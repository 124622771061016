import { useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { InvoiceService } from "../../services/invoice-service"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { GlobalContext } from "../../context/GlobalState"

export default function CheckoutForm({ cart }) {
  const stripe = useStripe()
  const navigate = useNavigate()
  const elements = useElements()
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { emptyCart } = useContext(GlobalContext)
  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          // return_url: 'http://localhost:3000/thankyou',
        },
        redirect: "if_required",
      })
      .then(async (res) => {
        if (!res.error) {
          const response = await InvoiceService.createInvoice({
            payment_response: res,
            from_id: window.config.vendor_id,
            stone_ids: cart.map((cartItem) => {
              return cartItem.id
            }),
            to_id: localStorage.getItem("user_id"),
            customer_billing_address_id: JSON.parse(
              localStorage.getItem("billingAddress")
            ).id,
            customer_shipping_address_id: JSON.parse(
              localStorage.getItem("shippingAddress")
            ).id,
            user_id: window.config.vendor_id,
            payment_received: true,
            prices: cart.map((cartItem) => {
              return cartItem.total_selling_price.toString().replace(",", "")
            }),
            payment_format: cart
              .map((cartItem) => {
                return cartItem.currency_format
              })[0]
              .toString(),
            user_type:
              localStorage.getItem("user_type") === null
                ? "vendor"
                : localStorage.getItem("user_type"),
            from_vendor: true,
          })

          if (response.data.success) {
            setMessage("Payment succeeded!")
            window.$("#stripeModal").modal("hide")

            localStorage.setItem("invoice_id", response.data.invoice.ref_id)
            localStorage.setItem("cartItems", JSON.stringify([]))
            emptyCart()
            navigate("/thankyou")
          }
        } else {
          if (
            res.error.type === "card_error" ||
            res.error.type === "validation_error"
          )
            setMessage(res.error.message)
          else setMessage("An unexpected error occurred.")
        }

        setIsLoading(false)
      })
      .catch((err) => {
        if (err.type === "card_error" || err.type === "validation_error")
          setMessage(err.message)
        else setMessage("An unexpected error occurred.")
      })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' />
      <div className='d-flex justify-content-end mt-2 mb-1'>
        <button
          id='submit'
          className='btn mt-2 btn-block'
          style={{ backgroundColor: "#1E6091", color: "white" }}
          disabled={isLoading || !stripe || !elements}
        >
          <span id='button-text'>{isLoading ? "Processing" : "Pay now"}</span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div className='alert alert-primary mt-2' role='alert'>
          {message}
        </div>
      )}
    </form>
  )
}
