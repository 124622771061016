import React, { useState, useEffect, useContext, useCallback } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Button,
  Drawer,
  Menu,
  MenuItem,
  Badge,
  IconButton,
  createTheme,
  ThemeProvider,
} from "@mui/material"

import { toast } from "react-hot-toast"
import { menuItems } from "../../../../../services/menuItems-service"
import { GlobalContext } from "../../../../../context/GlobalState"
import { AuthService } from "../../../../../services/auth-service"
import SidebarCartView from "../../../../Products/cart/SidebarCartView"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import YouTubeIcon from "@mui/icons-material/YouTube"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { getSectionDetail } from "../../../../../services/getSectionDetail"
// import { ThemeProvider } from "@mui/styles"

const accountAndwish = [
  {
    name: "Wishlist",
    icon: "/wishIconWhite.svg",
  },
  {
    name: "My cart",
    icon: "/shoppingIconWhite.svg",
  },
  {
    name: "My account",
    icon: "/accountIconWhite.svg",
  },
]

export const Header = ({ headerSection }) => {
  const [menuHeaderItem, setMenuHeaderItem] = useState([])
  const [cartOpen, setCartOpen] = useState(false)
  const [menu, setMenu] = useState(false)
  const [aboutContent, setAboutContent] = useState(false)
  const [contactContent, setContactContent] = useState(false)

  const [wishListOpen, setWishListOpen] = useState(false)
  const {
    cartItems,
    addToCart,
    emptyCart,
    wishList,
    addAllToCart,
    removeFromWishlist,
    removeFromCart,
    addToWishList,
    content
  } = useContext(GlobalContext)
  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
        desktopLarget: 1440,
      },
    },
  })

  const contactSectionDetail = async () => {
    try {
      const response = await getSectionDetail(
        window.config.vendor_id,
        "contact "
      )
     
      if(response?.data?.response?.user_website_details?.contact_section){
        setContactContent(true)
      }else{
        setContactContent(false)
      }
    } catch (error) {
      // setContactContent(false)
    }
   
  }
  const aboutSectionDetail = async () => {
    try {
      const response = await getSectionDetail(
        window.config.vendor_id,
        "about "
      )
   if(response?.data?.response?.user_website_details?.about_section){
     setAboutContent(true)
   }else{
    setAboutContent(false)
   }
    } catch (error) {
      // console.log(error)
    }
   
  }
  const cart = JSON.parse(localStorage.getItem("cartItems")) || []
  let wishlist = [];
  try {
    const wishlistData = localStorage.getItem("wishlistItems");
    if (wishlistData) {
      wishlist = JSON.parse(wishlistData);
    }
  } catch (error) {
    console.error("Error parsing wishlist data:", error);
    // Handle the error appropriately
  } 
   const navigate = useNavigate()
  const location = useLocation()

  const [anchorEl, setAnchorEl] = useState(null)
  const token = localStorage.getItem("token")
  const showBlank = true
  const handleNavigateToCart = () => {
    if (token !== null && cart.length !== 0) {
      navigate("/cart")
      setCartOpen(false)
    } else {
      toast.error("Please add item to your cart before checking out")
    }
  }

  const handleNavigateToWishlist = useCallback(() => {
    if (token !== null) {
      addAllToCart(wishList)
      setWishListOpen(false)
    } else {
      setWishListOpen(false)
      navigate("/login")
    }
  }, [token, addAllToCart, navigate, wishList, setWishListOpen])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    localStorage.clear()
    emptyCart()
    navigate("/")
    // Call AuthService.header to populate localStorage with new data
    let formdata = new formdata()
    formdata.append("vendor_id", window.config.vendor_id)

    try {
      const res = await AuthService.header(formdata)
      if (res.data.success) {
        localStorage.setItem("logo", res.data.logo)
        localStorage.setItem("hero_bg", res.data.hero_bg)
        localStorage.setItem("about_bg", res.data.about_bg)
        localStorage.setItem(
          "selection_stone_1_img",
          res.data.selection_stone_1_img
        )
        localStorage.setItem(
          "selection_stone_3_img",
          res.data.selection_stone_3_img
        )
        localStorage.setItem(
          "selection_stone_2_img",
          res.data.selection_stone_2_img
        )
        localStorage.setItem("template_status", res.data.is_website_completed)
      }
    } catch (err) {
      // console.log(err)
    }

    navigate("/")
  }

  const getMenuItems = async () => {
    const response = await menuItems({
      user_id:
        localStorage.getItem("user_id") === null
          ? window.config.vendor_id
          : localStorage.getItem("user_id"),
      user_type:
        localStorage.getItem("user_type") === null
          ? "vendor"
          : localStorage.getItem("user_type"),
    })
    setMenuHeaderItem(response.data.data)
  }

  useEffect(() => {
    contactSectionDetail()
    aboutSectionDetail()
    getMenuItems()
    const cartFromLC = JSON.parse(localStorage.getItem("cartItems"))
    if (cartFromLC) {
      cartFromLC?.map((cI) => {
        addToCart(cI)
      })
    }
    let wishListFromLC = [];
    try {
      const wishlistData = localStorage.getItem("wishlistItems");
      if (wishlistData) {
        wishListFromLC = JSON.parse(wishlistData);
      }
    } catch (error) {
      console.error("Error parsing wishlist data:", error);
      // Handle the error appropriately
    }
    if (wishListFromLC) {
      wishListFromLC?.map((wI) => {
        addToWishList(wI)
      })
    }
  }, [])

  const socialAccounts = [
    {
      name: "Our Instagram",
      icon: "/instagramWhite.svg",
      path: headerSection?.header_section_social_links?.instagram,
    },
    {
      name: "Our Facebook",
      icon: "/facebookWhite.svg",
      path: headerSection?.header_section_social_links?.facebook,
    },
    {
      name: "Our Twitter",
      icon: "/twitterWhite.svg",
      path: headerSection?.header_section_social_links?.twitter,
    },
  ]
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        width:"100%",
      }}>

      <Box sx={{
        width:"100%",
        maxWidth:"1216px", 
        mx:"auto",
        }}>
        <Box
          sx={{
            display: { mobile: "none", laptop: "flex" },
            alignItems: "center",
            justifyContent:
              headerSection?.header_section_logo?.image ||
              headerSection?.header_section_social_links?.is_enabled
                ? "space-between"
                : "flex-end",
          }}
        >
          {headerSection?.header_section_social_links?.is_enabled && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <a
                href={headerSection?.header_section_social_links?.instagram}
                target='_blank'
              >
                <Box
                  component='img'
                  src='/images/instagram.png'
                  sx={{ width: "40px" }}
                />
              </a>
              <a
                href={headerSection?.header_section_social_links?.facebook}
                target='_blank'
              >
                <Box
                  component='img'
                  src='/images/facebook.png'
                  sx={{ width: "40px" }}
                />
              </a>
              <a
                href={headerSection?.header_section_social_links?.twitter}
                target='_blank'
              >
                <Box
                  component='img'
                  src='/images/twitter.png'
                  sx={{ width: "40px" }}
                />
              </a>
              <a
                href={headerSection?.header_section_social_links?.youtube}
                target='_blank'
              >
                <YouTubeIcon
                  sx={{ color: "#4D4D4D", width: "40px", height: "30px" }}
                />
              </a>
            </Box>
          )}

          {headerSection?.header_section_logo?.image && (
            <Box>
              <Box
              onClick={()=>navigate("/")}
                component='img'
                src={headerSection?.header_section_logo?.image}
                alt='logo'
                sx={{
                  width: "auto",
                  maxWidth: "238px",
                  maxHeight: "71px",
                }}
              />
            </Box>
          )}

          <Box sx={{ display: "flex", gap:"16px", alignItems: "center" }}>
            <IconButton
              onClick={() => setWishListOpen(true)}
            
            >
              <Badge
                badgeContent={wishlist?.length}
                color='primary'
                sx={{
                  "& .MuiBadge-badge": {
                    minWidth: "0.6rem",
                    height: "1.125rem",
                    top: "7px",
                    right: "12px",
                    bgcolor: "#1A1A1A",
                  },
                }}
              >
                <Box
                  component='img'
                  src='/images/heart.png'
                  sx={{ width: "40px", height:"40px" }}
                />
              </Badge>
            </IconButton>

            <Menu
              sx={{
                "& .MuiList-root": {
                  pb: 0,
                  pt: 0,
                  boxShadow:"none",
                },
                boxShadow:"none",
                left: "-17px",
              }}
              id='menu-appbar'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {localStorage.hasOwnProperty("token") ? (
                <MenuItem
                  sx={{ fontFamily: "mulish-regular", boxShadow:"none" }}
                  onClick={() => {
                    handleLogout()
                    setAnchorEl(null)
                  }}
                >
                  SIGN OUT
                </MenuItem>
              ) : (
                <MenuItem
                  sx={{ fontFamily: "mulish-regular", boxShadow: 'none' }}
                  onClick={() => {
                    navigate("/login")
                    setAnchorEl(null)
                  }}
                >
                  Login
                </MenuItem>
              )}
            </Menu>
            <IconButton onClick={() => setCartOpen(true)}>
              <Badge
                badgeContent={cartItems?.length}
                color='primary'
                sx={{
                  "& .MuiBadge-badge": {
                    minWidth: "0.6rem",
                    height: "1.125rem",
                    top: "7px",
                    right: "9px",
                    bgcolor: "#1A1A1A",
                  },
                }}
              >
                <Box
                  component='img'
                  src='/images/cart.png'
                  sx={{ width: "40px" }}
                />
              </Badge>
            </IconButton>
                <IconButton
                  onClick={handleClick}
              
                >
                  <Box
                    component='img'
                    src='/images/profile.png'
                    sx={{ width: "40px" }}
                  />
                </IconButton>
          </Box>

          <Drawer
            anchor='right'
            open={wishListOpen}
            onClose={() => setWishListOpen(false)}
            sx={{
              display: { laptop: "flex" },
              "& .MuiDrawer-paper": {
                width: { laptop: "422px" },
              },
            }}
          >
            <SidebarCartView
              close={() => setWishListOpen(false)}
              name={"My wishlist"}
              items={wishlist}
              buttonName={"add all to cart"}
              buttonHandler={handleNavigateToWishlist}
              removeItem={removeFromWishlist}
            />
          </Drawer>
          <Drawer
            anchor='right'
            open={cartOpen}
            onClose={() => setCartOpen(false)}
            sx={{
              display: { laptop: "flex" },
              "& .MuiDrawer-paper": {
                width: { laptop: "422px" },
              },
            }}
          >
            <SidebarCartView
              close={() => setCartOpen(false)}
              name={"My cart"}
              items={cart}
              buttonName={"view order"}
              buttonHandler={handleNavigateToCart}
              removeItem={removeFromCart}
            />
          </Drawer>
        </Box>

        <Box
          sx={{
            borderBottom: "2px solid #E0E0E0",
            mt: "24px",
            display: { mobile: "none", laptop: "block" },
          }}
        />

        <Box
          sx={{
            display: {
              mobile: "none",
              laptop: "flex",
            },
            justifyContent:"center",
            mt: "24px",
            gap:"48px"
          }}
        >
          <Typography
            sx={{
              color: "#666666",
              // mr: { laptop: "24px", desktop: "48px" },
              fontFamily: "mulish-regular",
              p: "8px",
              fontSize: { laptop: "14px", desktop: "16px" },
              cursor: "pointer",
              fontWeight: location.pathname === "/" ? 700 : 500,
              borderBottom:
                location.pathname === "/" ? "1px solid #333" : "none",
            }}
            onClick={() => navigate("/")}
          >
            Home
          </Typography>
          {menuHeaderItem?.map((item, i) => (
            <Typography
              onClick={() =>
                navigate(`products/${item.parcel_type_ids?.toString()}`)
              }
              key={i}
              sx={{
                color: "#666666",
                // mr: { laptop: "24px", desktop: "48px" },
                fontFamily: "mulish-regular",
                fontSize: { laptop: "14px", desktop: "16px" },
                lineHeight:"150%",
                p: "8px",
                cursor: "pointer",
                fontWeight:
                  location.pathname ===
                  `/products/${item.parcel_type_ids?.toString()}`
                    ? 700
                    : 500,
                borderBottom:
                  location.pathname ===
                  `/products/${item.parcel_type_ids?.toString()}`
                    ? "1px solid #333"
                    : "none",
              }}
            >
              {item.title}
            </Typography>
          ))}
          {aboutContent && <Typography
            onClick={() => navigate("/about-us")}
            sx={{
              color: "#666666",
              // mr: { laptop: "24px", desktop: "48px" },
              fontFamily: "mulish-regular",
              fontSize: { laptop: "14px", desktop: "16px" },
              lineHeight:"150%",
              p: "8px",
              cursor: "pointer",
              fontWeight: location.pathname === "/about-us" ? 700 : 500,
              borderBottom:
                location.pathname === "/about-us" ? "1px solid #333" : "none",
            }}
          >
            About us
          </Typography>}
          
          {contactContent &&    <Typography
            onClick={() => navigate("/Contact-us")}
            sx={{
              color: "#666666",
              fontFamily: "mulish-regular",
              p: "8px",
              lineHeight:"150%",
              fontSize: { laptop: "14px", desktop: "16px" },
              cursor: "pointer",
              fontWeight: location.pathname === "/Contact-us" ? 700 : 500,
              borderBottom:
                location.pathname === "/Contact-us" ? "1px solid #333" : "none",
            }}
          >
            Contact us
          </Typography>}
        
        </Box>
        <MobileHeader
          image={headerSection?.header_section_logo?.image}
          OnClick={() => setMenu(true)}
          openCart={() => setCartOpen(true)}
        />
        <Drawer
          anchor='left'
          open={menu}
          onClose={() => setMenu(false)}
          sx={{
            // display: { laptop: "flex" },
            "& .MuiDrawer-paper": {
              width: { mobile: "100%", tablet: "50%" },
              height: "100%",
              // top: "10%",
              // zIndex: 0,
              // background: "rgba(0, 0, 0, 1)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: "16px",
              textAlign: "center",
              // width: "100%",
              mx: "16px",
            }}
          >
            <IconButton
              onClick={() => setMenu(false)}
              sx={{
                "&.MuiIconButton-root": {
                  padding: "0rem",
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Box
                component='img'
                src={headerSection?.header_section_logo?.image}
                sx={{ width: "auto", maxWidth: "14rem", height: "71px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              borderBottom: "2px solid #E0E0E0",
              mt: "16px",
            }}
          />
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ height: "40%", mx: "16px", pt: "16px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/")
                  setMenu(false)
                }}
              >
                <Typography
                  sx={{
                    color: "#666666",
                    mr: { laptop: "24px", desktop: "48px" },
                    fontFamily: "mulish-regular",
                    p: "8px",
                    fontSize: "16px",
                    fontWeight: location.pathname === "/" ? 700 : 500,
                    // borderBottom: location.pathname === "/" ? "1px solid #333" : "none",
                  }}
                >
                  Home
                </Typography>
                <ChevronRightIcon sx={{ coloe: "#666666" }} />
              </Box>
              {menuHeaderItem?.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`products/${item.parcel_type_ids?.toString()}`)
                    setMenu(false)
                  }}
                >
                  <Typography
                    key={i}
                    sx={{
                      color: "#666666",
                      mr: { laptop: "24px", desktop: "48px" },
                      fontFamily: "mulish-regular",
                      fontSize: "16px",
                      p: "8px",
                      cursor: "pointer",
                      fontWeight:
                        location.pathname ===
                        `/products/${item.parcel_type_ids?.toString()}`
                          ? 700
                          : 500,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <ChevronRightIcon sx={{ coloe: "#666666" }} />
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/about-us")
                  setMenu(false)
                }}
              >
                <Typography
                  sx={{
                    color: "#666666",
                    mr: { laptop: "24px", desktop: "48px" },
                    fontFamily: "mulish-regular",
                    fontSize: "16px",
                    p: "8px",
                    cursor: "pointer",
                    fontWeight: location.pathname === "/about-us" ? 700 : 500,
                  }}
                >
                  About us
                </Typography>
                <ChevronRightIcon sx={{ coloe: "#666666" }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/Contact-us")
                  setMenu(false)
                }}
              >
                <Typography
                  sx={{
                    color: "#666666",
                    fontFamily: "mulish-regular",
                    p: "8px",
                    fontSize: "16px",
                    cursor: "pointer",
                    fontWeight: location.pathname === "/Contact-us" ? 700 : 500,
                  }}
                >
                  Contact us
                </Typography>
                <ChevronRightIcon sx={{ coloe: "#666666" }} />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#1A1A1A",
                px: "24px",
                pt: "16px",
                mt: "16px",
                height: "100%",
              }}
            >
              {accountAndwish.map((item, i) => {
                return (
                  <Box
                    onClick={(e) => {
                      if (item.name === "Wishlist") {
                        setWishListOpen(true)
                      }
                      if (item.name === "My cart") {
                        navigate("/cart")
                        setMenu(false)
                      }
                      if (item.name === "My account") {
                        setAnchorEl(e.currentTarget)
                        // setMenu(false)
                      }
                    }}
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      py: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <Box component='img' src={item.icon} />
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontFamily: "mulish-regular",

                        ml: "8px",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                )
              })}
              <Menu
                sx={{
                  "& .MuiList-root": {
                    pb: 0,
                    pt: 0,
                  },
                  left: "-17px",
                }}
                id='menu-appbar'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {localStorage.hasOwnProperty("token") ? (
                  <MenuItem
                    sx={{ fontFamily: "mulish-regular" }}
                    onClick={() => {
                      handleLogout()
                      setMenu(false)
                      setAnchorEl(null)
                    }}
                  >
                    Logout
                  </MenuItem>
                ) : (
                  <MenuItem
                    sx={{ fontFamily: "mulish-regular" }}
                    onClick={() => {
                      navigate("/login")
                      setMenu(false)
                      setAnchorEl(null)
                    }}
                  >
                    Login
                  </MenuItem>
                )}
              </Menu>
              <Box sx={{ border: "1px solid #4D4D4D", my: "8px" }} />
              {headerSection?.header_section_social_links?.is_enabled &&
                socialAccounts.map((item, i) => {
                  return (
                    <Box
                      key={i}
                      component='a'
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        py: "16px",
                        textDecoration: "none",
                      }}
                      href={item.path}
                      target='_blank'
                    >
                      <Box component='img' src={item.icon} />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "14px",
                          fontFamily: "mulish-regular",

                          ml: "8px",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  )
                })}
              {headerSection?.header_section_social_links?.is_enabled && (
                <Box
                  component='a'
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: "16px",
                    textDecoration: "none",
                  }}
                  href={headerSection?.header_section_social_links?.youtube}
                  target='_blank'
                >
                  <YouTubeIcon
                    sx={{ color: "white", width: "28px", height: "30px" }}
                  />
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontFamily: "mulish-regular",
                      ml: "7px",
                    }}
                  >
                    Our Youtube
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ border: "1px solid #4D4D4D" }} />
            <Box
              sx={{
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "end",
                bgcolor: "#1A1A1A",
                // pt: "8px",
              }}
            >
              <Typography
                sx={{ textAlign: "center", color: "#FFFFFF", py: "8px" }}
              >
                Powered by GemCloud
              </Typography>
            </Box>
          </Box>
        </Drawer>
      </Box>
      </Box>
    </ThemeProvider>
  )
}

export const MobileHeader = ({ image, OnClick, openCart }) => {

  const navigate = useNavigate()
  return (
    <>
      <Box
        sx={{
          mx: { mobile: "12px" },
          display: { laptop: "none" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            mt: "16px",
            mb: "18px",
            mx: { tablet: "8px" },
          }}
        >
          <IconButton
            onClick={OnClick}
            sx={{
              "&.MuiIconButton-root": {
                padding: "0rem",
              },
            }}
          >
            <Badge color='primary'>
              <MenuIcon />
            </Badge>
          </IconButton>
          {/* <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "23.9px",
                fontWeight: 500,
                letterSpacing: "8.9px",
                color: "#333333",
                fontFamily: "abhaya-libre-medium",
                textTransform: "uppercase",
              }}
            >
              {gemstore}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.55rem",
                fontWeight: 400,
                letterSpacing: "0.2rem",
                color: "#333333",
                fontFamily: "alef-regular",
                textTransform: "uppercase",
              }}
            >
              {international}
            </Typography>
          </Box> */}{" "}
          <Box>
            <Box
            onClick={()=>navigate("/")}
              component='img'
              src={image}
              sx={{
                width: "auto",
                maxWidth: "14rem",
                height: { mobile: "60px", tablet: "71px" },
                display: { mobile: "block" },
              }}
            />
          </Box>
          <IconButton
            onClick={openCart}
            sx={{
              "&.MuiIconButton-root": {
                padding: "0rem",
              },
            }}
          >
            <Badge color='primary'>
              <Box
                component='img'
                src='/images/cart.png'
                sx={{ width: "40px" }}
              />
            </Badge>
          </IconButton>
        </Box>
        <Box
          sx={{
            borderBottom: "2px solid #E0E0E0",
            mt: "16px",
            // display: { mobile: "none", laptop: "block" },
          }}
        />
      </Box>
    </>
  )
}
