import * as React from "react"
import { Typography, Box, IconButton } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const FiltersSelectMultiple = ({
  name,
  label,
  value = [],
  options,
  handleSelectFilter,
  loading,
  productLength,
  onClose,
}) => {
  const [show, setShow] = React.useState(true)
  const handleStoneClick = (stone) => {
    const stoneSelected = value.includes(stone.id)
    handleSelectFilter({
      target: {
        name,
        value: stoneSelected
          ? value.filter((item) => item !== stone.id)
          : [...value, stone.id],
      },
    })
  }

  return (
    <Box
      sx={{
        width: "254px",
        mx: "auto",
        maxHeight: "312px",
        mt: "1rem",
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#999999 #F5F5F5",
        "&::-webkit-scrollbar": {
          height: "4px",
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#999999",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F5F5F5",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-corner": {
          backgroundColor: "#F5F5F5",
        },
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          zIndex: 1,
          marginBottom: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              textTransform: "uppercase",
              fontFamily: "mulish-regular",
              ml: "8px",
            }}
          >
            {label}
          </Typography>

          <IconButton sx={{ p: 0, mr: "18px" }} onClick={() => setShow(!show)}>
            {show ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      </Box>
      {show && (
        <Box sx={{mx:"8px"}}>
          {options &&
            options?.map((stone, index) => {
              const stoneSelected = value.includes(stone.id)

              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: "0.4rem",
                    
                  }}
                >
                  <Typography
                    sx={{
                      color: stoneSelected ? "#333333" : "#858585",
                      fontWeight: stoneSelected ? 600 : 500,
                      mt: "0.3rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      fontFamily: "mulish-regular",
                      cursor: loading ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                      handleStoneClick(stone)
                      onClose()
                    }}
                  >
                    {stone.name}
                  </Typography>
                </Box>
              )
            })}
        </Box>
      )}
    </Box>
  )
}
