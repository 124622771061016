import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  InputAdornment,
  FormControl,
  IconButton,
  Input,
  FormHelperText,
} from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import {
  validateCaptcha,
  loadCaptchaEnginge,
  LoadCanvasTemplate,
} from "react-simple-captcha"
import toast from "react-hot-toast"
import api from "../../../services/API"
import { AuthService } from "../../../services/auth-service"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../../../context/GlobalState"

export const LoginNew = (props) => {
  const { handleLogin, isAuth } = props
  const navigate = useNavigate()
  const { addCustomer } = useContext(GlobalContext)

  const [newValues, setNewValues] = useState({
    email: "",
    vendor_id: window ? window.config.vendor_id : "",
    password: "",
    loading: false,
    template_status: 0,
    showPassword: false,
    captcha: "",
  })

  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const handleClickShowPassword = () => {
    setNewValues({
      ...newValues,
      showPassword: !newValues.showPassword,
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleOnChange = (event) => {
    setNewValues({
      ...newValues,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    loadCaptchaEnginge(6, "white", "black")
  }, [])

  const validateForm = () => {
    let isValid = true

    // Email validation
    if (
      !newValues.email ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newValues.email)
    ) {
      setEmailError("Email is invalid")
      isValid = false
    } else {
      setEmailError("")
    }

    if (!newValues.password) {
      setPasswordError("Password is required")
      isValid = false
    } else {
      setPasswordError("")
    }

    return isValid
  }

  const login = async (e) => {
    e.preventDefault()

    if (validateForm()) {
      if (validateCaptcha(newValues.captcha) === true) {
        setNewValues({
          ...newValues,
          loading: true,
        })

        try {
          const res = await AuthService.userLogin(
            newValues.email,
            newValues.password
          )

          if (res.data.success === true) {
            setNewValues({
              ...newValues,
              loading: false,
            })

            api.defaults.headers["Authorization"] = `Bearer ${res.data.token}`

            if (res.data.is_website_completed === 1) {
              addCustomer(res.data.customer)
              localStorage.setItem("token", res.data.token)
              localStorage.setItem("user_id", res.data.customer.id)
              localStorage.setItem("user_type", res.data.user_type)

              if (!localStorage["cartItems"]) {
                localStorage.setItem("cartItems", JSON.stringify([]))
              }
            } else {
              localStorage.setItem("token", res.data.token)
              localStorage.setItem("user_id", res.data.customer.id)
              localStorage.setItem("user_type", res.data.user_type)

              if (localStorage.getItem("cartItems") === "null") {
                localStorage.setItem("cartItems", JSON.stringify([]))
              }
            }

            handleLogin(res.data)
            navigate("/")
          } else {
            setNewValues({
              ...newValues,
              loading: false,
            })

            if (!res.data.activated) {
              toast.error("Your account is pending approval.")
            }
          }
        } catch (err) {
          toast.error(err.message)
          setNewValues({
            ...newValues,
            loading: false,
            captcha: "",
          })
        }
      } else {
        toast.error("Captcha failed.")
        setNewValues({
          ...newValues, captcha: "",
          loading: false,
          
        })
      }
    }
  }

  return (
    <Box
      sx={{
        bgcolor: "#F8F8F8",
        width: { sm: "488px", md: "660px", lg: "1010px" },
        mx: { xs: "12px", sm: "auto" },
        mt: { xs: "24px", md: "48px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: { md: "390px" },
          mx: { xs: "16px", sm: "42px", md: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            textAlign: "center",
            color: " #333333",
            mt: "40px",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          Login
        </Typography>
        <Box component='form' onSubmit={login} noValidate sx={{}}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={handleOnChange}
            error={!!emailError}
            helperText={emailError}
          />

          <FormControl fullWidth sx={{ mt: "1px" }} variant='standard'>
            <InputLabel htmlFor='standard-adornment-password'>
              Password
            </InputLabel>
            <Input
              name='password'
              id='standard-adornment-password'
              onChange={handleOnChange}
              type={newValues.showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {newValues.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {passwordError && (
              <FormHelperText error={true}>{passwordError}</FormHelperText>
            )}
          </FormControl>
          <Box sx={{ mt: "1rem" }}>
            <LoadCanvasTemplate reloadColor='black' id='user_captcha_input' />
          </Box>
          <TextField
            margin='normal'
            variant='standard'
            fullWidth
            value={newValues.captcha}
            id='captcha'
            label='Enter Captcha Value'
            name='captcha'
            onChange={handleOnChange}
          />

          <Button
            type='submit'
            fullWidth
            disabled={newValues.loading}
            variant='contained'
            sx={{
              bgcolor: "black",
              color: "white",
              fontSize: "16px",
              textTransform: "capitalize",
              fontFamily: "mulish-regular",
              mt: "24px",
              "&.MuiButton-root:hover": {
                color: "white",
                bgcolor: "#333333",
                borderColor: "#333333",
              },
            }}
          >
            {newValues.loading ? "Please wait..." : "Login"}
          </Button>
        </Box>
        <Typography sx={{ my: "16px" }}>or</Typography>
        <Button
          variant='outlined'
          fullWidth
          sx={{
            color: "black",
            fontSize: "16px",
            borderColor: "black",
            fontFamily: "mulish-regular",
            "&.MuiButton-root:hover": {
              color: "white",
              bgcolor: "#333333",
              borderColor: "#333333",
            },
            // width: { sm: "188px", md: "660px", lg: "1010px" },
            // mx:"210px"
          }}
          onClick={() => navigate("/signup")}
        >
          request access
        </Button>
        <Box sx={{ mt: "48px", mb: "64px", textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#333333",
              fontFamily: "montserrat-bold",
            }}
          >
            Powered by
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#333333",
              letterSpacing: "0.4em",
              mt: "4px",
              fontFamily: "montserrat-Medium",
            }}
          >
            GEMCLOUD
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#333333",
              letterSpacing: "0.15em",
              mt: "4px",
              fontFamily: "montserrat-Medium",
            }}
          >
            SOFTWARE
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
