import React, { useState } from "react"
import { Box, Button, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
export const ProductDetailButtons = ({
  addToCartHandler,
  open,
  handleClose,
  handleOpen,
  item,
  saveStoneList,
  addToWishListHandler,
}) => {
  const [copied, setCopied] = useState(false)
  let wishlistItems = [];
try {
  const wishlistData = localStorage.getItem("wishlistItems");
  if (wishlistData) {
    wishlistItems = JSON.parse(wishlistData);
  }
} catch (error) {
  console.error("Error parsing wishlist data:", error);

}
  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || []
  const handleCopy = () => {
    const url = window.location.href
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 3000)
    })
  }
  const handleClickWhatsapp = () => {
    const message = encodeURIComponent(window.location.toString())
    window.open(`https://wa.me/?text=${message}`, "_blank")
  }
  const handleClickTwitter = () => {
    // const message = encodeURIComponent(props.text)
    const tweetUrl = `https://twitter.com/intent/tweet?url=${window.location.toString()}`
    window.open(tweetUrl, "_blank")
  }
  // const handleClickInstagram = () => {
  //   const image = encodeURIComponent(props.image)
  //   const caption = encodeURIComponent(props.caption)
  //   const instagramUrl = `instagram://share?caption=${caption}&backgroundImage=${image}`
  //   window.open(instagramUrl, "_blank")
  // }
  const handleClickTelegram = () => {
    const url = encodeURIComponent(window.location.toString())
    const telegramUrl = `https://telegram.me/share/url?url=${url}`
    window.open(telegramUrl, "_blank")
  }
  const handleClickFacebook = () => {
    const url = encodeURIComponent(window.location.toString())
    const messengerUrl = `https://facebook.com/share/?link=${url}`
    window.open(messengerUrl, "_blank")
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "space-between" },
        mt: "24px",
      }}
    >
      <Button
        sx={{
          bgcolor: "black",
          color: "white",
          fontSize: { xs: "11px", sm: "15px", md: "16px" },
          height: { xs: "42px", md: "50px" },
          width: { xs: "90px", sm: "118px", md: "165px", lg: "200px" },
          fontWeight: 400,
          textTransform: "lowercase",
          mt: "8px",
          fontFamily: "mulish-regular",
          "&.MuiButton-root:hover": {
            color: " white",
            bgcolor: "#333333",
            borderColor: " #333333",
          },
          "&.MuiButtonBase-root.Mui-disabled ": {
            color: " white",
          },
          p: 0,
          // mx:"0px"
        }}
        onClick={addToCartHandler}
        // disabled={cartItems.every((cartItem) => cartItem.id !== item.id)}
        // disabled={true}
      >
        {cartItems.every((cartItem) => cartItem.id !== item.id) ? (
          <>
            <span style={{ textTransform: "uppercase", p: 0 }}>A</span>
            <span>dd to cart</span>
          </>
        ) : (
          <>
            <span style={{ textTransform: "uppercase", p: 0 }}>A</span>
            <span>dded to cart</span>
          </>
        )}
      </Button>

      <Button
        variant='outlined'
        onClick={() => {
          saveStoneList()
        }}
        sx={{
          // bgcolor: "black",
          width: { xs: "95px", sm: "118px", md: "165px", lg: "200px" },
          color: "black",
          fontSize: { xs: "11px", sm: "15px", md: "16px" },
          height: { xs: "42px", md: "50px" },
          mt: "8px",
          fontWeight: 400,
          textTransform: "lowercase",
          borderColor: "#1A1A1A",
          fontFamily: "mulish-regular",
          "&.MuiButton-root:hover": {
            color: " white",
            bgcolor: "#333333",
            borderColor: " #333333",
          },
          // p:0,
        }}
      >
        <span style={{ textTransform: "uppercase" }}>G</span>et a quote
      </Button>
      <IconButton
        onClick={addToWishListHandler}
        // variant='outlined'
        sx={{
          // bgcolor: "black",
          backgroundColor:`${wishlistItems.some((ele)=>ele.id === item.id) ? "black":"white"}`,
          width: { xs: "45px", md: "50px" },
          // width:"10px !important",
          border: "1px solid black",
          // color: `${wishlistItems.find((ele)=>ele.id === item.id) ? "white":"black"}`,
          fontSize: "16px",
          height: { xs: "42px", md: "50px" },
          mt: "8px",
          textTransform: "capitalize",
          borderColor: "#1A1A1A",
          borderRadius: "4px",

           "&:hover": {
            color: "white",
            bgcolor: "#333333",
            borderColor: "#333333",
           },
          "& .hearticon": {
            fill: "black",
            transition: "fill",
          },
          "&:hover .hearticon": {
            fill: "white",
          },
       
        }}
        // disabled={wishlistItems.every((cartItem) => cartItem.id === item.id)}
      >
        {wishlistItems?.some((list) => {
          return list.id === item.id
        }) ? (
          
            <svg
              width='24'
              height='24'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.0354 1.33332C10.0703 1.33331 9.44822 1.44829 8.73506 1.79736C8.47103 1.9266 8.22332 2.08224 7.99327 2.26382C7.77209 2.09242 7.53428 1.94393 7.28117 1.81874C6.55417 1.45916 5.90015 1.33331 4.96966 1.33331C2.47758 1.33331 0.666626 3.39816 0.666626 6.0799C0.666626 8.10515 1.79585 10.0615 3.89919 11.9532C5.00322 12.9462 6.41284 13.9289 7.42236 14.4516L7.99996 14.7507L8.57756 14.4516C9.58708 13.9289 10.9967 12.9462 12.1007 11.9532C14.2041 10.0615 15.3333 8.10515 15.3333 6.0799C15.3333 3.42654 13.5053 1.34355 11.0354 1.33332ZM14 6.0799C14 7.66658 13.0574 9.29952 11.2091 10.9619C10.2014 11.8682 8.90593 12.7739 7.99996 13.2491C7.09399 12.7739 5.79851 11.8682 4.79081 10.9619C2.94251 9.29952 1.99996 7.66658 1.99996 6.0799C1.99996 4.09846 3.25572 2.66665 4.96966 2.66665C5.70948 2.66665 6.16538 2.75437 6.69005 3.01388C6.99996 3.16716 7.27388 3.36956 7.51098 3.62249L8.00152 4.14578L8.48759 3.61835C8.73003 3.35528 9.00797 3.14827 9.32124 2.99493C9.83054 2.74564 10.258 2.66665 11.0327 2.66665C12.7254 2.67367 14 4.12596 14 6.0799Z'
                fill='white'
              />
            </svg>
          
         
        ) : (
          
          <svg
            className="hearticon"
            width='24'
            height='24'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              className="hearticon"
              fillRule='evenodd'
              clipRule='evenodd'
              d='M11.0354 1.33332C10.0703 1.33331 9.44822 1.44829 8.73506 1.79736C8.47103 1.9266 8.22332 2.08224 7.99327 2.26382C7.77209 2.09242 7.53428 1.94393 7.28117 1.81874C6.55417 1.45916 5.90015 1.33331 4.96966 1.33331C2.47758 1.33331 0.666626 3.39816 0.666626 6.0799C0.666626 8.10515 1.79585 10.0615 3.89919 11.9532C5.00322 12.9462 6.41284 13.9289 7.42236 14.4516L7.99996 14.7507L8.57756 14.4516C9.58708 13.9289 10.9967 12.9462 12.1007 11.9532C14.2041 10.0615 15.3333 8.10515 15.3333 6.0799C15.3333 3.42654 13.5053 1.34355 11.0354 1.33332ZM14 6.0799C14 7.66658 13.0574 9.29952 11.2091 10.9619C10.2014 11.8682 8.90593 12.7739 7.99996 13.2491C7.09399 12.7739 5.79851 11.8682 4.79081 10.9619C2.94251 9.29952 1.99996 7.66658 1.99996 6.0799C1.99996 4.09846 3.25572 2.66665 4.96966 2.66665C5.70948 2.66665 6.16538 2.75437 6.69005 3.01388C6.99996 3.16716 7.27388 3.36956 7.51098 3.62249L8.00152 4.14578L8.48759 3.61835C8.73003 3.35528 9.00797 3.14827 9.32124 2.99493C9.83054 2.74564 10.258 2.66665 11.0327 2.66665C12.7254 2.67367 14 4.12596 14 6.0799Z'
              // fill="black"
            />
          </svg>
          
        )}
      </IconButton>
      <IconButton
        onClick={handleOpen}
        sx={{
          // bgcolor: "black",
          width: { xs: "45px", md: "50px" },
          color: "black",
          fontSize: "16px",
          height: { xs: "42px", md: "50px" },
          mt: "8px",
          border: "1px solid black",
          textTransform: "capitalize",
          borderColor: "#1A1A1A",
          borderRadius: "4px",
          "&:hover": {
            color: "white",
            bgcolor: "#333333",
            borderColor: "#333333",
          },
          "& .shareicon": {
            fill: "black",
            transition: "fill",
          },
          "&:hover .shareicon": {
            fill: "white",
          },
        }}
      >
        <svg
          className='shareicon'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            className='shareicon'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14 6C14 6.28742 14.0303 6.56777 14.0879 6.83801L9.01694 9.3735C8.28363 8.53189 7.20393 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.20393 16 8.28363 15.4681 9.01694 14.6265L14.0879 17.162C14.0303 17.4322 14 17.7126 14 18C14 20.2091 15.7909 22 18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C16.7961 14 15.7164 14.5319 14.9831 15.3735L9.91208 12.838C9.96968 12.5678 10 12.2874 10 12C10 11.7126 9.96968 11.4322 9.91208 11.162L14.9831 8.6265C15.7164 9.46811 16.7961 10 18 10C20.2091 10 22 8.20914 22 6C22 3.79086 20.2091 2 18 2C15.7909 2 14 3.79086 14 6ZM8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12ZM18 8C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4C16.8954 4 16 4.89543 16 6C16 7.10457 16.8954 8 18 8ZM20 18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18C16 16.8954 16.8954 16 18 16C19.1046 16 20 16.8954 20 18Z'
            fill='#333333'
          />
        </svg>
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "596px",
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: "none",
            // p: 4,
            px: "24px",
            py: "32px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id='modal-modal-title'
              sx={{ fontSize: "28px", fontWeight: 700, color: "#333333" }}
            >
              Share this page
            </Typography>
            <Button
              onClick={handleClose}
              sx={{
                p: 0,
                minWidth: "32px",
                height: "32px",
                border: " 0.8px solid #E0E0E0",
                borderRadius: "6.4px",
              }}
            >
              <CloseIcon sx={{ color: "#858585" }} />
            </Button>
          </Box>
          <Box sx={{ borderBottom: " 1px solid #E0E0E0", my: "24px" }} />
          <Box>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 700, color: "#333333" }}
            >
              Share this link via
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center " }}>
              <IconButton
                sx={{
                  width: "60px",
                  height: "60px",
                  border: "1.2px solid #E0E0E0",
                  borderRadius: "4.8px",
                  mr: "16px",
                  mt: "16px",
                  color: "black",
                  "&:hover": {
                    color: "white",
                    bgcolor: "#333333",
                    borderColor: "#333333",
                  },
                  "& .facebookIcon": {
                    fill: "black",
                    transition: "fill",
                  },
                  "&:hover .facebookIcon": {
                    fill: "white",
                  },
                }}
                onClick={handleClickFacebook}
              >
                <svg
                  className='facebookIcon'
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    className='facebookIcon'
                    d='M20.0826 15.5495L20.8082 10.7718H16.2694V7.67281C16.2694 6.36541 16.9023 5.09029 18.9352 5.09029H21V1.02281C21 1.02281 19.1269 0.699997 17.337 0.699997C13.5973 0.699997 11.1553 2.98876 11.1553 7.13048V10.7718H7V15.5495H11.1553V27.0998C11.9895 27.2322 12.8429 27.3 13.7123 27.3C14.5817 27.3 15.4352 27.2322 16.2694 27.0998V15.5495H20.0826Z'
                    fill='#333333'
                  />
                </svg>
              </IconButton>
              <IconButton
                sx={{
                  width: "60px",
                  height: "60px",
                  border: "1.2px solid #E0E0E0",
                  borderRadius: "4.8px",
                  mr: "16px",
                  mt: "16px",
                  color: "black",
                  "&:hover": {
                    color: "white",
                    bgcolor: "#333333",
                    borderColor: "#333333",
                  },
                  "& .twitterIcon": {
                    fill: "black",
                    transition: "fill",
                  },
                  "&:hover .twitterIcon": {
                    fill: "white",
                  },
                }}
                onClick={handleClickTwitter}
              >
                <svg
                  className='twitterIcon'
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    className='twitterIcon'
                    d='M24.5657 8.37755C24.5766 8.62395 24.5766 8.85915 24.5766 9.10555C24.5874 16.576 19.0988 25.2 9.06169 25.2C6.10133 25.2 3.19499 24.3152 0.699219 22.6576C1.13139 22.7136 1.56356 22.736 1.99573 22.736C4.44828 22.736 6.83602 21.8848 8.76997 20.3056C6.43626 20.2608 4.38346 18.6816 3.67038 16.3744C4.4915 16.5424 5.33423 16.5088 6.13374 16.2736C3.59475 15.7584 1.76884 13.44 1.75803 10.7408C1.75803 10.7184 1.75803 10.696 1.75803 10.6736C2.51433 11.1104 3.36786 11.3568 4.2322 11.3792C1.84447 9.72155 1.09898 6.41754 2.54674 3.83033C5.32343 7.36955 9.40742 9.50875 13.7939 9.74395C13.351 7.78395 13.956 5.72314 15.3714 4.33434C17.5646 2.19513 21.022 2.30713 23.0964 4.58074C24.3173 4.33434 25.4949 3.86393 26.5645 3.20313C26.154 4.51354 25.3004 5.62234 24.166 6.32794C25.2464 6.19354 26.3052 5.89114 27.2992 5.44314C26.5645 6.58554 25.6354 7.57115 24.5657 8.37755Z'
                    fill='#333333'
                  />
                </svg>
              </IconButton>

              {/* <ShareIconButton
                  img={"/instagram.svg"}
                  handleShare={handleClickInstagram}
                /> */}
              <ShareIconButton
                img={"/whatsapp.svg"}
                handleShare={handleClickWhatsapp}
              />

              <IconButton
                sx={{
                  width: "60px",
                  height: "60px",
                  border: "1.2px solid #E0E0E0",
                  borderRadius: "4.8px",
                  mr: "16px",
                  mt: "16px",
                  color: "black",
                  "&:hover": {
                    color: "white",
                    bgcolor: "#333333",
                    borderColor: "#333333",
                  },
                  "& .emailIcon": {
                    fill: "black",
                    transition: "fill",
                  },
                  "&:hover .emailIcon": {
                    fill: "white",
                  },
                }}
                onClick={handleClickTelegram}
              >
                <svg
                  className='emailIcon'
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    className='emailIcon'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.3763 13.1755C9.61137 10.0233 14.4359 7.94517 16.8498 6.94112C23.7422 4.07437 25.1743 3.57638 26.1078 3.55994C26.3131 3.55632 26.7722 3.6072 27.0695 3.84848C27.3206 4.05222 27.3897 4.32743 27.4227 4.52059C27.4558 4.71376 27.497 5.15379 27.4642 5.49761C27.0907 9.42198 25.4746 18.9454 24.6524 23.3407C24.3045 25.2006 23.6195 25.8242 22.9563 25.8852C21.5151 26.0178 20.4206 24.9327 19.0247 24.0177C16.8404 22.5858 15.6064 21.6945 13.4861 20.2973C11.0357 18.6825 12.6242 17.795 14.0206 16.3446C14.3861 15.965 20.7363 10.1891 20.8592 9.66509C20.8746 9.59956 20.8888 9.35529 20.7437 9.22631C20.5986 9.09733 20.3844 9.14143 20.2299 9.17651C20.0108 9.22624 16.5213 11.5327 9.76132 16.0958C8.77083 16.776 7.87368 17.1074 7.06986 17.09C6.18372 17.0708 4.47913 16.5889 3.21195 16.177C1.65769 15.6718 0.422401 15.4047 0.529967 14.5467C0.585994 14.0997 1.20144 13.6427 2.3763 13.1755Z'
                    fill='#333333'
                  />
                </svg>
              </IconButton>
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                color: "#333333",
                mt: "24px",
              }}
            >
              Or copy link
            </Typography>
            <Box
              sx={{
                border: "1px solid #E0E0E0",
                width: "548px",
                display: "flex",
                alignItems: "center",
                mt: "16px",
              }}
            >
              <Box
                component='img'
                src={"/link.svg"}
                sx={{
                  my: "13px",
                  ml: "8px",
                }}
              />

              <Typography
                sx={{
                  fontSize: "18px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "#333333",
                  overflow: "hidden",
                  fontWeight: 400,
                  py: "8px",
                  ml: "8px",
                  width: "393px",
                }}
              >
                {window.location.toString()}
              </Typography>
              <Button
                onClick={handleCopy}
                // data-clipboard-target='#responseLink'
                sx={{
                  bgcolor: "black",
                  color: "white",
                  fontSize: "18px",
                  ml: "8px",
                  width: "91px",
                  height: "50px",
                  textTransform: "capitalize",
                  "&.MuiButton-root:hover": {
                    color: " white",
                    bgcolor: "#333333",
                    borderColor: " #333333",
                  },
                }}
              >
                copy
              </Button>
            </Box>
            {copied && (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#333333",
                  mt: "10px",
                }}
              >
                Link copied to clipboard!
              </Typography>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
export const ShareIconButton = ({ img, handleShare, className }) => {
  return (
    <IconButton
      sx={{
        width: "60px",
        height: "60px",
        border: "1.2px solid #E0E0E0",
        borderRadius: "4.8px",
        mr: "16px",
        mt: "16px",
        color: "black",
        "&:hover": {
          color: "white",
          bgcolor: "#333333",
          borderColor: "#333333",
        },
        "& .iconshare": {
          fill: "black",
          transition: "fill",
        },
        "&:hover .iconshare": {
          fill: "white",
        },
      }}
      onClick={handleShare}
    >
      <Box
        className={className}
        component='img'
        src={img}
        sx={{
          my: "0.95rem",
          mx: "0.9rem",
          "&.MuiButton-root:hover": {
            color: " white",
            bgcolor: "#333333",
            borderColor: " #333333",
            fill: "white",
          },
        }}
      />
    </IconButton>
  )
}
