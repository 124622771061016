import { GET_ALL_STONES, SORT_ALL_PRODUCTS } from "../constant"

const initState = {
  products: [],
  stonesData: [],
  cartCounter: 0,
}
const getStoneReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_STONES:
      return {
        ...state,
        products: action.payload,
      }
    // case SORT_ALL_PRODUCTS:
    //   return {
    //     ...state,
    //     products: action.payload,

    //   }
    // case "INCREMENT":
    //   return {
    //     ...state, cartCounter: state.cartCounter + 1
    //   }
    // case "DECREMENT":
    //   return {
    //     ...state, cartCounter: state.cartCounter - 1
    //   }

    default:
      return state
  }
}
export default getStoneReducer
