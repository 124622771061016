import React, { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
export const RenderStoneDetails = ({ title, stones }) => {
    return (
      <>
        {stones && (
          <div>
            <Accordion
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none",
                  m: 0,
                },
              }}
            >
              <AccordionSummary
                sx={{
                  "&.MuiAccordionSummary-root": {
                    padding: 0,
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>
                  {title} ({stones?.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {stones.map((stone, index) => (
                  <Box key={index}>
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          // mt: "-1rem",
                        }}
                      >
                        <Typography>Variety:</Typography>
                        <Typography>
                          {stone.stone_types || stone?.type}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px dashed rgba(0, 0, 0, 0.12)",
                          width: "78%",
                          ml: "4.5rem",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.6rem",
                        }}
                      >
                        <Typography>Shape:</Typography>
                        <Typography>{stone.shapes}</Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px dashed rgba(0, 0, 0, 0.12)",
                          width: "78%",
                          ml: "4.5rem",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.6rem",
                        }}
                      >
                        <Typography>Origin:</Typography>
                        <Typography>{stone.countries}</Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px dashed rgba(0, 0, 0, 0.12)",
                          width: "78%",
                          ml: "4.5rem",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.6rem",
                        }}
                      >
                        <Typography>Treatment:</Typography>
                        <Typography>{stone.stone_type_treatments}</Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px dashed rgba(0, 0, 0, 0.12)",
                          width: "78%",
                          ml: "4.5rem",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.6rem",
                        }}
                      >
                        <Typography>Dimensions:</Typography>
                        <Typography>
                          {stone.measurements
                            ?.split("x")
                            .map((dim) => dim + "mm")
                            .join(" x ")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px dashed rgba(0, 0, 0, 0.12)",
                          width: "78%",
                          ml: "4.5rem",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.6rem",
                        }}
                      >
                        <Typography>Weight:</Typography>
                        <Typography>
                          {stone.weight}
                          {stone.weight_type}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "2px solid rgba(0, 0, 0, 0.12)",
                          width: "100%",
                          my: "0.6rem",
                        }}
                      />
                    </>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </>
    )
  }
  