import React, { useEffect, useRef, useState } from "react"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import {
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  IconButton,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
const valuetext = (value) => {
  return `${value}$`
}

const FiltersRange = ({
  name,
  label,
  rangeSymbol,
  value,
  handleSelectFilter,
  maxValue,
  productData,
}) => {
  const [slideValue, setSlideValue] = useState([0, maxValue])
  const textFieldRef = useRef(null)
  const [show, setShow] = React.useState(true)
  useEffect(() => {
    if (value) {
      setSlideValue(value)
    }
  }, [value])

  const handleChange = (event, newValue) => {
    setSlideValue(newValue)
  }

  const handleRange = (rangeValue) => {
    let rangeObj = {
      target: {
        name,
        value: rangeValue,
      },
    }
    handleSelectFilter(rangeObj)
  }

  const handleSliderChangeCommitted = (event, newValue) => {
    handleRange(newValue)
  }

  const handleTextFieldBlur = (event) => {
    const newValue = parseFloat(event.target.value)
    if (!isNaN(newValue)) {
      const index = event.target.id === "from" ? 0 : 1
      const updatedValue = [...slideValue]
      updatedValue[index] = newValue
      setSlideValue(updatedValue)
      handleRange(updatedValue)
    }
  }

  return (
    <Box sx={{ borderTop: "1px solid #E0E0E0", mt: "16px" }}>
      <Box
        sx={{
          width: "254px",
          // mx: "auto",
          // maxHeight: "212px",
          mt: "1rem",
          // overflowY: "auto",
          // scrollbarWidth: "thin",
          // scrollbarColor: "#999999 #F5F5F5",
          // "&::-webkit-scrollbar": {
          //   height: "4px",
          //   width: "4px",
          // },
          // "&::-webkit-scrollbar-thumb": {
          //   backgroundColor: "#999999",
          //   borderRadius: "4px",
          // },
          // "&::-webkit-scrollbar-track": {
          //   backgroundColor: "#F5F5F5",
          //   borderRadius: "4px",
          // },
          // "&::-webkit-scrollbar-corner": {
          //   backgroundColor: "#F5F5F5",
          // },
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 1,
            marginBottom: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                textTransform: "uppercase",
                fontFamily: "mulish-regular",
                ml: "8px",
              }}
            >
              {label}
            </Typography>

            <IconButton
              sx={{ p: 0, mr: "18px" }}
              onClick={() => setShow(!show)}
            >
              {show ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Box>
        </Box>

        {show && (
          <Box sx={{ mx: "8px", mt: "0.6rem" }}>
            <Slider
              getAriaLabel={() => label}
              name={name}
              value={slideValue}
              max={maxValue}
              onChange={handleChange}
              onChangeCommitted={handleSliderChangeCommitted}
              getAriaValueText={valuetext}
              disabled={productData ? false : true}
              sx={{
                color: "#333333",
                height: 3,
                padding: "13px 0",
                "& .MuiSlider-thumb": {
                  height: 12,
                  width: 12,
                  backgroundColor: "#fff",
                  border: "1px solid #858585",
                  "&:hover": {
                    boxShadow: "0 0 0 2px #858585",
                  },
                },
                "& .MuiSlider-track": {
                  height: 3,
                },
                "& .MuiSlider-rail": {
                  color: "#d8d8d8",
                  opacity: 1,
                  height: 3,
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                ref={textFieldRef}
                sx={{
                  marginRight: "0.2rem",
                  width: "6rem",
                  fontFamily: "mulish-regular",
                }}
                id='from'
                key={name + 1}
                value={slideValue[0]}
                onChange={(e) => {
                  const newValue = [+e.target.value, slideValue[1]]
                  setSlideValue(newValue)
                }}
                onBlur={handleTextFieldBlur}
                // label="From"
                type='number'
                variant='outlined'
                InputProps={{
                  inputProps: {
                    max: maxValue,
                    min: 0,
                  },
                  // startAdornment: (
                  //   <InputAdornment position="start">{rangeSymbol}</InputAdornment>
                  // ),
                }}
                size='small'
              />

              <Box>-</Box>

              <TextField
                ref={textFieldRef}
                sx={{
                  marginLeft: "0.2rem",
                  width: "6rem",
                  fontFamily: "mulish-regular",
                }}
                id='to'
                key={name + 2}
                value={slideValue[1]}
                onChange={(e) => {
                  const newValue = [slideValue[0], +e.target.value]
                  setSlideValue(newValue)
                }}
                onBlur={handleTextFieldBlur}
                // label="To"
                type='number'
                variant='outlined'
                InputProps={{
                  inputProps: {
                    max: maxValue,
                    min: 0,
                  },
                  // startAdornment: (
                  //   <InputAdornment position="start">{rangeSymbol}</InputAdornment>
                  // ),
                }}
                size='small'
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#858585",
                  fontFamily: "mulish-regular",
                }}
              >
                Min ({rangeSymbol})
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#858585",
                  fontFamily: "mulish-regular",
                }}
              >
                Max ({rangeSymbol})
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FiltersRange
