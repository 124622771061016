import { useSelector } from "react-redux"
import { loadStripe } from "@stripe/stripe-js"
import React, { useState, useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "../components/form/CheckoutForm"
import { PaymentService } from "../services/payment-service"
import { userPaymentKey } from "../services/get-user-payment-publish-key"

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// const getpaymentKey = async () => {
//   const [keyId, setKeyId] = useState(null)
//   const [stripeObj, setStripeObj] = useState(null)
//   const response = await userPaymentKey({
//     user_id:
//       localStorage.getItem("user_id") === null
//         ? window.config.vendor_id
//         : localStorage.getItem("user_id"),
//     user_type:
//       localStorage.getItem("user_type") === null
//         ? "vendor"
//         : localStorage.getItem("user_type"),
//   })
//   const paymentKeys = response?.data?.data
//   setKeyId(paymentKeys)
//   if (paymentKeys) {
//     const paypalKey = paymentKeys.find((key) => key.payment_type === "stripe")
//     if (paypalKey) {
//       setStripeObj(paypalKey)
//     }
//   }
// }

// useEffect(() => {
//   getpaymentKey()
// }, [])

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)

export default function StripeForm(props) {
  const [clientSecret, setClientSecret] = useState(props.clientSecret)
  const [key, setKey] = useState(props.publishKey)

  const cart = JSON.parse(localStorage.getItem("cartItems"))

  // const getClientSecret = () => {
  // 	let sum = cart.reduce(
  // 		(prev, current) => prev + +current.custome_price_total,
  // 		0
  // 	)

  // 	PaymentService.getClientSecret({
  // 		amount: sum,
  // 		vendor_id: window.config.vendor_id,
  // 		currency: cart[0]?.custome_price_currency,
  // 	})
  // 		.then(res => {
  // 			if (res.data.success) setClientSecret(res.data.result.client_secret)
  // 		})
  // 		.catch(err => console.log(err))
  // }

  // useEffect(() => {
  // 	let sum = cart.reduce(
  // 		(prev, current) => prev + +current.custome_price_total,
  // 		0
  // 	)
  // 	if (sum !== 0) getClientSecret()
  // }, [])
  if (!key) {
    // API call has not completed, so don't render anything
    return null;
  }

  const stripePromise = loadStripe(key);
  const appearance = {
    theme: "stripe",
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div
      className='mainWrapper w-100 mt-0'
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {clientSecret && key && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm cart={cart} />
        </Elements>
      )}
    </div>
  )
}
