import api from "./API"

// export const ContactForm = async (data) => {
//   return await api.post("/contact-us", data)
// }
// const url = window.location.pathname
export const ContactForm = async (formData) => {
  try {
    // Make the API request
    const response = await fetch(`${window.config.api_url}/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
    if (!response.ok) {
        // If the response status is not OK (e.g., 400 or 500), throw an error
        const errorResponse = await response.json(); // Parse error response
  
        if (errorResponse.errors) {
          // Handle multiple field validation errors
          const errorFields = Object.keys(errorResponse.errors);
          const errorMessages = errorFields.map((field) => {
            return `${field}: ${errorResponse.errors[field][0]}`;
          });
          throw new Error(errorMessages.join('\n'));
        } else {
          // Handle other types of errors
          throw new Error('An error occurred.');
        }
      }
  
    // Parse the response JSON and return it
    return await response.json()
  } catch (error) {
    // Handle errors here and return a response with an error message
    return {
      error: true,
      message: error.message, // You can customize the error message
    }
  }
}
