import "./App.css"
import React, { useState } from "react"
import { Routes, Route } from "react-router-dom"
import { HomePage } from "./components/HomePage/HomePage"
import { LoginNew } from "./components/Auth/NewAuth/Login"
import { SignupNew } from "./components/Auth/NewAuth/Signup"
import { protectedRoutes, publicRoutes } from "./routes"
import Error from "./components/Error/Error"
import RouteMiddleware from "./routes/middleware"
import MainContent from "./components/Products/MainContent"
import AdminLogin from "./components/Auth/NewAuth/AdminLogin"
import { Toaster } from "react-hot-toast"
import { PDetail } from "./pages/PDetail"
import { useLocation } from "react-router-dom"
import { ProductDetails } from "./components/Products/ProductDetails"
import { ProductDetail } from "./components/ProductDetail/ProductDetail"
import { ContactUs } from "./components/ContactUs/ContactUs"
import { Aboutus } from "./components/Aboutus/Aboutus"
let alreadyLoggedIn = localStorage.getItem("token") ? true : false
const App = () => {
  const [user, setuser] = useState(null)
  const [theme, setTheme] = useState("noTheme")
  const [isAuth, setIsAuth] = useState(alreadyLoggedIn)

  const handleLogin = (data) => {
    setuser(data.user)
    setIsAuth(true)
  }

  const handleSignup = (data) => {
    setuser(data.user)
    setIsAuth(true)
  }

  const setUser = (user) => {
    setuser(user)
    setIsAuth(false)
  }
  const location = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <>
      <Toaster
        position='top-center'
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "white",
            },
          },
        }}
      />
      <Routes>
        <Route
          exact
          path='/login'
          element={<LoginNew handleLogin={handleLogin} isAuth={isAuth} />}
        />
        <Route
          exact
          path='/signup'
          // element={<Signup handleLogin={handleSignup} isAuth={isAuth} />}
          element={<SignupNew />}
        />
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/Contact-us' element={<ContactUs />} />
        <Route exact path='/About-us' element={<Aboutus />} />
        <Route
          exact
          path='/admin/login/:id'
          element={<AdminLogin handleLogin={handleLogin} isAuth={isAuth} />}
        />

        <Route
          exact
          path='/products/:parcel_type_ids'
          element={<MainContent />}
        />
        <Route
          exact
          path='*'
          element={<Error />}
        />
        <Route
          exact
          path='detail/:id'
          // element={<ProductDetails />}
          element={<ProductDetail />}
          // element={<PDetail />}
        />
        {/* {
    path: "",
    element: <MainContent />,
    errorElement: <ErrorPage />,
  }, */}

        {/* <Route
        exact
        path='/'
        element={<RouteMiddleware isProtected={false} />}
        children={publicRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            isProtected={false}
            element={route.element}
          />
        ))}
      /> */}

        {/* admin routes */}
        <Route
          exact
          path='/'
          element={<RouteMiddleware isProtected={true} />}
          children={protectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              isProtected={true}
              element={route.element}
            />
          ))}
        />
      </Routes>
    </>
  )
}
export default App
