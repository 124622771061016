import React, { useContext, useEffect } from "react"
import CardMedia from "@mui/material/CardMedia"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { GlobalContext } from "../../context/GlobalState"
import { useNavigate } from "react-router-dom"
import { IconButton, Link } from "@mui/material"
import { toast } from "react-hot-toast"

export const ProductCard = ({ item }) => {
  const { addToCart, addToWishList, removeFromWishlist } =
    useContext(GlobalContext)
  let wishlistItems = [];
try {
  const wishlistData = localStorage.getItem("wishlistItems");
  if (wishlistData) {
    wishlistItems = JSON.parse(wishlistData);
  }
} catch (error) {
  console.error("Error parsing wishlist data:", error);
  // Handle the error appropriately
}
  const cart = JSON.parse(localStorage.getItem("cartItems"))
  const token = localStorage.getItem("token")
  const userType = localStorage.getItem("user_type")
  const navigate = useNavigate()
  const addToWishListHandler = () => {
    if (token === null) {
      toast.error("Please sign in to add the item to your wishlist.")
    } else {
      const inCart = cart?.some((cartItem) => cartItem.id === item.id)

      if (!inCart) {
        addToWishList(item)
      } else {
        toast.error("item already in the cart")
      }
    }
  }
  const removeFromtheWishList = () => {
    const inWishlist = wishlistItems.some(
      (wishlistItem) => wishlistItem.id === item.id
    )
    if (inWishlist) {
      removeFromWishlist(item.id)
    }
  }
  const stoneDetail = [
    item.weight + item.weight_type,
    item.countries,
    item.stone_type_treatments,
  ]
  // useEffect(() => {
  //   const wishListFromLC = JSON.parse(localStorage.getItem("wishlistItems"))
  //   if (wishListFromLC) {
  //     wishListFromLC?.map((wI) => {
  //       addToWishList(wI)
  //     })
  //   }
  // }, [])

  return (
    <Box
      sx={{
        width: { mobile: "50%", laptop: "30.75%" },
        border: { laptop: "1px solid #E0E0E0" },
        borderRadius: "0.25rem",
        p: "1rem",
        ml: { laptop: "24px" },
        mt: "1rem",
        position: "relative",
        height: "407px",
        display:"flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component='img'
          loading="lazy"
          image={item.image}
          alt='stone'
          sx={{
            width: { mobile: "100%", mobile2: "auto" },
            maxWidth: { mobile: "170px", tablet: "200px" },
            mx: "auto",
            height: "11.125rem",
            minHeight: "11.125rem",
            bgcolor: "#F8F8F8",
          }}
        />
      </Box>


      {wishlistItems?.every((list) => {
          return list.id !== item.id
        }) ? (
          <>
        
          <IconButton
          onClick={() => {
            addToWishListHandler()
            removeFromtheWishList()
          }}
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.25rem",
            zIndex: "1",
            bgcolor: "white",
            border: "0.5px solid #E0E0E0",
            borderRadius: "0.25rem",
          }}
        >
       
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.0354 1.33332C10.0703 1.33331 9.44822 1.44829 8.73506 1.79736C8.47103 1.9266 8.22332 2.08224 7.99327 2.26382C7.77209 2.09242 7.53428 1.94393 7.28117 1.81874C6.55417 1.45916 5.90015 1.33331 4.96966 1.33331C2.47758 1.33331 0.666626 3.39816 0.666626 6.0799C0.666626 8.10515 1.79585 10.0615 3.89919 11.9532C5.00322 12.9462 6.41284 13.9289 7.42236 14.4516L7.99996 14.7507L8.57756 14.4516C9.58708 13.9289 10.9967 12.9462 12.1007 11.9532C14.2041 10.0615 15.3333 8.10515 15.3333 6.0799C15.3333 3.42654 13.5053 1.34355 11.0354 1.33332ZM14 6.0799C14 7.66658 13.0574 9.29952 11.2091 10.9619C10.2014 11.8682 8.90593 12.7739 7.99996 13.2491C7.09399 12.7739 5.79851 11.8682 4.79081 10.9619C2.94251 9.29952 1.99996 7.66658 1.99996 6.0799C1.99996 4.09846 3.25572 2.66665 4.96966 2.66665C5.70948 2.66665 6.16538 2.75437 6.69005 3.01388C6.99996 3.16716 7.27388 3.36956 7.51098 3.62249L8.00152 4.14578L8.48759 3.61835C8.73003 3.35528 9.00797 3.14827 9.32124 2.99493C9.83054 2.74564 10.258 2.66665 11.0327 2.66665C12.7254 2.67367 14 4.12596 14 6.0799Z'
                fill='#333333'
              />
            </svg>
      </IconButton>

            </>
        ) : (
          <IconButton
          onClick={() => {
            addToWishListHandler()
            removeFromtheWishList()
          }}
          sx={{
            position: "absolute",
            top: "1.25rem",
            right: "1.25rem",
            zIndex: "1",
            bgcolor: "black",
            border: "0.5px solid #E0E0E0",
            borderRadius: "0.25rem",
            '&:hover': {
              bgcolor: "black", 
            },
          }}
        >
       
       <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M11.0354 1.33332C10.0703 1.33331 9.44822 1.44829 8.73506 1.79736C8.47103 1.9266 8.22332 2.08224 7.99327 2.26382C7.77209 2.09242 7.53428 1.94393 7.28117 1.81874C6.55417 1.45916 5.90015 1.33331 4.96966 1.33331C2.47758 1.33331 0.666626 3.39816 0.666626 6.0799C0.666626 8.10515 1.79585 10.0615 3.89919 11.9532C5.00322 12.9462 6.41284 13.9289 7.42236 14.4516L7.99996 14.7507L8.57756 14.4516C9.58708 13.9289 10.9967 12.9462 12.1007 11.9532C14.2041 10.0615 15.3333 8.10515 15.3333 6.0799C15.3333 3.42654 13.5053 1.34355 11.0354 1.33332ZM14 6.0799C14 7.66658 13.0574 9.29952 11.2091 10.9619C10.2014 11.8682 8.90593 12.7739 7.99996 13.2491C7.09399 12.7739 5.79851 11.8682 4.79081 10.9619C2.94251 9.29952 1.99996 7.66658 1.99996 6.0799C1.99996 4.09846 3.25572 2.66665 4.96966 2.66665C5.70948 2.66665 6.16538 2.75437 6.69005 3.01388C6.99996 3.16716 7.27388 3.36956 7.51098 3.62249L8.00152 4.14578L8.48759 3.61835C8.73003 3.35528 9.00797 3.14827 9.32124 2.99493C9.83054 2.74564 10.258 2.66665 11.0327 2.66665C12.7254 2.67367 14 4.12596 14 6.0799Z'
              fill='white'
            />
          </svg>
      </IconButton>

          ) }
 
        
      
    

      <Typography
        sx={{
          fontSize: "0.875rem",
          fontWeight: 400,
          color: "#666666",
          mt: "0.5rem",
          lineHeight:"20px",
          // maxHeight: "1.25rem",
          fontFamily: "mulish-regular",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        SKU # : {item.internal_reference_number}
      </Typography>
      <Typography
        sx={{
          fontSize: {mobile:"18px", tablet:"20px"},
          fontWeight: 700,
          color: "#333333",
          lineHeight:"26.4px",
          // minHeight: "1.6875rem",
          fontFamily: "KaiseiDecol-Regular",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {item?.stone_types}   {item?.jewelry_type}      
      </Typography>

      <Typography       sx={{
          fontSize: "16px",
          fontWeight: 400,
          color: "#666666",
          fontFamily: "KaiseiDecol-Regular",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          // display:"inline-block"
        }}>
{item?.metal
}
      </Typography>
      <Box sx={{ display: "flex" }}>
        {stoneDetail
          .filter((item) => item)
          .map((item, index) => {
            const displayText =
              item.length > 11 ? item.substring(0, 8) + "..." : item
            return (
              <Typography
                // component='span'
                key={index}
                sx={{
                  color: "#333333",
                  fontSize: "0.875rem",
                  fontFamily: "mulish-regular",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {displayText}&nbsp;
                {index !== stoneDetail.filter((i) => i).length - 1 ? "|" : null}
                &nbsp;
              </Typography>
            )
          })}
      </Box>
      {token ? (
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: 700,
            color: "#333333",
            mt: "1rem",
            lineHeight:"28px ",
            // minHeight: "1.75rem",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          {item.currency_logo + item.total_selling_price}
        </Typography>
      ) : (
        <Typography
          onClick={() => navigate("/login")}
          sx={{
            fontSize: "1.125rem",
            lineHeight:"26px",
            fontWeight: 400,
            fontFamily: "mulish-regular",
            mt: "1rem",
            cursor: "pointer",
          }}
        >
          Login to see price
        </Typography>
      )}
      <a
    
   href={`/detail/${item.id}`}>
        
      <Button
  
        fullWidth
        sx={{
          p:"8px",
          bgcolor: "#1A1A1A",
          color: "white",
          fontSize: "18px",
          textTransform: "capitalize",
          mt: "1rem",
          fontFamily: "mulish-regular",
          fontWeight:400,
          lineHeight:"144.444%",
          "&.MuiButton-root:hover": {
            color: " white",
            bgcolor: "#333333",
            borderColor: " #333333",
          },
        }}

      
      >
          View
      
      </Button>
      </a>
    </Box>
  )
}
