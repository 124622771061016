import React from "react"
import StripeForm from "../../pages/StripeForm"

const StripeModal = ({ clientSecret, publishKey }) => {
  return (
    <div
      tabIndex='-1'
      id='stripeModal'
      className='modal fade'
      data-backdrop='static'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header d-flex justify-content-end'>
            {/* <h5 className='modal-title'>Add New Address</h5> */}
            <button
              type='button'
              className='close'
              aria-label='Close'
              data-bs-dismiss='modal'
              style={{
                backgroundColor: "white",
                color: "black",
                marginRight: "1rem",
              }}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            {!clientSecret && (
              <h5 className='text-center'>Loading Elements...</h5>
            )}
            {clientSecret && (
              <StripeForm clientSecret={clientSecret} publishKey={publishKey} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StripeModal
