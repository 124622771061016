import React, { useEffect, useState } from "react"
import { Box } from "@mui/system"
import { Button, Typography } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { convertObjectToUrlSearch, convertUrlSearch } from "./MainContent"
import { parametersService } from "../../services/parameters-service"
import FilterDimensions from "../Layout/common/FilterDimensions"
import FiltersRange from "../Layout/common/FiltersRange"

import { FiltersSelectMultiple } from "../Layout/common/FiltersSelectMultiple"

export const updateLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

const initialFilterState = {
  // weight_range: [0, 200],
  // price_range: [0, 100000],
}

const Filters = ({ productData, onClose }) => {
  const [filtersOptions, setfiltersOptions] = useState({})
  const [filters, setFilters] = useState({
    weight_range: [0, filtersOptions?.weight?.max],
    price_range: [0, filtersOptions?.total_selling_price?.max],
  })
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)
  const { search } = useLocation()
  const { parcel_type_ids } = useParams()

  const navigate = useNavigate()

  let objectFromUrl = convertUrlSearch(search)

  const getFilterOptions = async () => {
    setLoading(true)
    const response = await parametersService({
      user_id:
        localStorage.getItem("user_id") === null
          ? window.config.vendor_id
          : localStorage.getItem("user_id"),
      user_type:
        localStorage.getItem("user_type") === null
          ? "vendor"
          : localStorage.getItem("user_type"),
      parcel_type_ids: parcel_type_ids?.split(","),

      ...objectFromUrl,
    })
    setfiltersOptions(response?.data?.data)
    setLoading(false)
  }

  useEffect(() => {
    getFilterOptions()

    setFilters({
      ...initialFilterState,
      ...objectFromUrl,
      parcel_type_id: parcel_type_ids?.split(","),
    })
  }, [search, parcel_type_ids])

  const handleSelectFilter = (e) => {
    let modifiedFilters = filters

    let { name, value } = e.target

    modifiedFilters[name] = value

    setFilters({ ...modifiedFilters })

    convertObjectToUrlSearch(modifiedFilters, navigate)
  }

  const filterOptions = (nameAndfilterValueKey, label, filterOptionsKey) => {
    return (
      filtersOptions[filterOptionsKey] && (
        <Box sx={{ borderTop: "1px solid #E0E0E0", mt: "16px" }}>
          <FiltersSelectMultiple
            productLength={productData?.data.length}
            name={nameAndfilterValueKey}
            label={label}
            value={filters[nameAndfilterValueKey]}
            options={filtersOptions[filterOptionsKey]}
            handleSelectFilter={handleSelectFilter}
            loading={loading}
            onClose={onClose}
          />
        </Box>
      )
    )
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          // size='small'
          variant='outlined'
          disabled={loading}
          onClick={() => {
            setFilters({
              weight_range: [0, filtersOptions?.weight?.max],
              price_range: [0, filtersOptions?.total_selling_price?.max],
            })
            convertObjectToUrlSearch({}, navigate)
          }}
          sx={{
            color: "black",
            fontSize: "16px",
            textTransform: "capitalize",
            width: "254px",
            borderColor: "black",
            height: "42px",
            // mt: "16px",
            fontFamily: "mulish-regular",
            "&.MuiButton-root:hover": {
              color: " white",
              bgcolor: "#333333",
              borderColor: " #333333",
            },
          }}
        >
          Clear All
          
        </Button>
      </Box>

      {filterOptions("category_ids", "Categories", "categories")}
      {token && productData && (
        <FiltersRange
          name='price_range'
          label={`Price Range`}
          maxValue={filtersOptions.total_selling_price?.max}
          productData={productData}
          rangeSymbol={filtersOptions?.total_selling_price?.currency_format}
          value={filters?.price_range}
          handleSelectFilter={handleSelectFilter}
        />
      )}

      {filterOptions("stone_type_id", "Variety", "variety")}
      {filterOptions("diamond_type_id", "Diamond Types", "diamond_types")}
      {filterOptions("color_id", "Colors", "colors")}
      {filterOptions("jewellery_type_id", "Jewelry Types", "jewelry_types")}
      {filterOptions("shape_id", "Shape", "shapes")}
      {filterOptions("origin_id", "Origin", "origins")}
      {filterOptions("treatment_id", "Treatment", "treatments")}
      {filterOptions("metal_ids", "Metal", "metals")}
      {filterOptions("metal_color_ids", "Metal Colors", "metal_colors")}
      {filterOptions("style_ids", "Styles", "styles")}
      {filterOptions("ocassion_ids", "Ocassions", "ocassions")}
      {filterOptions("gender_type_ids", "Gender Type", "gender_types")}
      {filterOptions("clarity_id", "Clarity Grade", "clarities")}
      {filterOptions("saturation_id", "Color Grade", "saturations")}
      {filterOptions("engraving_ids", "Engraving", "engravings")}
      {filterOptions("cut_grade_id", "Cut Grade", "cut_grades")}
      {productData && (
        <FiltersRange
          name='weight_range'
          label='Weight'
          maxValue={filtersOptions.weight?.max}
          productData={productData}
          rangeSymbol='ct'
          value={filters?.weight_range}
          handleSelectFilter={handleSelectFilter}
        />
      )}
      {/* <br /> */}

      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant='outlined'
          disabled={loading}
          onClick={() => {
            setFilters({
              weight_range: [0, filtersOptions?.weight?.max],
              price_range: [0, filtersOptions?.total_selling_price?.max],
            })
            convertObjectToUrlSearch({}, navigate)
          }}
          sx={{
            color: "black",
            fontSize: "16px",
            textTransform: "capitalize",
            width: "254px",
            borderColor: "black",
            height: "42px",
            // mt: "16px",
            fontFamily: "mulish-regular",
            "&.MuiButton-root:hover": {
              color: " white",
              bgcolor: "#333333",
              borderColor: " #333333",
            },
          }}
        >
          Clear All
        </Button>
      </Box> */}
      <br />
      <br />
      <br />
      <br />
      <br />
    </Box>
  )
}
export default Filters
