import React, { useEffect, useState } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import ImageMagnifier from "../../../Products/ImageMagnifier"
import { Navigation, Pagination } from "swiper"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { BsFillPlayFill } from "react-icons/bs"
import ReactPlayer from "react-player"
// import "swiper/css"
// import "../../swiperModificationMobile.css"
import { useNavigate } from "react-router-dom"
import Slider from "react-slick"

export const ProductDetailHeadline = ({ item, token, sliderItems }) => {
  const stoneDetail = [
    item.stone_type_colors,
    item.clarities,
    item.stone_type_treatments,
  ]
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [imgIndex, setImgIndex] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex > 0 ? prevIndex - 1 : prevIndex)
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex < slideImage.length -1  ? prevIndex + 1 : prevIndex )
  }

  const handleImageSelect = (image, index) => {
    setSelectedImage(image)
    setImgIndex(index)
  }

  const slideImage = [
    ...(sliderItems.length >= 3
      ? [
          sliderItems[
            (currentIndex - 1 + sliderItems.length) % sliderItems.length
          ],
          sliderItems[currentIndex],
          sliderItems[(currentIndex + 1) % sliderItems.length],
        ]
      : sliderItems),
  ]
  useEffect(() => {
    setSelectedImage(sliderItems[0])
  }, [])

  const goToFirstIndex = () => {
    if (imgIndex !== 0) {
      setCurrentIndex(0)
      setImgIndex(0)
      setSelectedImage(sliderItems[0])
    }
  }

  const goToLastIndex = () => {
    if (imgIndex !== sliderItems.length - 1) {
      setCurrentIndex(sliderItems.length - 1)
      setImgIndex(sliderItems.length - 1)
      setSelectedImage(sliderItems[sliderItems.length - 1])
    }
  }
  const goToVideoIndex = () => {
    const videoIndex = sliderItems.findIndex((item) => item.type === "VIDEO")
    if (videoIndex !== -1) {
      setCurrentIndex(videoIndex)
      setSelectedImage(sliderItems[videoIndex])
      setImgIndex(videoIndex)
    }
  }

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          color: " #858585",
          //   my: "4px",
          fontFamily: "mulish-regular",
        }}
      >
        SKU#:&nbsp;
        {item?.internal_reference_number}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "32px", md: "40px" },
          fontWeight: 700,
          color: "#333333",
          fontFamily: "KaiseiDecol-Regular",
        }}
      >
        {item?.stone_types}
      </Typography>

      {token ? (
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            fontWeight: 700,
            color: "#333333",
            mt: "8px",
            minHeight: "28px",
            mb: "24px",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          {item.currency_logo + item.total_selling_price}
          <Typography
            component='span'
            sx={{
              fontWeight: 400,
              color: "#333333",
              ml: "8px",
              fontFamily: "mulish-regular",
            }}
          >
            ({item?.selling_price_per_carat + " " + item?.currency_format}
            /Carat)
          </Typography>
        </Typography>
      ) : (
        <Typography
          onClick={() => navigate("/login")}
          sx={{
            fontSize: "18px",
            fontWeight: 400,
            fontFamily: "mulish-regular",
            my: "16px",
            cursor: "pointer",
          }}
        >
          Login to see price
        </Typography>
      )}
      {/* for mobile */}
      <Box
        sx={{ display: { xs: "flex", sm: "none" }, flexDirection: "column" }}
      >
        <Box
          sx={{
            border: "1px solid  #E0E0E0",
            width: "100%",
            height: "209px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                alignItems: "center",
              }}
            >
              {selectedImage && (
                <div>
                  {selectedImage.type === "IMAGE" && (
                    <ImageMagnifier src={selectedImage.url} />
                  )}
                  {selectedImage.type === "lab360" && (
                    <iframe
                      title='360'
                      src={selectedImage.url}
                      width='100%'
                      height='100%'
                      scrolling='no'
                    ></iframe>
                  )}
                  {selectedImage.type === "vendor360" && (
                    <iframe
                      title='360'
                      src={selectedImage.url}
                      width='100%'
                      height='100%'
                      scrolling='no'
                    ></iframe>
                  )}
                  {selectedImage.type === "VIDEO" && (
                    <ReactPlayer url={selectedImage.url} width='100%' />
                  )}
                </div>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "1.25rem",
              zIndex: "1",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", pl: "16px" }}>
              <Box
                sx={{
                  border: "0.5px solid #E0E0E0",
                  cursor: "pointer",
                  display: sliderItems.some((item) => item.type === "IMAGE")
                    ? "block"
                    : "none",
                }}
                onClick={goToFirstIndex}
              >
                <Box component='img' src='/photo.png' />
              </Box>
              <Box
                sx={{
                  border: "0.5px solid #E0E0E0",
                  mt: "16px",
                  cursor: "pointer",
                  display: sliderItems.some(
                    (item) =>
                      item.type === "vendor360" || item.type === "lab360"
                  )
                    ? "block"
                    : "none",
                }}
                onClick={goToLastIndex}
              >
                <Box component='img' src='/three360.png' />
              </Box>
              <Box
                sx={{
                  border: "0.5px solid #E0E0E0",
                  mt: "16px",
                  cursor: "pointer",
                  display: sliderItems.some((item) => item.type === "VIDEO")
                    ? "block"
                    : "none",
                }}
                onClick={goToVideoIndex}
              >
                <Box component='img' src='/images/viewVideo.png' />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: "24px",
            width: "100%",

            justifyContent: "space-between",
          }}
        >
          <IconButton
            sx={{
              height: "16px",
              width: "16px",
              bgcolor: " #E0E0E0",
              borderRadius: "4px",
              "&.MuiIconButton-root:hover": {
                bgcolor: "#E0E0E0",
              },
              display: sliderItems.length <= 3 ? "none" : "flex",
            }}
            onClick={handlePrev}
          >
            <ArrowBackIosIcon
              sx={{
                ml: "6px",
                fontSize: "12px",
              }}
            />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent:
                sliderItems.length === 1 ? "flex-start" : "space-around",
              width:
                sliderItems.length === 1
                  ? "100%"
                  : sliderItems.length <= 2
                  ? "55%"
                  : "100%",

              mx: "auto",
            }}
          >
            {slideImage.map((item, index) => {
              if (item.type === "IMAGE") {
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "72px",
                      height: "72px",
                      border:
                        "1px solid var(--shades-neutrals-grey-300, #E0E0E0)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component='img'
                      src={item.url}
                      alt={`Image ${index + 1}`}
                      sx={{ width: "60px", height: "100%" }}
                      onClick={() => handleImageSelect(item)}
                    />
                  </Box>
                )
              } else if (item.type === "lab360" || item.type === "vendor360") {
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "72px",
                      height: "72px",
                      border:
                        "1px solid var(--shades-neutrals-grey-300, #E0E0E0)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component='img'
                      src={"/images/slide360.png"}
                      alt={`Image ${index + 1}`}
                      sx={{ width: "30px" }}
                      onClick={() => handleImageSelect(item)}
                    />
                  </Box>
                )
              } else if (item.type === "VIDEO") {
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "72px",
                      height: "72px",
                      border:
                        "1px solid var(--shades-neutrals-grey-300, #E0E0E0)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BsFillPlayFill
                      size={70}
                      style={{ margin: "auto", color: "#A3A3A3" }}
                      onClick={() => handleImageSelect(item)}
                    />
                  </Box>
                )
              } else {
                return null
              }
            })}
          </Box>
          <IconButton
            sx={{
              height: "16px",
              width: "16px",
              bgcolor: " #E0E0E0",
              borderRadius: "4px",
              "&.MuiIconButton-root:hover": {
                bgcolor: "#E0E0E0",
              },
              display: sliderItems.length <= 3 ? "none" : "flex",
            }}
            onClick={handleNext}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        </Box>
      </Box>
      {/* for mobile */}
      {stoneDetail
        .filter((item) => item)
        .map((item, index) => {
          return (
            <Typography
              component='span'
              key={index}
              sx={{
                color: "#333333",
                fontWeight: 400,
                fontFamily: "mulish-regular",
              }}
            >
              {" "}
              {item}
              {index !== stoneDetail.filter((i) => i).length - 1 ? " |" : ""}
            </Typography>
          )
        })}
      {item.measurements && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            color: "#333333",
            mt: "8px",
            fontFamily: "mulish-regular",
          }}
        >
          Dimensions:&nbsp;
          {item.measurements
            ?.split("x")
            .map((dim) => `${parseFloat(dim.trim()).toFixed(1)}`)
            .join(" x ")}
          &nbsp;mm
        </Typography>
      )}

      {/* <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#333333",
          my: "4px",
        }}
      >
        Weight:&nbsp;
        {item?.weight + item?.weight_type}
      </Typography> */}
      {/* <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          color: " #333333",
          my: "4px",
        }}
      >
        Origin:&nbsp;
        {item?.countries}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#333333",
          my: "4px",
        }}
      >
        Treatment:&nbsp;
        {item?.stone_type_treatments}
      </Typography> */}
    </Box>
  )
}
