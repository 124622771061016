import React, { useEffect, useState } from "react"
import { Country, State, City } from "country-state-city"
import { AddressService } from "../../services/address-service"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Typography,
  Box,
  IconButton,
  lighten,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { toast } from "react-hot-toast"

const NewAddressModal = ({
  setCustomerDetails,
  open,
  handleClose,
  handleOpen,
}) => {
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [newAddressData, setNewAddressData] = useState({
    city: "",
    phone: "",
    email: "",
    tax_id: "",
    country: "",
    postal_code: "",
    state_region: "",
    company_name: "",
    address_line_1: "",
    address_line_2: "",
  })

  const getCountries = () => {
    setCountries(Country.getAllCountries())
  }

  const addNewAddress = async (e) => {
    e.preventDefault()
    setLoading(true)

    let tempNewAddressData = newAddressData

    tempNewAddressData.country = countries.find(
      (country) => tempNewAddressData.country === country.isoCode
    ).name
    tempNewAddressData.state_region = states.find(
      (state) => tempNewAddressData.state_region === state.isoCode
    ).name

    tempNewAddressData.user_id = JSON.parse(
      localStorage.getItem("customer")
    )?.id

    await AddressService.addNewAddress(tempNewAddressData)
      .then((res) => {
        if (res.data.success) {
          setLoading(false)

          toast.success("New Address saved")

          let tempLocalCustomer = JSON.parse(localStorage.getItem("customer"))

          tempLocalCustomer.shipping_addresses.push(res.data.address)

          setCustomerDetails(tempLocalCustomer)

          localStorage.setItem("customer", JSON.stringify(tempLocalCustomer))

          setNewAddressData({
            city: "",
            phone: "",
            email: "",
            tax_id: "",
            country: "",
            postal_code: "",
            state_region: "",
            company_name: "",
            address_line_1: "",
            address_line_2: "",
          })

          handleClose()
        } else setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
    setLoading(false)
  }
  useEffect(() => {
    getCountries()
  }, [])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <Box
        sx={{
          px: "3rem",
          pt: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.3rem", fontWeight: 600 }}>
          Add New Address
        </Typography>
        <IconButton sx={{ p: 0, m: 0 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ px: "3rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Company Name'
              variant='outlined'
              fullWidth
              value={newAddressData.company_name}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  company_name: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Phone'
              variant='outlined'
              fullWidth
              value={newAddressData.phone}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  phone: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Email'
              variant='outlined'
              fullWidth
              value={newAddressData.email}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  email: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Tax ID'
              variant='outlined'
              fullWidth
              value={newAddressData.tax_id}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  tax_id: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Postal Code'
              variant='outlined'
              fullWidth
              value={newAddressData.postal_code}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  postal_code: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Country</InputLabel>
              <Select
                fullWidth
                label='Country'
                id='country'
                value={newAddressData.country}
                onChange={(e) => {
                  setCities(City.getCitiesOfCountry(e.target.value))
                  setStates(State.getStatesOfCountry(e.target.value))
                  setNewAddressData({
                    ...newAddressData,
                    country: e.target.value,
                  })
                }}
              >
                {countries.map((country, i) => (
                  <MenuItem key={i} value={country.isoCode}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel>State/Region</InputLabel>
              <Select
                fullWidth
                label='State/Region'
                placeholder='abc'
                id='state_region'
                value={newAddressData.state_region}
                onChange={(e) => {
                  setCities(
                    City.getCitiesOfState(
                      newAddressData.country,
                      e.target.value
                    )
                  )
                  setNewAddressData({
                    ...newAddressData,
                    state_region: e.target.value,
                  })
                }}
              >
                {states.map((state, i) => (
                  <MenuItem key={i} value={state.isoCode}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='City'
              variant='outlined'
              fullWidth
              value={newAddressData.city}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  city: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id='address_line_1'
              label='Address Line 1'
              multiline
              rows={5}
              value={newAddressData.address_line_1}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  address_line_1: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id='address_line_2'
              label='Address Line 2'
              multiline
              rows={5}
              value={newAddressData.address_line_2}
              onChange={(e) =>
                setNewAddressData({
                  ...newAddressData,
                  address_line_2: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: "3rem", pb: "1.5rem" }}>
        <Button
          size='small'
          sx={{
            bgcolor: "black",
            color: "white",
            fontSize: { mobile: "14px", laptop: "16px" },
            textTransform: "lowercase",

            mt: "16px",
            "&.MuiButton-root:hover": {
              color: " white",
              bgcolor: "#333333",
              borderColor: " #333333",
            },
            fontFamily: "mulish-regular",
            "& .capitalize-first": {
              "&::first-letter": {
                textTransform: "uppercase",
              },
            },
            "&.Mui-disabled": {
              color: "white",
              backgroundColor: lighten("#333333", 0.5),
              borderColor: "black",
              cursor: "not-allowed",
            },
          }}
          onClick={addNewAddress}
          disabled={
            loading ||
            Object.values(newAddressData).filter(
              (addressDataValue) => addressDataValue === ""
            ).length !== 0
          }
        >
          <span className='capitalize-first'>Add</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewAddressModal
