import { Box, Typography } from "@mui/material"
import React from "react"

export const BillingDetails = ({ title, billingDetails }) => {
  return (
    <Box>
      {billingDetails.map((item, index) => {
        return (
          <Box key={index} sx={{ display: "flex" }}>
            <Typography
              sx={{
                color: "#333333",
                mt: "0.6rem",
                fontWeight: 700,
                fontSize: "16px",
                fontFamily: "mulish-regular",
                minWidth: "124px",
              }}
            >
              {item.addressName}:
            </Typography>
            <Typography
              sx={{
                mt: "0.6rem",
                ml: "16px",
                // maxWidth: { xs: "9rem", sm: "100%" },
                fontWeight: 400,
                fontSize: { xs: "0.9rem", md: "1rem" },
                mb: index === billingDetails.length - 1 ? "0px" : "0px",
                fontFamily: "mulish-regular",
              }}
            >
              {item.addressValue}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
