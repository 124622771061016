import api from "./API";

export const productList = async (data, pageNumber) => {
  try {
    return await api.post(`/fetch-products?page=${pageNumber}`, data);
  } catch (error) {
    console.log(error);
  }
};
export const ProductService = {
  completedProducts: async (pageNumber, filters) => {
    return await api.post(`/website-completed-products?page=${pageNumber}`, {
      user_id: localStorage.getItem("user_id"),

      user_type: localStorage.getItem("user_type"),
      parcel_type_id: filters.parcel_type_id,
      stone_type_id: filters.stone_type_id,
      shape_id: filters.shape_id,
      stone_cut_id: filters.stone_cut_id,
      color_id: filters.color_id,
      origin_id: filters.origin_id,
      treatment_id: filters.treatment_id,
      size_start_from: filters.size_start_from,
      size_start_to: filters.size_start_to,
      size_end_from: filters.size_end_from,
      size_end_to: filters.size_end_to,
      reference_name: filters.reference_name,
      stone_length_from: filters.stone_length_from,
      stone_width_from: filters.stone_width_from,
      stone_height_from: filters.stone_height_from,
      stone_length_to: filters.stone_length_to,
      stone_width_to: filters.stone_width_to,
      stone_height_to: filters.stone_height_to,
      weight_range_from: filters.weight_range_from,
      weight_range_to: filters.weight_range_to,
      price_range_from: filters.price_range_from,
      price_range_to: filters.price_range_to,
    });
  },
  getAllStone: async (pageNumber) => {
    return await api.post(`/fetch-products?page=${pageNumber}`, {
      user_id: 20,
      user_type: "vendor",
      parcel_type_ids: [1, 4],
    });
  },

  detailsProduct: async (props) => {
    return await api.post(`/website-stone-detail`, {
      user_id: localStorage.getItem("user_id"),
      parcel_type_id: "1",
      stone_detail_id: props.id,
      user_type: localStorage.getItem("user_type"),
    });
  },

  productPages: async (reqData, opts) => {
    return await api.post(`/stone-detail`, reqData, opts);
  },
};
