import { CheckoutTwo } from "../components/Checkout/CheckoutTwo"
import { HomePage } from "../components/HomePage/HomePage"
import { Cart } from "../components/Products/cart/Cart"
import MainContent from "../components/Products/MainContent"
import { PDetail } from "../pages/PDetail"
import StripeForm from "../pages/StripeForm"
import Thankyou from "../pages/Thankyou"
import ThankyouQuote from "../pages/ThankyouQuote"
import ErrorPage from "../util/ErrorPage"

const customRoutes = [{ path: "", element: <div></div> }]

const publicRoutes = [{ path: "", element: <HomePage /> }]

const protectedRoutes = [
  {
    path: "cart",
    element: <Cart />,
    errorElement: <ErrorPage />,
  },
  {
    path: "checkout",
    // element: <NewCheckout />,
    element: <CheckoutTwo />,
    errorElement: <ErrorPage />,
  },
  {
    path: "stripe-checkout",
    element: <StripeForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "thankyou",
    element: <Thankyou />,
    errorElement: <ErrorPage />,
  },
  {
    path: "thankyou-quote",
    element: <ThankyouQuote />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "detail/:id",
  //   element: <PDetail />,
  //   errorElement: <ErrorPage />,
  // },
]

export { protectedRoutes, publicRoutes, customRoutes }
