import React, { useEffect, useState, useContext} from "react"
import {
  Box,
  Button,
  Container,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { fetchUserWebisteSetting } from "../../services/fetch-user-website-settings-service"
import { getSectionDetail } from "../../services/getSectionDetail"
import { Loader } from "../common/Loader/Loader"
import { ContactForm } from "../../services/form-contact-service"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import ReCAPTCHA from "react-google-recaptcha"
import { GlobalContext } from "../../context/GlobalState"
export const ContactUs = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
      },
    },
  })
  const navigate = useNavigate()
  const { setContentState } = useContext(GlobalContext)
  const [fetchUserWebsite, setFetchUserWebsite] = useState([])

  const [contactSection, setContactSection] = useState()
  const [contactSectionExist, setContactSectionExist]= useState(true)
  const [isLoading, setIsLoading] = useState(false)
  function handleClick() {
    navigate(-1)
  }
  const sectionDetail = async () => {
    try {
      setIsLoading(true)
    const response = await getSectionDetail(window.config.vendor_id, "contact")
    setContactSection(response.data.response)
    setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      }
     }

  
  useEffect(() => {
    sectionDetail()
    // if(!contactSection){
    //   setContactSectionExist(false)
    // }
  }, [])



  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          m: "atuo",
          //  flexDirection:"column"
        }}
      >
        <Loader />
      </Box>
    )
  const contactDetailImage =
    contactSection?.user_website_details?.contact_section?.Contact_details
      ?.image ||
    contactSection?.user_website_details?.contact_section?.Contact_details
      ?.photo_link
  const emailUsImage =
    contactSection?.user_website_details?.contact_section?.Contact_us?.image ||
    contactSection?.user_website_details?.contact_section?.Contact_us
      ?.photo_link


  return (
    <>

 <ThemeProvider theme={theme}>
      <Container
        sx={{
          "&.MuiContainer-root ": {
            p: 0,
            maxWidth: "1215px",
          },
        }}
      >
        {contactSection?.user_website_details?.contact_section?.our_history
          ?.paragraph && (
          <Box
            sx={{
              mt:{mobile:"24px", laptop:"48px"} ,
              width: { laptop: "596px" },
              mx: { mobile: "12px", laptop: "auto" },
            }}
          >
            <Typography
              sx={{
                fontSize: { mobile: "32px", laptop: "36px" },
                fontWeight: 700,
                textAlign: { tablet: "center" },
                lineHeight: "48px",
                fontFamily: "KaiseiDecol-Regular",
              }}
            >
              {contactSection?.user_website_details?.contact_section ? "Contact us" : ""}  
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: { tablet: "center" },
                mt: "16px",
                fontFamily: "mulish-regular",
                mx: { tablet: "74px", laptop: "0px" },
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    contactSection?.user_website_details?.contact_section
                      ?.our_history?.paragraph,
                }}
              />
            </Typography>
          </Box>
        )}
        {contactSection?.user_website_details?.contact_section
          ?.Contact_details && (
          <Box
            id='contact-us'
            sx={{
              display: "flex",
              mt:{mobile:"24px", tablet:"32px", laptop:"48px"} ,
              flexDirection: { mobile: "column", tablet: "row" },
              mx: { mobile: "12px", desktop: "0px" },
            }}
          >
            <Box
             sx={{
             width:"100%",
             maxWidth: {mobile:"100%" ,tablet:"59.45%"},
             height:"auto",
             }}>
              <Box
                sx={{
                  backgroundImage: `url("${contactDetailImage}")`,

                  width: "100%",
                  height: { mobile: "218px", tablet: "100%" },
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </Box>
            <Box
              sx={{
                bgcolor: "#F8F8F8",
                width: { tablet: "44%", laptop: "43%", desktop: "40.55%" },
                height: "auto",
              }}
            >
              <Box
                sx={{
                  mx: { mobile: "16px", laptop: "45px" },
                  py: { mobile: "24px", desktop: "76px" },
                  display:"flex",
                  flexDirection:"column",
                  gap:"16px",
                  height:"100%"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "28px",
                    letterSpacing: "1.5px",
                    color: " #333333",
                    fontFamily: "KaiseiDecol-Regular",
                  }}
                >
                  Contact details
                </Typography>
                <Box sx={{
                  height:"100%",
                  maxHeight:"100px",
                  overflowY:"auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#999999 #F5F5F5",
                  "&::-webkit-scrollbar": {
                    height: "4px",
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#999999",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#F5F5F5",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-corner": {
                    backgroundColor: "#F5F5F5",
                  },
                }}>

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "26px",
                    height:"100%",
                    letterSpacing: "1px",
                    color: "#333333",
                    fontFamily: "mulish-regular",
                  }}
                >
                   <div
                dangerouslySetInnerHTML={{
                  __html:
                  contactSection?.user_website_details?.contact_section
                  ?.Contact_details?.description
                }}
              />


                </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "26px",
                    letterSpacing: "1px",
                    color: "#333333",
                    fontFamily: "mulish-regular",
                  }}
                >
                  {
                    contactSection?.user_website_details?.contact_section
                      ?.Contact_details?.address
                  }
                </Typography>

                <Typography
                  sx={{
                    color: "#333333",
                    fontFamily: "mulish-regular",
                    fontSize: "18px",
                  }}
                >
                  Email:&nbsp;{" "}
                  {
                    contactSection?.user_website_details?.contact_section
                      ?.Contact_details?.email
                  }
                </Typography>
                <Typography
                  sx={{
                    color: "#333333",
                    fontFamily: "mulish-regular",
                    fontSize: "18px",
                  }}
                >
                  Phone # :&nbsp;{" "}
                  {
                    contactSection?.user_website_details?.contact_section
                      ?.Contact_details?.phone_number
                  }
                
                </Typography>

                <Box>

                <Typography
                  component='span'
                  sx={{
                    color: "#333333",
                    fontFamily: "mulish-regular",
                    fontSize: "18px",
                    textTransform: "uppercase",
                    lineHeight:"144.444%",
                  }}
                >
                  {contactSection?.user_website_details?.contact_section?.Contact_details?.available_days.map(
                    (day, index) => (
                      <span key={day}>
                        {index > 0 &&
                        index ===
                          contactSection?.user_website_details?.contact_section
                            ?.Contact_details?.available_days?.length -
                            1
                          ? " and "
                          : ""}
                        {day}
                        {index <
                        contactSection?.user_website_details?.contact_section
                          ?.Contact_details?.available_days?.length -
                          1
                          ? ", "
                          : ""}
                      </span>
                    )
                  )}
                </Typography>

                <Typography
                  sx={{
                    color: "#333333",
                    fontFamily: "mulish-regular",
                    fontSize: "18px",
                    lineHeight:"144.444%",
                  }}
                >
                  From&nbsp;
                  {
                    contactSection?.user_website_details?.contact_section
                      ?.Contact_details?.time_from
                  }
                  &nbsp;to&nbsp;
                  {
                    contactSection?.user_website_details?.contact_section
                      ?.Contact_details?.time_to
                  }
                </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
      {contactSection?.user_website_details?.contact_section?.map_links
        ?.visibilty === "on" && (
        <Box sx={{ mt: {mobile:"24px", tablet:"32px", laptop:"48px"}  }}>
          <iframe
            title='Google Map'
            width='100%'
            height='450'
            frameBorder='0'
            style={{ border: 0 }}
            src={
              contactSection?.user_website_details?.contact_section?.map_links
                ?.google_map_link
            }
            allowFullScreen=''
            loading='lazy'
          ></iframe>
        </Box>
      )}
      <Container
        sx={{
          "&.MuiContainer-root ": {
            p: 0,
            width:"100%",
           
          },
        }}
      >
        <EmailUs image={emailUsImage} />
      </Container>
    </ThemeProvider>

    
    </>
  )
}

export const EmailUs = ({ image }) => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    message: "",
  }

  const [formData, setFormData] = useState(initialFormData)
  const [contactFormErrorMsg, setContactFormErrorMsg] = useState("")
  const [contactFormSuccessMsg, setContactFormSuccessMsg] = useState("")
  const [captchaValue, setCaptchaValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  function onChange(value) {
    setCaptchaValue(value)
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const response = await ContactForm({
        first_name: formData.firstName,
        last_name: formData.lastName,
        company: formData.companyName,
        email: formData.email,
        message: formData.message,
        vendor_id: window.config.vendor_id,
        recaptcha: captchaValue,
      })
      setContactFormSuccessMsg(response.success)
      setContactFormErrorMsg(response.message)

      if (response.success === "email send successfully") {
        setFormData(initialFormData)
        setCaptchaValue("")
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const handleCloseErrorModal = () => {
    setContactFormErrorMsg("")
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width:"100%",
        maxWidth:"1261px",

        // border:"4px solid red",
        display: "flex",
        mt: {mobile:"24px", tablet:"32px", laptop:"48px"}  ,
        px:"12px",
        flexDirection: { mobile: "column-reverse", tablet: "row" },
        mx: { tablet: "auto" },
      }}
    >
      <Dialog
        sx={{ width: { desktop: "30%" }, m: "auto" }}
        open={!!contactFormErrorMsg}
        onClose={handleCloseErrorModal}
        aria-labelledby='error-dialog-title'
        aria-describedby='error-dialog-description'
      >
        <DialogTitle id='error-dialog-title'>Error</DialogTitle>
        <DialogContent>
          <DialogContentText id='error-dialog-description'>
            {contactFormErrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseErrorModal}
            sx={{
              bgcolor: "black",
              color: "white",
              fontSize: "16px",
              textTransform: "capitalize",
              width: "85px",
              mt: "24px",
              fontFamily: "mulish-regular",
              "&.MuiButton-root:hover": {
                color: " white",
                bgcolor: "#333333",
                borderColor: " #333333",
              },
              "&.MuiButtonBase-root.Mui-disabled ": {
                color: " white",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          // bgcolor: " #FFF7F8",
          width: {mobile:"100%" },
          maxWidth:{mobile:"768px", tablet:"520px", laptop:"661px"},

          px: {mobile:"24px", tablet:"24px", laptop: "45px" },
          py:{mobile:"16px", laptop:"40px"} ,
          mt: { mobile: "0px", laptop: "0rem" },
        }}
      > 
        <Box
        // sx={{
        //   px: { mobile: "32px", laptop: "45px" },
        //   py: { mobile: "40px", desktop: "112px" },
        // }}
        >
          <Box sx={{width:"100%"}}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "28px",
                letterSpacing: "1.5px",
                color: " #333333",
                fontFamily: "KaiseiDecol-Regular",
              }}
            >
              Contact us by email
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                mt: "24px",
              }}
            >
              <TextField
                fullWidth
                // sx={{ width: "277.5px" }}
                id='name'
                name='firstName'
                label='First name'
                variant='outlined'
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                // sx={{ width: "277.5px", ml: "16px" }}
                id='lastName'
                name='lastName'
                label='Last name'
                variant='outlined'
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </Box>
            <TextField
              fullWidth
              sx={{ mt: "24px" }}
              id='companyName'
              name='companyName'
              label='Company name'
              variant='outlined'
              value={formData.companyName}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              sx={{ mt: "24px" }}
              id='email'
              name='email'
              label='Email address'
              variant='outlined'
              value={formData.email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              sx={{ mt: "24px" }}
              id='message'
              name='message'
              label='Your message'
              variant='outlined'
              multiline
              // rows={4}
              value={formData.message}
              onChange={handleInputChange}
            />
            {contactFormSuccessMsg && (
              <Typography
                sx={{
                  mt: "1rem",
                  color: " #333333",
                  fontFamily: "mulish-regular",
                  textTransform: "capitalize",
                  color: "green",
                }}
              >
                {contactFormSuccessMsg}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { mobile: "end", laptop: "space-between" },
              alignItems: { mobile: "end", laptop: "center" },
              flexDirection: { mobile: "column", laptop: "row" },
              // gap: { laptop: "1rem" },
              // width: "100%",
            }}
          >
            <Box sx={{ mt: "1.7rem" }}>
              <ReCAPTCHA
                style={{
                  // minWidth: "100%",
                  "& .rc-anchor-normal": {
                    width: "400px",
                  },
                }}
                sitekey="6LcXp8MUAAAAAO6riIqsFt8isllkN0zTwnyYHokX"     
                onChange={onChange}
              />
            </Box>
            <Button
              disabled={isLoading}
              type='submit'
              sx={{
                bgcolor: "black",
                color: "white",
                fontSize: "16px",
                textTransform: "capitalize",
                px: "0.8rem",
                mt: "24px",
                fontFamily: "mulish-regular",
                "&.MuiButton-root:hover": {
                  color: " white",
                  bgcolor: "#333333",
                  borderColor: " #333333",
                },
                "&.MuiButtonBase-root.Mui-disabled ": {
                  color: " white",
                },
              }}
            >
              {isLoading ? "please wait..." : "submit"}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{
         width:"100%",
         maxWidth:{tablet:"480px", laptop:"600px" }
    }}>
        <Box
          sx={{
            display: { mobile: "none", tablet: "block" },
            backgroundImage: `url("${image}")`,
            width: { mobile: "100%", laptop: "100%" },
            height: { mobile: "218px", tablet: "100%" },
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Box>
    </Box>
  )
}
