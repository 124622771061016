import { Box, Button, Typography } from "@mui/material"
import React, { useContext, useMemo, useState } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { useNavigate } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

export const Cart = () => {
  const navigate = useNavigate()
  const { removeFromCart } = useContext(GlobalContext)
  const cart = JSON.parse(localStorage.getItem("cartItems"))

  const sumPrice = useMemo(() => {
    return cart?.reduce((total, item) => {
      if (item.total_selling_price === 0 || item.total_selling_price === "0") {
        return total + parseFloat(item.total_selling_price)
      } else {
        return total + parseFloat(item.total_selling_price.replace(/,/g, ""))
      }
    }, 0)
  }, [cart])
  const handleNavigate = () => {
    if (cart.length !== 0) {
      navigate("/checkout")
    }
  }

  return (
    <Box
      sx={{
        width: { lg: "50.1875rem" },
        mx: { xs: "12px", lg: "auto" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: "16px",
          mt: { xs: "24px", sm: "32px", md: "48px" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "32px", md: "36px" },
            fontWeight: 700,
            color: " #333333",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          My Cart
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            fontFamily: "KaiseiDecol-Regular",
            color: " #333333",
          }}
        >
          {cart.length === 1 || cart.length === 0
            ? cart.length + " item"
            : cart.length + " items"}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: "1px solid #E0E0E0", mt: "24px", mb: "32px" }} />
      {cart?.map((item) => {
        return (
          <Box
            key={item.id}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "4px",
              mt: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mx: "16px",
                my: "16px",
              }}
            >
              <Box sx={{ width: { md: "183px" } }}>
                <Box
                  component='img'
                  src={item.image}
                  alt={item.internal_reference_number}
                  sx={{
                    width: { xs: "125px", md: "183px" },
                    height: { xs: "125px", md: "136px" },
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: "178px", sm: "100%" }, ml: "16px" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "#333333",
                    mt: "6.5px",
                    fontFamily: "KaiseiDecol-Regular",
                  }}
                >
                  {item?.stone_types}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#858585",
                    my: "4px",
                    fontFamily: "mulish-regular",
                    // opacity:"0.7"
                  }}
                >
                  Ref#:&nbsp;
                  {item?.system_reference_number}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#333",
                    my: "4px",
                    fontFamily: "mulish-regular",
                  }}
                >
                  <Box component='span' sx={{ fontWeight: 400 }}>
                    Weight:&nbsp;
                  </Box>
                  {item?.weight + item?.weight_type}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: " #333",
                    my: "4px",
                    fontFamily: "mulish-regular",
                  }}
                >
                  <Box component='span' sx={{ fontWeight: 500 }}>
                    Origin:&nbsp;
                  </Box>

                  {item?.countries}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#333",
                    my: "4px",
                    fontFamily: "mulish-regular",
                  }}
                >
                  <Box component='span' sx={{ fontWeight: 400 }}>
                    Treatment:&nbsp;
                  </Box>

                  {item?.stone_type_treatments}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "4px",
                        width: "28px",
                        height: "28px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Box
                          onClick={() => {
                            removeFromCart(item.id)
                          }}
                          component='img'
                          src='/images/trash.png'
                          alt=''
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "#333333",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      fontFamily: "KaiseiDecol-Regular",
                    }}
                  >
                    {item.currency_logo + item.total_selling_price}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      })}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: "32px",
          mr: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#333333",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          TOTAL: &nbsp;
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#333333",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          {cart[0]?.currency_logo}
          {sumPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: "1px solid #E0E0E0", mt: "32px", mb: "24px" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: { xs: "24px", md: "32px" },
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => navigate("/products/1,4")}
            sx={{
              color: "black",
              fontSize: "16px",
              textTransform: "lowercase",
              fontWeight: "18px",
              fontWeight: 400,
              color: "#333333",
              fontFamily: "mulish-regular",
              "&.MuiButton-root:hover": {
                color: " #1A1A1A",
                bgcolor: "white",
              },
              "& .capitalize-first": {
                "&::first-letter": {
                  textTransform: "uppercase",
                },
              },
            }}
            startIcon={
              <ChevronLeftIcon
                sx={{
                  bgcolor: " #1A1A1A",
                  fontSize: "10px",
                  color: "white",
                  borderRadius: "4px",
                }}
              />
            }
          >
            <span style={{fontSize:"18px"}} className='capitalize-first'>Continue shopping</span>
          </Button>
        </Box>
        <Button
          size='small'
          onClick={handleNavigate}
          sx={{
            bgcolor: "black",
            color: "#FFF",
            fontSize: "18px",
            textTransform: "lowercase",
            height: "42px",
            width: { sm: "240px" },
            fontFamily: "mulish-regular",
            "&.MuiButton-root:hover": {
              color: " white",
              bgcolor: "#333333",
              borderColor: " #333333",
            },
            "& .capitalize-first": {
              "&::first-letter": {
                textTransform: "uppercase",
              },
            },
            mt: { xs: "16px", md: "0px" },
          }}
        >
          <span  className='capitalize-first'> proceed to checkout</span>
        </Button>
      </Box>
    </Box>
  )
}
