import * as React from "react"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { menuItems } from "../../../../../services/menuItems-service"
import YouTubeIcon from "@mui/icons-material/YouTube"
import { AuthService } from "../../../../../services/auth-service"
import { GlobalContext } from "../../../../../context/GlobalState"
const links = ["Login", "Request access"]
export const Footer = ({ footerSection }) => {
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const [menuHeaderItem, setMenuHeaderItem] = React.useState([])
  const { emptyCart } = React.useContext(GlobalContext)
  const getMenuItems = async () => {
    const response = await menuItems({
      user_id:
        localStorage.getItem("user_id") === null
          ? window.config.vendor_id
          : localStorage.getItem("user_id"),
      user_type:
        localStorage.getItem("user_type") === null
          ? "vendor"
          : localStorage.getItem("user_type"),
    })
    setMenuHeaderItem(response.data.data)
  }
  const handleLogout = () => {
    localStorage.clear()
    emptyCart()
  }
  React.useEffect(() => {
    getMenuItems()
  }, [])

  return (
    <Box
      sx={{
        bgcolor:"black",
        mt:{xs:"24px", sm:"48px"} ,
      }}
    >
      <Box
        sx={{
          bgcolor: "black",
          width:"100%",
          maxWidth:"1216px",
          mx:"auto",
          display: {xs:"flex", sm: "grid", lg:"flex" },
          flexDirection:{xs:"column", sm:"row"},
          gridTemplateColumns:  {sm:"repeat(2, 1fr)"},
          flexWrap:{md:"wrap", lg:"nowrap"},
          alignItems: { sm: "start" },
          gap:{xs:"32px",  lg:"90px"} ,
          justifyContent: "space-between",
          pt: { sm: "32px", md: "3.5rem" },
          pb: { md: "3.5rem" },
          // px: { xs: "40px", md: "0px" },
          px: {xs:"40px", lg:"0"},
          py:{xs:"56px"}
        }}
      >
        <Box
          sx={{
            // width:"100%",
            maxWidth: { md:"432px", lg: "517px" },
            // minWidth: { md: "20rem" },
            // ml: { md: "7rem" },
            // pt: { xs: "50px", sm: "0px" },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
              fontWeight: 700,
              fontFamily: "KaiseiDecol-Regular",
              lineHeight:"132%",
            }}
          >
            About us
          </Typography>
          <Typography
            sx={{
              color: "white",
              mt: "8px",
              fontFamily: "mulish-regular",
              width: { sm: "432px", md: "100%" },
            }}
          >
             {footerSection?.footer_contact_details?.about_us}
          </Typography>
        </Box>
        {menuHeaderItem.length !== 0 && (
          <Box
            sx={{
              // width: { xs: "210px", md: "142px" },
              // mt: { xs: "32px", sm: "0px" },
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "20px",
                fontWeight: 700,
                fontFamily: "KaiseiDecol-Regular",
              }}
            >
              Categories
            </Typography>
            {menuHeaderItem?.map((item, i) => (
              <Typography
                onClick={() =>
                  navigate(`products/${item.parcel_type_ids?.toString()}`)
                }
                key={i}
                sx={{
                  color: "white",
                  mt: "8px",
                  fontFamily: "mulish-regular",
                  cursor: "pointer",
                }}
              >
                {item.title}
              </Typography>
            ))}
          </Box>
        )}

        <Box
          sx={{
            // width: "145px",
            // mt: { xs: "32px", sm: "0px" },
            // pb: { xs: "25px", sm: "0px" },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
              fontWeight: 700,
              fontFamily: "KaiseiDecol-Regular",
            }}
          >
            Link
          </Typography>
          {localStorage.hasOwnProperty("token") ? (
            <Typography
              onClick={handleLogout}
              sx={{
                color: "white",
                mt: "8px",
                fontFamily: "mulish-regular",
                cursor: "pointer",
              }}
            >
              Logout
            </Typography>
          ) : (
            <Typography
              onClick={() => navigate("/login")}
              sx={{
                color: "white",
                mt: "8px",
                fontFamily: "mulish-regular",
                cursor: "pointer",
              }}
            >
              Login
            </Typography>
          )}
          {!localStorage.hasOwnProperty("token") && (
            <Typography
              onClick={() => navigate("/signup")}
              sx={{
                color: "white",
                mt: "8px",
                fontFamily: "mulish-regular",
                cursor: "pointer",
              }}
            >
              Request access
            </Typography>
          )}

          {/* {links.map((link, index) => {
            return (
              <Typography
                key={index}
                onClick={() => handleNavigateToLink(link)}
                sx={{
                  color: "white",
                  mt: "8px",
                  fontFamily: "mulish-regular",
                  cursor: "pointer",
                }}
              >
                {link}
              </Typography>
            )
          })} */}
        </Box>
        <Box>
          {footerSection?.footer_contact_details?.is_enabled ? (
            <>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: 700,
                  fontFamily: "KaiseiDecol-Regular",
                }}
              >
                Contact:
              </Typography>
              <Typography
                sx={{ color: "white", mt: "8px", fontFamily: "mulish-regular" }}
              >
                Phone:&nbsp;
                {footerSection?.footer_contact_details?.phone_number}
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  mt: "8px",
                  fontFamily: "mulish-regular",
                  textOverflow: "clip",
                }}
              >
                {footerSection?.footer_contact_details?.email}
              </Typography>
            </>
          ) : null}
          {footerSection?.header_section_social_links?.is_enabled ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: "16px",
                gap: "1.5rem",
              }}
            >
              <a
                href={footerSection?.header_section_social_links?.instagram}
                target='_blank'
              >
                <Box
                  component='img'
                  src='/instagramWhite.svg'
                  sx={{ width: "24px" }}
                />
              </a>
              <a
                href={footerSection?.header_section_social_links?.facebook}
                target='_blank'
              >
                <Box
                  component='img'
                  src='/facebookWhite.svg'
                  sx={{ width: "24px" }}
                />
              </a>
              <a
                href={footerSection?.header_section_social_links?.twitter}
                target='_blank'
              >
                <Box
                  component='img'
                  src='/twitterWhite.svg'
                  sx={{ width: "24px", color: "white" }}
                />
              </a>
              <a
                href={footerSection?.header_section_social_links?.youtube}
                target='_blank'
              >
                <YouTubeIcon
                  sx={{ color: "white", width: "28px", height: "30px" }}
                />
              </a>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{ bgcolor: "black", borderTop:"1px solid white"  ,mt: "0.5px", textAlign: "center", py: "8px" }}
      >
        <Typography
          component='a'
          href='https://www.thegemcloud.com/'
          target='_blank'
          sx={{
            fontWeight: 500,
            color: "white",
            textDecoration: "none",
            fontFamily: "mulish-regular",
          }}
        >
          Powered by GemCloud
        </Typography>
      </Box>
    </Box>
  )
}
