import Box from "@mui/material/Box"
import api from "../../services/API"
import Typography from "@mui/material/Typography"
import {
  Button,
  Card,
  Skeleton,
  ThemeProvider,
  createTheme,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import {useState, useContext, useEffect } from "react"
import { Container } from "@mui/system"
import { fetchUserWebisteSetting } from "../../../src/services/fetch-user-website-settings-service"
import { Loader } from "../common/Loader/Loader"
import Carousel from "react-material-ui-carousel"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
// import "./styles.css"
import "./swiperModification.css"
import { GlobalContext } from "../../context/GlobalState"
import { getSectionDetail } from "../../services/getSectionDetail"
import { FlashAuto } from "@mui/icons-material"
export const HomePage = () => {
  const navigate = useNavigate()
  // const [hasLoaded, setHasLoaded] = useState(true)
  // const [fetchUserWebsite, setFetchUserWebsite] = useState([])
  // const [discoverOurProductsSection, setDiscoverOurProductsSection] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const [homeSection, setHomeSection] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [ourNewItems, setOurNewItems] = useState([])
  const [homeSectionExist, setHomeSectionExist]= useState(true)
  const [isLoadingNewSection, setIsLoadingNewSection] = useState(false)
  const { setContentState } = useContext(GlobalContext)
  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
      },
    },
  })
  const enabledItems = homeSection?.discover_our_products_section?.filter(
    (item) => item.enabled
  )
  const homeSectionDetail = async () => {
    try {
      setIsLoading(true)
      const response = await getSectionDetail(
        window.config.vendor_id,
        "homepage_top "
      )
      setHomeSection(
        response?.data?.response?.user_website_details?.homepage_top_section
      )
      if(response?.data?.response?.user_website_details?.homepage_top_section){
        setHomeSectionExist(true)
      }else{
        setHomeSectionExist(false)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
   
  }

  
  // const contactSectionDetail = async () => {
  //   try {
  //     setIsLoading(true)
  //     const response = await getSectionDetail(
  //       window.config.vendor_id,
  //       "contact "
  //     )
  //     if(response){
  //       setContentState(true)
  //     }else{
  //      setContentState(false)
  //     }
  //     setIsLoading(false)
  //   } catch (error) {
  //     setIsLoading(false)
  //     setContentState(false)
  //   }
   
  // }
  // const aboutSectionDetail = async () => {
  //   try {
  //     setIsLoading(true)
  //     const response = await getSectionDetail(
  //       window.config.vendor_id,
  //       "about "
  //     )
  //  if(response){
  //    setContentState(true)
  //  }else{
  //   setContentState(false)
  //  }
  //     setIsLoading(false)
  //   } catch (error) {
  //     setIsLoading(false)
  //     setContentState(false)
  //   }
   
  // }
  useEffect(() => {
    homeSectionDetail()
    // contactSectionDetail()
    // aboutSectionDetail()
  }, [])

  return (
    <>
        {!homeSectionExist &&  <Box sx={{
      width:"100%",
      height: "400px", 
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      mt:"40px"
    }}>
{/* <Typography  
  sx={{
    fontSize: { mobile: "32px", laptop: "36px" },
    fontWeight: 700,
    textAlign: { tablet: "center" },
    lineHeight: "48px",
    fontFamily: "KaiseiDecol-Regular",
       }}>
         Coming soon
        </Typography> */}

        <Box
         component="img" 
         src="https://static.vecteezy.com/system/resources/previews/017/197/459/original/coming-soon-icon-symbol-on-transparent-background-free-png.png"
         height="100%"
         width= "100%"
         />
          
      
</Box>}
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          "&.MuiContainer-root ": {
            p: 0,
            maxWidth: "1216px",
          },
        }}
      >
        <HeroSection
          title={homeSection?.title}
          homeSectionExist={homeSectionExist}
          description={homeSection?.description}
          image={homeSection?.image}
          isLoading={isLoading}
        />

        {homeSection?.discover_our_products_section && (
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { mobile: "24px", laptop: "28px" },
              textAlign: "center",
              lineHeight:"128.571%",
              color: "#333333",
              mt: { mobile: "24px", laptop: "48px" },
              fontFamily: "KaiseiDecol-Regular",
            }}
          >
               Discover our products
          </Typography>
        )}

        <Box
          sx={{
            display: { mobile: "none", tablet: "flex" },
            justifyContent: "center",
            // alignItems:"center",
            px: { mobile: "12px", desktop: "0px" },
          }}
        >
          <StoneCard hasLoaded={isLoading} stoneImage={enabledItems} />
        </Box>
        <Box
          sx={{
            display: { mobile: "flex", tablet: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Swiper
            slidesPerView={1.2}
            spaceBetween={12}
            className='mySwiper'
            onSlideChange={(slideChange) =>
              setActiveStep(slideChange.activeIndex)
            }
            // style={{width:"100%"}}
          >
            {enabledItems?.map((item, index) => {
              const parcelid = item.parcel_type.join(",")

              return (
                <SwiperSlide key={item.id}>
                  <Card
                    sx={{
                      mt: { mobile: "1rem", laptop: "24px" },
                      // ml: { mobile: index !== 0 ? "2rem" : 0 },
                      mx: "12px",
                      cursor: "pointer",
                      boxShadow:
                        "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75)",
                      transition: "box-shadow 0.3s",
                      "&:hover": {
                        boxShadow:
                          "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 15px -9px rgba(51, 51, 51, 0.9)",
                      },
                      // width:"60%"
                    }}
                    onClick={() => navigate(`products/${parcelid}`)}
                  >
                    {isLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          bgcolor: "#F0F0F0",
                        }}
                      >
                        <Loader />
                      </Box>
                    ) : (
                      <>
                        <Box
                          component='img'
                          src={item.url}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: {
                              mobile: "320px",
                            },
                          }}
                        />
                        {/* <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: {
                              mobile: "100%",
                              // tablet: "175px",
                              // laptop: "225px",
                              // desktop: "286px",
                            },
                            height: {
                              mobile: "320px",
                              // tablet: "216px",
                              // laptop: "246px",
                              // desktop: "375px",
                            },
                            backgroundImage: `url("${item.url}")`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        /> */}
                      </>
                    )}

                    <Typography
                      sx={{
                        textAlign: "center",
                        // my: { mobile: "12px", laptop: "24px" },
                        my:"24px",
                        fontWeight: 400,
                        fontSize: { mobile: "16px", laptop: "18px" },
                        fontFamily: "mulish-regular",
                        width: "100%",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Card>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { mobile: "24px", laptop: "28px" },
              textAlign: "center",
              color: "#333333",
              mt: { mobile: "24px", laptop: "48px" },
              fontFamily: "KaiseiDecol-Regular",
              lineHeight:"128.571%"
            }}
          >
            {homeSection?.our_new_item_section?.enabled && "Our New Items" }  
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexWrap: "wrap",
              pt: "24px",
              px: { mobile: "12px", tablet: "0px" },
              gap: { mobile: "16px", tablet: "25px" },
            }}
          >
            {homeSection?.our_new_item_section?.enabled &&
              homeSection?.our_new_item_section?.product_records?.map(
                (item, ind) => {
                  return (
                    <Box
                      key={ind}
                      sx={{
                        width: "100%",
                        maxWidth: {
                          mobile: ind === 0 ? "100%" : "167px",
                          tablet: ind === 0 ? "658px" : "317px",
                          laptop: ind === 0 ? "658px" : "317px",
                          desktop: ind === 0 ? "803px" : "388px",
                        },
                        height: {
                          mobile: "167px",
                          tablet: "317px",
                          laptop: "375px",
                        },
                      }}
                    >
                      {Object.values(item).map((ele, index) => (
                        <Box
                          key={index}
                          component='img'
                          src={ele?.stone_image}
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ))}
                    </Box>
                  )
                }
              )}
          </Box>
        </Box>

        {homeSection?.contact_us_section?.enabled && (
          <Contactus
            title={homeSection?.contact_us_section?.title}
            description={homeSection?.contact_us_section?.description}
            image={homeSection?.contact_us_section?.image}
          />
        )}

        {homeSection?.signupSection?.is_enabled && (
          <Box
            sx={{
              textAlign: "center",
              mt:{mobile: "24px", laptop:"48px"} ,
              px: {mobile:"16px", tablet:"0px "},
              py: "32px",
              bgcolor:
                homeSection?.signupSection?.theme_mode === "dark"
                  ? "black"
                  : homeSection?.signupSection?.theme_mode === "season"
                  ? "#FFF5E6 "
                  : "white",
            }}
          >
            <Typography
              sx={{
                // pt: "32px",
                fontSize: { mobile: "28px", mobile: "32px", desktop: "44px" },
                fontWeight: 700,
                letterSpacing: "1px",
                lineHeight:"118.182%",
                color:
                  homeSection?.signupSection?.theme_mode === "dark"
                    ? "white "
                    : homeSection?.signupSection?.theme_mode === "season"
                    ? "#7B4A00 "
                    : "#333333",

                fontFamily: "KaiseiDecol-Regular",
              }}
            >
               {homeSection?.signupSection?.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { mobile: "16px", desktop: "18px" },
                fontWeight: 400,
                letterSpacing: "1px",
                lineHeight:"144.444%",
                color:
                  homeSection?.signupSection?.theme_mode === "dark"
                    ? "white "
                    : homeSection?.signupSection?.theme_mode === "season"
                    ? "#7B4A00 "
                    : "#333333",
                maxWidth: { mobile: "364px" },
                mt: "16px",
                mx: "auto",
                fontFamily: "mulish-regular",
              }}
            >
              {homeSection?.signupSection?.description}
            </Typography>

            {!localStorage.hasOwnProperty("token") && (
              <Button
                onClick={() => navigate("/signup")}
                sx={{
                  bgcolor:
                    homeSection?.signupSection?.theme_mode === "dark"
                      ? "white"
                      : homeSection?.signupSection?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "black ",

                  color:
                    homeSection?.signupSection?.theme_mode === "dark"
                      ? "#333333 "
                      : homeSection?.signupSection?.theme_mode === "season"
                      ? "#E1D5C3 "
                      : "white",

                  fontSize: { mobile: "14px", laptop: "16px" },
                  textTransform: "lowercase",
                  paddingX:"16px",
                  paddingY:"8px",
                  lineHeight:"150%",
                  // width: { mobile: "112px", laptop: "130px" },
                  // height: "20px",
                  mt: "16px",
                  fontFamily: "mulish-regular",
                  "&.MuiButton-root:hover": {
                    color: " white",
                    bgcolor: "#333333",
                    borderColor: " #333333",
                  },
                  "& .capitalize-first": {
                    "&::first-letter": {
                      textTransform: "uppercase",
                    },
                  },
                }}
              >
                <span className='capitalize-first'> join the club</span>
              </Button>
            )}
          </Box>
        )}
      </Container>
    </ThemeProvider>
    </>
  )
}
export const HeroSection = ({homeSectionExist, title, description, image, isLoading }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: "flex",
        // mt:  "48px",
        marginTop:{mobile:"24px", laptop:"48px"},
        flexDirection: { mobile: "column-reverse", tablet: "row" },
        mx: { mobile: "12px", desktop: "0px" },
      }}
    >
      <Box
        sx={{
          bgcolor: " #FFF7F8",
          width: { tablet: "44%", laptop: "43%", desktop: "40.5%" },
        }}
      >
        <Box
          sx={{
            px: { mobile: "32px", laptop: "45px" },
            py: { mobile: "40px", desktop: "112px" },
          }}
        >
          {isLoading ? (
            <Box>
              <Skeleton
                variant='text'
                sx={{
                  fontSize: "44px",
                  fontWeight: 700,

                  height: { laptop: "60px" },
                }}
              />
            </Box>
          ) : (
            <Typography
              sx={{
                fontSize: { mobile: "32px", desktop: "44px" },
                fontWeight: 700,
                lineHeight: { mobile: "40px", desktop: "52px" },
                letterSpacing: { laptop: "1px" },
                color: " #333333",
                fontFamily: "KaiseiDecol-Regular",
                // width: "241px",
              }}
            >
              {title}
            </Typography>
          )}
          {isLoading && homeSectionExist &&  (
            <Skeleton
              variant='text'
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "26px",
                height: { laptop: "130px" },
              }}
            />
            )}
             { homeSectionExist && <Typography
              sx={{
                fontSize: { mobile: "16px", desktop: "18px" },
                fontWeight: 400,
                lineHeight: { laptop: "26px" },
                letterSpacing: "1px",
                color: "#333333",
                mt: "16px",
                fontFamily: "mulish-regular",
              }}
            >
              {description}
            </Typography> } 
          

          <Button
            size='small'
            sx={{
              bgcolor: "black",
              color: "white",
              fontSize: { mobile: "14px", laptop: "16px" },
              textTransform: "lowercase",
              // width: { mobile: "117px", laptop: "130px" },
              py:"8px",
              px:"16px",
              lineHeight:"142.857%",
              mt: "16px",
              "&.MuiButton-root:hover": {
                color: " white",
                bgcolor: "#333333",
                borderColor: " #333333",
              },
              fontFamily: "mulish-regular",
              "& .capitalize-first": {
                "&::first-letter": {
                  textTransform: "uppercase",
                },
              },
            }}
            onClick={() => navigate(`/products/1,4`)}
          >
           {homeSectionExist && <span className='capitalize-first'>See products</span>}  
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {isLoading ? (
          <Box sx={{ width: "100%", bgcolor: "#F0F0F0", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                m: "atuo",
              }}
            >
              <Loader />
            </Box>
          </Box>
        ) : (
          <>
            {/* <Box
              component='img'
              src={fetchUserWebsite.homepage_top_section?.image}
              sx={{
                width: { mobile: "100%", laptop: "100%" },
                height: { mobile: "218px", desktop: "480px" },
              }}
            /> */}
            <Box
              sx={{
                backgroundImage: `url("${image}")`,
                width: { mobile: "100%", laptop: "100%" },
                height: { mobile: "218px", tablet: "100%" },
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
export const                        Contactus = ({ title, description, image }) => {
  const navigate = useNavigate()

  return (
    <Box
      id='contact-us'
      sx={{
        display: "flex",
        mt: {mobile:"24px", laptop:"48px"} ,
        flexDirection: { mobile: "column-reverse", tablet: "row" },
        mx: { mobile: "12px", desktop: "0px" },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundImage: `url("${image}")`,

            width: { mobile: "100%", laptop: "100%" },
            height: { mobile: "218px", tablet: "100%" },
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Box>
      <Box
        sx={{
          bgcolor: " #FFF7F8",
          width: { tablet: "44%", laptop: "43%", desktop: "40.5%" },
        }}
      >
        <Box
          sx={{
            px: { mobile: "32px",tablet:"45px" },
            py: { mobile: "40px", tablet:"62.5px", desktop: "112px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { mobile: "28px", desktop: "44px" },
              fontWeight: 700,
              lineHeight: { mobile: "36px", desktop: "52px" },
              letterSpacing: { laptop: "1px" },
              color: " #333333",
              fontFamily: "KaiseiDecol-Regular",
              // width: "241px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: { mobile: "16px", desktop: "18px" },
              fontWeight: 400,
              lineHeight: { laptop: "26px" },
              letterSpacing: "1px",
              color: "#333333",
              mt: "16px",
              fontFamily: "mulish-regular",
            }}
          >
            {description}
          </Typography>
          <Button
            size='small'
            sx={{
              bgcolor: "black",
              color: "white",
              fontSize: { mobile: "14px", laptop: "16px" },
              textTransform: "lowercase",
              // width: { mobile: "117px", laptop: "130px" },
              py:"8px",
              px:"16px",
              mt: "16px",
              lineHeight:"24px",
              "&.MuiButton-root:hover": {
                color: " white",
                bgcolor: "#333333",
                borderColor: " #333333",
              },
              fontFamily: "mulish-regular",
              "& .capitalize-first": {
                "&::first-letter": {
                  textTransform: "uppercase",
                },
              },
            }}
            onClick={() => navigate(`/contact-us`)}
          >
            <span className='capitalize-first'> contact us</span>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export const StoneCard = ({ stoneImage, hasLoaded }) => {
  const navigate = useNavigate()

  return (
    <>
      {stoneImage?.map((item, index) => {
        const parcelid = item.parcel_type.join(",")

        return (
          <Card
            key={item.id}
            sx={{
              mt: "24px",
              ml: { mobile: index !== 0 ? "2rem" : 0 },
              cursor: "pointer",
              boxShadow:
                "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75)",
              transition: "box-shadow 0.3s",
              "&:hover": {
                boxShadow:
                  "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 15px -9px rgba(51, 51, 51, 0.9)",
              },
            }}
            onClick={() => navigate(`products/${parcelid}`)}
          >
            {hasLoaded ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  bgcolor: "#F0F0F0",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: {
                    mobile: "290px",
                    tablet: "175px",
                    laptop: "225px",
                    desktop: "286px",
                  },
                  height: {
                    mobile: "320px",
                    tablet: "216px",
                    laptop: "246px",
                    desktop: "375px",
                  },
                }}
              >
                <Box
                  component='img'
                  src={item?.url}
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
              // <Box
              //   sx={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     width: {
              //       mobile: "290px",
              //       tablet: "175px",
              //       laptop: "225px",
              //       desktop: "286px",
              //     },
              //     height: {
              //       mobile: "320px",
              //       tablet: "216px",
              //       laptop: "246px",
              //       desktop: "375px",
              //     },
              //     backgroundImage: `url("${item.url}")`,
              //     backgroundRepeat: "no-repeat",
              //     backgroundPosition:"center",
              //     backgroundSize: "cover",
              //   }}
              // />
            )}

            <Typography
              sx={{
                textAlign: "center",
                my: "24px",
                fontWeight: 400,
                fontSize: { mobile: "16px", laptop: "18px" },
                fontFamily: "mulish-regular",
                lineHeight:"144.444%"
              }}
            >
              {item.name}
            </Typography>
          </Card>
        )
      })}
    </>
  )
}
