import axios from "axios"
import toast from "react-hot-toast"
import React, { useState, useEffect } from "react"
// import Loader from "../../Layout/common/Loader"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../common/Loader/Loader"
import { Box } from "@mui/material"

export default function AdminLogin(props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [template_status, setTemplate_status] = useState(0)
  const [vendor_id, setVendor_id] = useState(
    window ? window.config.vendor_id : ""
  )
  const navigate = useNavigate()
  const DirectLogin = async (props) => {
    setLoading(true)
    const url = window.location.pathname
    var id = url.substring(url.lastIndexOf("/") + 1)
    await axios
      .post(
        `${window.config.api_url}/login-api-vendor`,
        {},
        {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.is_website_completed === 1) {
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("user_id", res.data.user.id)
            localStorage.setItem("user_type", res.data.user_type)

            // localStorage.setItem('filters', JSON.stringify({}))
            // localStorage.setItem('refValues', JSON.stringify({}))

            localStorage.setItem("hero_bg", res.data.hero_bg)
            localStorage.setItem("about_bg", res.data.about_bg)
            localStorage.setItem(
              "selection_stone_1_img",
              res.data.selection_stone_1_img
            )
            localStorage.setItem(
              "selection_stone_2_img",
              res.data.selection_stone_2_img
            )
            localStorage.setItem(
              "selection_stone_3_img",
              res.data.selection_stone_3_img
            )
            if (!localStorage["cartItems"]) {
              localStorage.setItem("cartItems", JSON.stringify([]))
            }
            // localStorage.setItem("dataCart", JSON.stringify(arr))

            setTemplate_status(1)
            window.location = "/"
          } else {
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("user_id", res.data.user.id)
            localStorage.setItem("filters", JSON.stringify({}))
            localStorage.setItem("user_type", res.data.user_type)
            // localStorage.setItem("refValues", JSON.stringify({}))
            localStorage.setItem(
              "template_status",
              res.data.is_website_completed
            )

            navigate("/products/1,4")
          }

          props.handleLogin(res.data)
        } else {
          if (res.response.err.message) {
            toast.error("Invalid Credentials")
            setLoading(false)
          }
        }
      })
  }
  useEffect(() => {
    DirectLogin(props)
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        m: "atuo",
        //  flexDirection:"column"
      }}
    >
      {loading && <Loader />}
    </Box>
  )
}
