import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { Header } from "./Header/Header"
import { Footer } from "./Footer/Footer"
import { useNavigate } from "react-router-dom"
import { fetchUserWebisteSetting } from "../../../../services/fetch-user-website-settings-service"
import { Helmet } from "react-helmet"
import { getSectionDetail } from "../../../../services/getSectionDetail"
export const Layout = ({ children }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [headerSection, setHeaderSection] = useState(null)
  const [footerSection, setFooterSection] = useState(null)
  const headerSectionDetail = async () => {
    setIsLoading(true)
    const response = await getSectionDetail(window.config.vendor_id, "header")
    setHeaderSection(response.data.response.user_website_details.header_section)
    setIsLoading(false)
  }
  const footerSectionDetail = async () => {
    setIsLoading(true)
    const response = await getSectionDetail(window.config.vendor_id, "footer")
    setFooterSection(response.data.response.user_website_details.footer_section)
    setIsLoading(false)
  }
  useEffect(() => {
    headerSectionDetail()
    footerSectionDetail()
  }, [])

  return (
    <>
      {/* <Helmet>
        <title>Gemstone E-Commerce Website | {companyName}</title>
        <meta
          name='description'
          content={fetchUserWebsite.homepage_top_section?.description}
        />
      </Helmet> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            mt: { md: "40px" },
          }}
        >
          <Header headerSection={headerSection} />
          <Box>{children}</Box>
        </Box>
        <Footer footerSection={footerSection} />
      </Box>
    </>
  )
}
