import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react"
import { Typography, Box, Button, Card } from "@mui/material"
import toast from "react-hot-toast"
import { ProductService } from "../../services/products-service"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalState"
import { Container } from "@mui/system"
import { Loader } from "../common/Loader/Loader"
import { CartService } from "../../services/cart-service"
import { ProductImages } from "./Components/ProductImages/ProductImages"
import { ProductDetailHeadline } from "./Components/ProductDetailHeadline/ProductDetailHeadline"
import { ProductDetailButtons } from "./Components/ProductDetailButtons/ProductDetailButtons"
import { ProductDetailCard } from "./Components/ProductDetailCard/ProductDetailCard"
import { RenderStoneDetails } from "./Components/RenderStoneDetails/RenderStoneDetails"
import { Contactus } from "./Components/Contactus/Contactus"
import { Helmet } from "react-helmet"
import { getSectionDetail } from "../../services/getSectionDetail"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "./productSwiperModification.css"
export const ProductDetail = () => {
  const {removeFromWishlist } =
  useContext(GlobalContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [item, setItem] = useState(null)
  const [sliderItems, setSliderItems] = useState([])
  const [toggleImageValue, setToggleImageValue] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const { addToCart, emptyCart, addToWishList } = useContext(GlobalContext)
  const [vendor_id, setVendor_id] = useState(window.config.vendor_id)
  const [open, setOpen] = useState(false)
  const [isAddedToCart, setIsAddedToCart] = useState(false)
  const [shopDifferentVariety, setShopDifferentVariety] = useState(null)
  const billingAddress = useMemo(
    () => JSON.parse(localStorage.getItem("billingAddress")),
    []
  )
  const shippingAddress = useMemo(
    () => JSON.parse(localStorage.getItem("shippingAddress")),
    []
  )

  const customerDetails = useMemo(
    () => JSON.parse(localStorage.getItem("customer")) || [],
    []
  )
  // const cart = useMemo(() => JSON.parse(localStorage.getItem("cartItems")), [])
  const cart = JSON.parse(localStorage.getItem("cartItems"))
  const token = useMemo(() => localStorage.getItem("token"), [])

  const addToWishListHandler = useCallback(() => {
    if (token === null) {
      toast.error("Please sign in to add the item to your wishlist.")
    } else {
      const inCart = cart?.some((cartItem) => cartItem.id === item.id)
      if (!inCart) {
        addToWishList(item)
      } else {
        toast.error("Item already in the cart")
      }
    }
    const wishList = JSON.parse(localStorage.getItem("wishlistItems"))
    const inWishlist = wishList.some((ele)=>ele.id === item.id)
    if(inWishlist){
      removeFromWishlist(item.id)
      }
  }, [addToWishList, cart, item, token])

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const addToCartHandler = useCallback(() => {
    if (token === null) {
      navigate("/login")
    } else {
      addToCart(item)
      const wishlist = JSON.parse(localStorage.getItem("wishlistItems")) || [];

      const inWishList = wishlist?.some((ele) => ele.id === item.id);


      if (inWishList) {
        const updateLocalStorage = wishlist?.filter((ele) => ele.id !== item.id);
        localStorage.setItem(
          "wishlistItems",
          JSON.stringify(updateLocalStorage)
        )
      }
    }
  }, [addToCart, item, navigate, token])

  const saveStoneList = useCallback(async () => {
    if (token === null) {
      navigate("/login")
    } else {
      const isVendor = localStorage.getItem("user_type") === "vendor"
      const data = {
        stone_ids: [item.id],
        user_id: vendor_id,
        customer_billing_id: billingAddress?.id,
        customer_id: isVendor ? null : localStorage.getItem("user_id"),
        customer_shipping_id: shippingAddress?.id,
        user_type:
          localStorage.getItem("user_type") === null
            ? "vendor"
            : localStorage.getItem("user_type"),
        payment_format: item?.currency_format,
      }

      try {
        const res = await CartService.completedCart(data)
        if (res.data.success) {
          localStorage.setItem("invoice_id", res.data.id)
          localStorage.setItem("cartItems", JSON.stringify([]))
          emptyCart()
          navigate("/thankyou-quote")
        } else {
          toast.error("Something went wrong. Please try again later!")
        }
      } catch (error) {
        console.error(error)
        toast.error("Something went wrong. Please try again later!")
      }
    }
  }, [
    billingAddress?.id,
    emptyCart,
    item?.currency_format,
    item?.id,
    navigate,
    shippingAddress?.id,
    token,
    vendor_id,
  ])

  const getProduct = useCallback(async () => {
    const user_id = window.config.vendor_id
    const user_type = "vendor"
    const reqData = {
      stone_detail_id: id,
      user_id,
      user_type,
    }
    try {
      const res = await ProductService.productPages(reqData)
      const sliderImages =
        res.data?.data.images?.map((image) => ({
          type: "IMAGE",
          url: image,
        })) || []
      const sliderVideos =
        res.data?.data.video_link?.map((video) => ({
          type: "VIDEO",
          url: video.link,
        })) || []
      const sliderVendor360 =
        res.data?.data.vendor_360?.map((threeD) => ({
          type: "vendor360",
          url: threeD,
        })) || []
      const sliderLab360 =
        res.data?.data.lab_360?.map((lab360) => ({
          type: "lab360",
          url: lab360,
        })) || []
      setSliderItems([
        ...sliderImages,
        ...sliderVideos,
        ...sliderVendor360,
        ...sliderLab360,
      ])
      setItem(res.data?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [id, token])

  useEffect(() => {
    getProduct()
  }, [getProduct])

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem(
        "billingAddress",
        JSON.stringify(
          customerDetails?.shipping_addresses?.find(
            (singleAddress) => singleAddress.default
          ) ?? null
        )
      )
    }, 1000)

    setTimeout(() => {
      localStorage.setItem(
        "shippingAddress",
        JSON.stringify(
          customerDetails?.shipping_addresses?.find(
            (singleAddress) => singleAddress.default
          ) ?? null
        )
      )
    }, 1000)
  }, [customerDetails])
  const sectionDetail = async () => {
    const response = await getSectionDetail(window.config.vendor_id, "products")
    setShopDifferentVariety(response.data.response)
  }
  useEffect(() => {
    sectionDetail()
  }, [])

  return (
    <>
      <Container
        sx={{
          "&.MuiContainer-root ": {
            p: 0,
            maxWidth: "1215px",
          },
        }}
      >
        <Box 
        onClick={()=> window.history.back()}
        sx={{
          display:"flex",
          justifyContent:"start",
          lignItems:"center",
          gap:"8px",
          mt:"48px",
          mb:"16px",
          ml:"12px",
          cursor:"pointer"
                 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M15 18L9 12L15 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<Typography sx={{fontFamily:"mulish-regular", fontSize:"16px", fontWeight:500, lineHeight:"162.5%"}}>
Back to Listing
</Typography>
        </Box>
       
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              m: "atuo",
              //  flexDirection:"column"
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box sx={{ mx: { md: "12px", md: "0px" } }}>
            <Box
              sx={{
                display: { sm: "flex" },
                justifyContent: { sm: "space-between" },
                alignItems: "start",
                mx: { xs: "12px", md: "12px" },
              }}
            >
              <ProductImages
                sliderItems={sliderItems}
                setToggleImageValue={setToggleImageValue}
                toggleImageValue={toggleImageValue}
                isLoading={isLoading}
              />

              <Box
                sx={{
                  width: { sm: "400px", md: "489px", lg: "560px" },
                  ml: { sm: "24px", md: "0px" },
                }}
              >
                <ProductDetailHeadline
                  item={item}
                  token={token}
                  sliderItems={sliderItems}
                  setToggleImageValue={setToggleImageValue}
                  toggleImageValue={toggleImageValue}
                  isLoading={isLoading}
                />
                <ProductDetailButtons
                  addToCartHandler={addToCartHandler}
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  item={item}
                  saveStoneList={saveStoneList}
                  addToWishListHandler={addToWishListHandler}
                />
                <Box
                  sx={{
                    // width: { xl: "100%" },
                    mt: "24px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: " #F8F8F8 ",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        marginY: "1rem",
                        ml: "1.4rem",
                        fontWeight: 600,
                        fontSize: "20px" ,
                        color: "#595959",
                        fontFamily: "KaiseiDecol-Regular",
                      }}
                    >
                      Product Details
                    </Typography>
                    <Box sx={{ mr: "1.4rem" }}>
                      <svg
                        width='25'
                        height='25'
                        viewBox='0 0 22 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M3.83725 21H17.8372M1.33725 7H20.3372M8.83725 1L6.83725 7L10.8372 17.5L14.8372 7L12.8372 1M11.4292 17.3489L20.3688 7.5153C20.5348 7.33264 20.6179 7.24131 20.651 7.13732C20.6801 7.04569 20.6822 6.94759 20.657 6.85478C20.6285 6.74946 20.5495 6.65465 20.3914 6.46501L16.0771 1.28785C15.989 1.18204 15.9449 1.12914 15.8908 1.09111C15.8429 1.05741 15.7895 1.03238 15.733 1.01717C15.6691 1 15.6003 1 15.4625 1H6.21195C6.07422 1 6.00535 1 5.94153 1.01717C5.88499 1.03238 5.83156 1.05741 5.78368 1.09111C5.72963 1.12914 5.68555 1.18204 5.59737 1.28785L1.28307 6.46501C1.12504 6.65464 1.04603 6.74946 1.01745 6.85478C0.992267 6.94759 0.99438 7.04569 1.02354 7.13732C1.05662 7.24131 1.13965 7.33264 1.3057 7.51529L10.2453 17.3489C10.4505 17.5746 10.5531 17.6875 10.6737 17.7292C10.7797 17.7659 10.8948 17.7659 11.0008 17.7292C11.1214 17.6875 11.224 17.5746 11.4292 17.3489Z'
                          stroke='#333333'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      my: "1.2rem",
                      mx: "1.4rem",
                    }}
                  >
                    <ProductDetailCard item={item} />
                    {item?.certificate.length !== 0 && (
                      <Box
                        // key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.6rem",
                        }}
                      >
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            color: "#595959",
                            fontSize: { mobile: "0.8rem", laptop: "1rem" },
                          }}
                        >
                          Certificate
                        </Typography>
                        <Box
                          sx={{
                            borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
                            flexGrow: 1,
                          }}
                        />
                        {item?.certificate.map((certificate) => {
                          return (
                            <Button
                              target='_black'
                              href={certificate.certificate_file}
                              sx={{
                                color: " #333",
                                fontFamily: "mulish-regular",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                bgcolor: "#F0F0F0",
                                borderRadius: "1rem",
                                textTransform: "lowercase",
                                // height: "42px",
                                // width: { sm: "240px" },
                                px: "8px",
                                py: "4px",
                                ml: "1rem",
                                fontFamily: "mulish-regular",
                                "&.MuiButton-root:hover": {
                                  color: " #333",
                                  bgcolor: "#F0F0F0",
                                  bgcolor: "#F0F0F0",
                                },
                                "& .capitalize-first": {
                                  "&::first-letter": {
                                    textTransform: "uppercase",
                                  },
                                },
                              }}
                            >
                              <span className='capitalize-first'>
                                {certificate?.certificate_name}
                              </span>

                              <Box
                                component='img'
                                src={"/external-link.png"}
                                sx={{ ml: "8px" }}
                              />
                            </Button>
                          )
                        })}
                      </Box>
                    )}

                    <RenderStoneDetails
                      title='Center Stone'
                      stones={item?.center_stones}
                    />
                    <RenderStoneDetails
                      title='Side Stone'
                      stones={item?.side_stones}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "24px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                display: { xs: "block", md: "none" },
                mx: "12px",
              }}
            >
              <Box
                sx={{
                  bgcolor: " #F8F8F8 ",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    marginY: "1rem",
                    ml: "1.4rem",
                    fontWeight: 600,
                    fontSize: { xs: "1rem", md: "1.2rem" },
                    color: "#595959",
                    fontFamily: "KaiseiDecol-Regular",
                  }}
                >
                  Product Details
                </Typography>
                <Box sx={{ mr: "1.4rem" }}>
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 22 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3.83725 21H17.8372M1.33725 7H20.3372M8.83725 1L6.83725 7L10.8372 17.5L14.8372 7L12.8372 1M11.4292 17.3489L20.3688 7.5153C20.5348 7.33264 20.6179 7.24131 20.651 7.13732C20.6801 7.04569 20.6822 6.94759 20.657 6.85478C20.6285 6.74946 20.5495 6.65465 20.3914 6.46501L16.0771 1.28785C15.989 1.18204 15.9449 1.12914 15.8908 1.09111C15.8429 1.05741 15.7895 1.03238 15.733 1.01717C15.6691 1 15.6003 1 15.4625 1H6.21195C6.07422 1 6.00535 1 5.94153 1.01717C5.88499 1.03238 5.83156 1.05741 5.78368 1.09111C5.72963 1.12914 5.68555 1.18204 5.59737 1.28785L1.28307 6.46501C1.12504 6.65464 1.04603 6.74946 1.01745 6.85478C0.992267 6.94759 0.99438 7.04569 1.02354 7.13732C1.05662 7.24131 1.13965 7.33264 1.3057 7.51529L10.2453 17.3489C10.4505 17.5746 10.5531 17.6875 10.6737 17.7292C10.7797 17.7659 10.8948 17.7659 11.0008 17.7292C11.1214 17.6875 11.224 17.5746 11.4292 17.3489Z'
                      stroke='#333333'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </Box>
              </Box>
              <Box
                sx={{
                  my: "1.2rem",
                  mx: "1.4rem",
                }}
              >
                <ProductDetailCard item={item} />
                {item?.certificate.length !== 0 && (
                  <Box
                    // key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: "0.6rem",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color: "#595959",
                        fontSize: { mobile: "0.8rem", laptop: "1rem" },
                      }}
                    >
                      Certificate
                    </Typography>
                    <Box
                      sx={{
                        borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
                        flexGrow: 1,
                      }}
                    />
                    {item?.certificate.map((certificate) => {
                      return (
                        <Button
                          target='_black'
                          href={certificate.certificate_file}
                          sx={{
                            color: " #333",
                            fontFamily: "mulish-regular",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            bgcolor: "#F0F0F0",
                            borderRadius: "1rem",
                            textTransform: "lowercase",
                            // height: "42px",
                            // width: { sm: "240px" },
                            px: "8px",
                            py: "4px",
                            ml: "1rem",
                            fontFamily: "mulish-regular",
                            "&.MuiButton-root:hover": {
                              color: " #333",
                              bgcolor: "#F0F0F0",
                              bgcolor: "#F0F0F0",
                            },
                            "& .capitalize-first": {
                              "&::first-letter": {
                                textTransform: "uppercase",
                              },
                            },
                          }}
                        >
                          <span className='capitalize-first'>
                            {certificate?.certificate_name}
                          </span>

                          <Box
                            component='img'
                            src={"/external-link.png"}
                            sx={{ ml: "8px" }}
                          />
                        </Button>
                      )
                    })}
                  </Box>
                )}

                <RenderStoneDetails
                  title='Center Stone'
                  stones={item?.center_stones}
                />
                <RenderStoneDetails
                  title='Side Stone'
                  stones={item?.side_stones}
                />
              </Box>
            </Box>

            {/* <Box sx={{width:"100%", maxWidth:"1216px", mx:"auto", paddingY:"48px", display:"flex", gap:"40px"}}>
  <Box sx={{width :"100%", border:"2px solid #F0F0F0", borderRadius: '2px 2px 0 0'}}>
    <Box sx={{width:"100%", borderTop:"8px solid black", borderBottom:"1px solid #E0E0E0",  borderRadius: '4px 4px 0 0' }}>
  <Box sx={{display:"flex", bgcolor:"#F8F8F8", justifyContent:"space-between", paddingY:"16px", paddingX:"24px"}}>
    <Typography sx={{fontSize:"20px", fontFamily:"KaiseiDecol-Regular", fontWeight: 700 , lineHeight:"26.4px" }}>
    Traceability information
    </Typography>
    <Box sx={{cursor:"pointer"}}>

    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 13L10 8L5 13" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </Box>

  </Box>

</Box>
<Box sx={{
display:"flex",
flexDirection:"column",
  }}>
    <Box sx={{display:"flex", gap:"10px"}}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.16667 3.33333H6.5C5.09987 3.33333 4.3998 3.33333 3.86502 3.60582C3.39462 3.8455 3.01217 4.22795 2.77248 4.69836C2.5 5.23314 2.5 5.9332 2.5 7.33333V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H12.6667C14.0668 17.5 14.7669 17.5 15.3016 17.2275C15.772 16.9878 16.1545 16.6054 16.3942 16.135C16.6667 15.6002 16.6667 14.9001 16.6667 13.5V10.8333M10.8333 14.1667H5.83333M12.5 10.8333H5.83333M16.7678 3.23223C17.7441 4.20854 17.7441 5.79146 16.7678 6.76777C15.7915 7.74408 14.2085 7.74408 13.2322 6.76777C12.2559 5.79146 12.2559 4.20854 13.2322 3.23223C14.2085 2.25592 15.7915 2.25592 16.7678 3.23223Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<Typography>
Lorem Ipsum #1
</Typography>
    </Box>
  </Box>
</Box>

<Box sx={{width:"100%" , borderTop:"8px solid black",  borderRadius: '4px 4px 0 0' }}></Box>
</Box> */}

            {item.similar_stones.length !== 0 && (
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: 700,
                  // lineHeight: { xs: "40px", md: "52px" },
                  // letterSpacing: { lg: "1.5px" },
                  color: " #333333",
                  fontFamily: "KaiseiDecol-Regular",
                  textAlign: "center",
                  my: "24px",
                }}
              >
                Similar Products
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                flexWrap:{xs:"wrap", lg:`${item?.similar_stones.length > 4 ? "wrap" : "nowrap"}`},
                width:"100%",
                // maxWidth:"1216px",
                // mx:"atuo",
                gap:{xs:"16px", md:"24px"} ,
                justifyContent: "center",
                // gap: "1rem",
                px: { xs: "12px", lg: "0px" },
              }}
            >
              <StoneCard stoneImage={item.similar_stones} />
            </Box>
            {/* <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
              }}
            >
              <Swiper
                slidesPerView={1.2}
                spaceBetween={12}
                className='mySwiper'
                // onSlideChange={(slideChange) =>
                //   setActiveStep(slideChange.activeIndex)
                // }
                // style={{width:"100%"}}
              >
                {item.similar_stones.map((item, index) => {
                  // const parcelid = item.parcel_type.join(",")

                  return (
                    <SwiperSlide key={index}>
                      <Card
                        sx={{
                          mt: { xs: "1rem", md: "24px" },
                          // ml: { mobile: index !== 0 ? "2rem" : 0 },
                          mx: "12px",
                          cursor: "pointer",
                          boxShadow:
                            "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75)",
                          transition: "box-shadow 0.3s",
                          "&:hover": {
                            boxShadow:
                              "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 15px -9px rgba(51, 51, 51, 0.9)",
                          },
                          // width:"60%"
                        }}
                        onClick={() => navigate(`/detail/${item.stone_id}`)}
                      >
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              bgcolor: "#F0F0F0",
                            }}
                          >
                            <Loader />
                          </Box>
                        ) : (
                          <>
                            <Box
                              component='img'
                              src={item.image}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: {
                                  xs: "320px",
                                },
                              }}
                            />
                          </>
                        )}

                        <Typography
                          sx={{
                            textAlign: "center",
                            mt: { xs: "12px", md: "24px" },
                            fontWeight: 400,
                            fontSize: { xs: "16px", md: "18px" },
                            fontFamily: "mulish-regular",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            mb: { xs: "12px", md: "24px" },
                            fontWeight: 400,
                            fontSize: { xs: "16px", md: "18px" },
                            fontFamily: "mulish-regular",
                          }}
                        >
                          {item.role}
                        </Typography>
                      </Card>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </Box> */}
            {shopDifferentVariety?.user_website_details.products_section
              .different_variety?.is_enabled && (
              <Contactus shopDifferentVariety={shopDifferentVariety} />
            )}
          </Box>
        )}
      </Container>
    </>
  )
}

export const StoneCard = ({ stoneImage, hasLoaded = false }) => {
  const navigate = useNavigate()

  return (
    <>
      {stoneImage?.map((item, index) => {
        // const parcelid = item.parcel_type.join(",")

        return (
          <Card
            key={index}
            sx={{
              mt: { xs:"0px", md: "24px" },
              // ml: { mobile: index !== 0 ? "2rem" : 0 },
              width:"100%",
              maxWidth:{xs:"167px", md:"225px", lg:"286px"},
              // height:{lg:"100%"},
              cursor: "pointer",
              boxShadow:
                "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75)",
              transition: "box-shadow 0.3s",
              "&:hover": {
                boxShadow:
                  "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 15px -9px rgba(51, 51, 51, 0.9)",
              },
            }}
            onClick={() => navigate(`/detail/${item.stone_id}`)}
          >
            {hasLoaded ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  bgcolor: "#F0F0F0",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <Box
                component='img'
                src={item.image}
                alt='stone'
                sx={{
                  // mt: {lg: "2rem" } ,
                  objectFit: "cover",

                  width: "100%",
                  maxWidth: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                  mx: "auto",
                  height: {
                    xs: "168px",
                    sm: "216px",
                    md: "246px",
                    lg: "375px",
                  },
                  bgcolor: "#F8F8F8",
                }}
              />
            )}

<Box sx={{ my: "24px" }}>
              <Typography
                sx={{
                  color:"#333333",
                  textAlign: "center",
                  // my: { xs: "12px", lg: "24px" },
                  fontWeight: 400,
                  fontSize: { xs: "16px", lg: "18px" },
                  fontFamily: "mulish-regular",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  // my: { xs: "12px", lg: "24px" },
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#858585",
                  fontFamily: "mulish-regular",
                }}
              >
                {item?.reference_number}
              </Typography>
            </Box>
          </Card>
        )
      })}
    </>
  )
}
