import React, { useEffect, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { getSectionDetail } from "../../../../services/getSectionDetail"
import { Loader } from "../../../common/Loader/Loader"

export const Contactus = ({ shopDifferentVariety }) => {
  const navigate = useNavigate()
  return (
    <Box
      id='contact-us'
      sx={{
        display: "flex",
        mt: "48px",
        flexDirection: { xs: "column", sm: "row" },
        mx: { xs: "12px", lg: "0px" },
        // width:"100%"
      }}
    >
      <Box>
        <Box
          component='img'
          alt=''
          src={
            shopDifferentVariety?.user_website_details.products_section
              .different_variety?.banner_image
          }
          sx={{ width: "100%", height: "auto", display: { sm: "none" } }}
        />
      </Box>

      <Box sx={{ width: "659px" }}>
        <Box
          sx={{
            backgroundImage: `url(${shopDifferentVariety?.user_website_details.products_section.different_variety?.banner_image})`,
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: { sm: "block" },
          }}
        />
      </Box>
      <Box
        sx={{
          bgcolor:
            shopDifferentVariety?.user_website_details.products_section
              .different_variety?.theme_mode === "dark"
              ? "black"
              : shopDifferentVariety?.user_website_details.products_section
                  .different_variety?.theme_mode === "season"
              ? "#FFF5E6 "
              : "#F8F8F8",

          // width: { xs: "100%", sm: "70%", lg: "60%" },
          width: { xs: "100%", sm: "46.5%" },
        }}
      >
        <Box
          sx={{
            mx: { xs: "32px", md: "88px" },
            my: { xs: "32px", md: "52px", lg: "112px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "32px", md: "44px" },
              fontWeight: 700,
              lineHeight: { xs: "40px", md: "52px" },
              letterSpacing: { lg: "1.5px" },
              color:
                shopDifferentVariety?.user_website_details.products_section
                  .different_variety?.theme_mode === "dark"
                  ? "white "
                  : shopDifferentVariety?.user_website_details.products_section
                      .different_variety?.theme_mode === "season"
                  ? "#7B4A00 "
                  : "#333333",
              fontFamily: "KaiseiDecol-Regular",
            }}
          >
            {
              shopDifferentVariety?.user_website_details.products_section
                .different_variety?.title
            }
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "1px",
              color:
                shopDifferentVariety?.user_website_details.products_section
                  .different_variety?.theme_mode === "dark"
                  ? "white "
                  : shopDifferentVariety?.user_website_details.products_section
                      .different_variety?.theme_mode === "season"
                  ? "#7B4A00 "
                  : "#333333",
              mt: "16px",
              fontFamily: "mulish-regular",
            }}
          >
            {
              shopDifferentVariety?.user_website_details.products_section
                .different_variety?.description
            }
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              size='small'
              variant='outlined'
              sx={{
                color:
                  shopDifferentVariety?.user_website_details.products_section
                    .different_variety?.theme_mode === "dark"
                    ? "white "
                    : shopDifferentVariety?.user_website_details
                        .products_section.different_variety?.theme_mode ===
                      "season"
                    ? "#7B4A00 "
                    : "#333333",
                fontSize: "16px",
                textTransform: "capitalize",
                px:"16px",
                py:"8px",
                lineHeight:"150%",                borderColor:
                  shopDifferentVariety?.user_website_details.products_section
                    .different_variety?.theme_mode === "dark"
                    ? "white "
                    : shopDifferentVariety?.user_website_details
                        .products_section.different_variety?.theme_mode ===
                      "season"
                    ? "#7B4A00 "
                    : "#333333",
                mt: "16px",
                fontFamily: "mulish-regular",
                "&.MuiButton-root:hover": {
                  color: " white",
                  bgcolor: "#333333",
                  borderColor: " #333333",
                },
              }}
              onClick={() => navigate(`/products/1,4`)}
            >
              view inventory
            </Button>
            <Button
              size='small'
              sx={{
                bgcolor:
                  shopDifferentVariety?.user_website_details.products_section
                    .different_variety?.theme_mode === "dark"
                    ? "white"
                    : shopDifferentVariety?.user_website_details
                        .products_section.different_variety?.theme_mode ===
                      "season"
                    ? "#7B4A00 "
                    : "black ",
                color:
                  shopDifferentVariety?.user_website_details.products_section
                    .different_variety?.theme_mode === "dark"
                    ? "#333333 "
                    : shopDifferentVariety?.user_website_details
                        .products_section.different_variety?.theme_mode ===
                      "season"
                    ? "#E1D5C3 "
                    : "white",
                fontSize: "16px",
                textTransform: "capitalize",
                px:"16px",
                py:"8px",
                lineHeight:"150%",  
                ml: "16px",
                mt: "16px",
                fontFamily: "mulish-regular",
                "&.MuiButton-root:hover": {
                  color: " white",
                  bgcolor: "#333333",
                  borderColor: " #333333",
                },
              }}
              onClick={() => navigate(`/contact-us`)}
            >
              contact us
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
