import React, { useState } from "react"
import { Button, Box, Typography, TextField } from "@mui/material"
import axios from "axios"
import toast from "react-hot-toast"

import { useNavigate } from "react-router-dom"
export const SignupNew = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const validateForm = () => {
    let errors = {}
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/
    const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/

    if (!formData.firstName) {
      errors.firstName = "Please enter your first name"
    }
    if (!formData.lastName) {
      errors.lastName = "Please enter your last name"
    }
    if (!formData.email || !emailRegex.test(formData.email)) {
      errors.email = "Please enter a valid email address"
    }
    if (!formData.phoneNumber || !phoneRegex.test(formData.phoneNumber)) {
      errors.phoneNumber =
        "Please enter a valid phone number with country code start with +"
    }
    if (!formData.companyName) {
      errors.companyName = "Please enter your company name"
    }

    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateForm()) {
      return
    }

    setSubmitting(true)

    try {
      const payload = {
        vendor_id: window ? window.config.vendor_id : "",
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        full_name: formData.companyName,
        phone: formData.phoneNumber,
        vendor_website: true,
      }
      const res = await axios.post(
        `${window.config.api_url}/add-customer`,
        payload
      )

      toast.success(res.data.message)
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
      })
      navigate("/login")
    } catch (error) {
      toast.error("Failed to sign up!")
      console.error(error)
    }

    setSubmitting(false)
  }

  return (
    <Box
      sx={{
        bgcolor: "#F8F8F8",
        width: { sm: "488px", md: "660px", lg: "1010px" },
        mx: { xs: "12px", sm: "auto" },
        mt: { xs: "24px", md: "48px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: { md: "390px" },
          mx: { xs: "16px", sm: "42px", md: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            textAlign: "center",
            color: " #333333",
            mt: "40px",
            fontFamily: "KaiseiDecol-Regular",
          }}
        >
          Request Access
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            color: "#666666",
            mt: "16px",
            fontFamily: "mulish-regular",
            Width: "438px",
          }}
        >
          We value your privacy, the information requested below is used to
          internal verification only.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: { md: "flex" }, alignItems: "center" }}>
            <TextField
              variant='standard'
              label='First Name'
              value={formData.firstName}
              onChange={(event) =>
                setFormData({ ...formData, firstName: event.target.value })
              }
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
              fullWidth
              required
              sx={{ mr: "1rem" }}
            />
            <TextField
              variant='standard'
              label='Last Name'
              value={formData.lastName}
              onChange={(event) =>
                setFormData({ ...formData, lastName: event.target.value })
              }
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
              fullWidth
              required
              sx={{ mt: { xs: "1rem", md: "0rem" } }}
            />
          </Box>
          <TextField
            variant='standard'
            label='Email'
            type='email'
            value={formData.email}
            onChange={(event) =>
              setFormData({ ...formData, email: event.target.value })
            }
            error={Boolean(errors.email)}
            helperText={errors.email}
            fullWidth
            required
            sx={{ mt: "1rem" }}
          />
          <TextField
            variant='standard'
            label='Phone Number'
            value={formData.phoneNumber}
            onChange={(event) =>
              setFormData({ ...formData, phoneNumber: event.target.value })
            }
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber}
            fullWidth
            required
            sx={{ mt: "1rem" }}
          />
          <TextField
            variant='standard'
            fullWidth
            label='Company Name'
            value={formData.companyName}
            onChange={(event) =>
              setFormData({ ...formData, companyName: event.target.value })
            }
            required
            sx={{ mt: "1rem" }}
          />

          <Button
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
            disabled={submitting}
            sx={{
              bgcolor: "black",
              color: "white",
              fontSize: "16px",
              textTransform: "capitalize",

              // height: "20px",
              mt: "24px",
              fontFamily: "mulish-regular",
              "&.MuiButton-root:hover": {
                color: " white",
                bgcolor: "#333333",
                borderColor: " #333333",
              },
            }}
          >
            {submitting ? "Please wait" : " request access"}
          </Button>
        </form>
        <Typography sx={{ my: "16px" }}>or</Typography>
        <Button
          variant='outlined'
          fullWidth
          sx={{
            color: "black",
            fontSize: "16px",
            borderColor: "black",
            fontFamily: "mulish-regular",
            "&.MuiButton-root:hover": {
              color: " white",
              bgcolor: "#333333",
              borderColor: " #333333",
            },
          }}
          onClick={() => navigate("/login")}
        >
          login
        </Button>
        <Box sx={{ mt: "48px", mb: "64px", textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#333333",
              fontFamily: "montserrat-bold",
            }}
          >
            Powered by
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#333333",
              letterSpacing: "0.4em",
              mt: "4px",
              fontFamily: "montserrat-Medium",
            }}
          >
            GEMCLOUD
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#333333",
              letterSpacing: "0.15em",
              mt: "4px",
              fontFamily: "montserrat-Medium",
            }}
          >
            SOFTWARE
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
