import React from "react"
import {
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Typography,
} from "@mui/material"
import { ProductCard } from "./ProductCard"
import { HiExclamation, HiOutlineSearch } from "react-icons/hi"
import { useNavigate } from "react-router-dom"

const ProductList = ({ productData, loading, categorySection }) => {
  if (!loading && productData) {
    if (productData) {
      if (productData?.data?.length > 0) {
        // Separate the first 9 items and the remaining items
        const firstNineItems = productData.data.slice(0, 9)
        const remainingItems = productData.data.slice(9)

        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {firstNineItems.map((item, i) => (
              <ProductCard key={i} item={item} />
            ))}
            {firstNineItems.length >= 1 && firstNineItems.length < 9 && (
              <ShopDiffVariety categorySection={categorySection} />
              // <></>
            )}

            {remainingItems.length > 0 && (
              <>
                <ShopDiffVariety categorySection={categorySection} />
                {remainingItems.map((item, i) => (
                  <ProductCard key={i} item={item} />
                ))}
              </>
            )}
          </Box>
        )
      } else {
        return (
          <Box width='100%' my={"3rem"} textAlign={"center"}>
            <HiOutlineSearch size='5em' />
            <Typography variant='h3' mt='1rem'>
              No results found
            </Typography>
          </Box>
        )
      }
    } else {
      return (
        <Box width='100%' my={"3rem"} textAlign={"center"}>
          <HiExclamation size='5em' />
          <Typography variant='h3' mt='1rem'>
            Error!
          </Typography>
        </Box>
      )
    }
  } else {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {Array.from({ length: 9 }).map((_, i) => (
            <Skeleton
              key={i}
              variant='rectangular'
              animation='wave'
              sx={{
                width: { mobile: "50%", laptop: "30.75%" },
                ml: { laptop: "2.5%" },
                mt: "1rem",
                height: "25rem",
              }}
            />
          ))}
        </Box>
      </>
    )
  }
}

export default ProductList

export const ShopDiffVariety = ({ categorySection }) => {
  const navigate = useNavigate()
  return (
    categorySection?.is_enabled === true && (
      <Box
        sx={{
          width: "100%",
          ml: { tablet: "1.45rem" },
          mt: "16px",
        }}
      >
        <Box
          sx={{
            bgcolor:
              categorySection?.theme_mode === "dark"
                ? "black"
                : categorySection?.theme_mode === "season"
                ? "#FFF5E6 "
                : "#F8F8F8",
          }}
        >
          <Box
            sx={{
              px: { mobile: "32px", laptop: "88px" },
              py: { mobile: "40px", desktop: "32px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { mobile: "36px", desktop: "44px" },
                fontWeight: 700,
                lineHeight: { mobile: "48px", desktop: "52px" },
                letterSpacing: { laptop: "1px" },
                color:
                  categorySection?.theme_mode === "dark"
                    ? "white "
                    : categorySection?.theme_mode === "season"
                    ? "#7B4A00 "
                    : "#333333",
                fontFamily: "KaiseiDecol-Regular",
                // width: "241px",
              }}
            >
              {categorySection?.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { mobile: "16px", desktop: "18px" },
                fontWeight: 400,
                lineHeight: { laptop: "26px" },
                letterSpacing: "1px",
                color:
                  categorySection?.theme_mode === "dark"
                    ? "white "
                    : categorySection?.theme_mode === "season"
                    ? "#7B4A00 "
                    : "#333333",
                mt: "16px",
                fontFamily: "mulish-regular",
              }}
            >
              {categorySection?.description}
            </Typography>
            <Box sx={{ display: "flex", mt:"16px" }}>
              <Button
                size='small'
                variant='outlined'
                sx={{
                  color:
                    categorySection?.theme_mode === "dark"
                      ? "white "
                      : categorySection?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "#333333",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  py:"8px",
                  px:"16px",
                  lineHeight:"150%",
                  borderColor:
                    categorySection?.theme_mode === "dark"
                      ? "white "
                      : categorySection?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "#333333",
                  fontFamily: "mulish-regular",
                  "&.MuiButton-root:hover": {
                    color: " white",
                    bgcolor: "#333333",
                    borderColor: " #333333",
                  },
                }}
                onClick={() => navigate(`/products/1,4`)}
              >
                view inventory
              </Button>
              <Button
                size='small'
                sx={{
                  bgcolor:
                    categorySection?.theme_mode === "dark"
                      ? "white"
                      : categorySection?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "black ",
                  color:
                    categorySection?.theme_mode === "dark"
                      ? "#333333 "
                      : categorySection?.theme_mode === "season"
                      ? "#E1D5C3 "
                      : "white",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  py:"8px",
                  px:"16px",
                  lineHeight:"150%",
                  ml: "16px",
                  fontFamily: "mulish-regular",
                  "&.MuiButton-root:hover": {
                    color: " white",
                    bgcolor: "#333333",
                    borderColor: " #333333",
                  },
                }}
                onClick={() => navigate(`/contact-us`)}
              >
                contact us
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  )
}
