import api from "./API"


export const userPaymentKey = async (data) => {
    try {
      return await api.post(`/get-user-payment-publish-key`, data);
    } catch (error) {
      console.log(error);
    }
  };
  