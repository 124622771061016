import React, { createContext, useReducer } from "react"
import AapReducer from "./AapReducer"
import {
  ADD_ALL_TO_CART,
  ADD_CUSTOMER,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  EMPTY_CART,
  REMOVE_FROM_CART,
  CONTENT_STATE,
  REMOVE_FROM_WISHLIST,
} from "./actionTypes"

const initialState = { cartItems: [], wishList: [], customer: {}, content: false  }

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AapReducer, initialState)

  const addCustomer = (customer) => {
    dispatch({ type: ADD_CUSTOMER, payload: customer })
  }

  const addToCart = (product) => {
    dispatch({ type: ADD_TO_CART, payload: product })
  }
  const addToWishList = (productItem) => {
    dispatch({ type: ADD_TO_WISHLIST, payload: productItem })
  }

  const removeFromCart = (itemId) => {
    dispatch({ type: REMOVE_FROM_CART, payload: itemId })
  }
  const removeFromWishlist = (itemId) => {
    dispatch({ type: REMOVE_FROM_WISHLIST, payload: itemId })
  }
  const setContentState = (value) => {
    dispatch({ type: CONTENT_STATE, payload: value });
  }
  const emptyCart = () => {
    dispatch({ type: EMPTY_CART, payload: state })
  }
  const addAllToCart = (products) => {
    dispatch({ type: ADD_ALL_TO_CART, payload: products })
  }

  return (
    <GlobalContext.Provider
      value={{
        customer: state.customer,
        addCustomer: addCustomer,
        setContentState:setContentState,
        cartItems: state.cartItems,
        wishList: state.wishList,
        content:state.content,
        addToCart: addToCart,
        addToWishList: addToWishList,
        removeFromCart: removeFromCart,
        removeFromWishlist: removeFromWishlist,
        emptyCart: emptyCart,
        addAllToCart: addAllToCart,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
