import React, { useContext } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { GlobalContext } from "../context/GlobalState"
import { clearCart } from "../store/actions/cart.action"
import { Link, useNavigate } from "react-router-dom"
import { Box, Button, Typography } from "@mui/material"

const Thankyou = (props) => {
  const { emptyCart } = useContext(GlobalContext)
  const navigate = useNavigate()
  const cart = JSON.parse(localStorage.getItem("cartItems"))

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.removeItem("invoice_id")
      navigate("/products/1,4") // Change this to the correct path
    }, 10000)
    return () => {
      clearTimeout(timer)
    }
  }, [navigate])

  const handleNavigate = () => {
    localStorage.removeItem("invoice_id")
    navigate("/products/1,4")
  }

  return (
    <>
      {localStorage.getItem("invoice_id") === null ? (
        <Box
          sx={{
            mt: { xs: "4rem", md: "7rem" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.1rem", md: "2.5rem" },
              fontWeight: 500,
              color: "#595959",
            }}
          >
            You haven't place any order!
          </Typography>
          <Typography
            sx={{ fontSize: { xs: "0.9rem", md: "1.4rem", color: "#595959" } }}
          >
            Sorry for the inconvience, kindly go back to our showcase and place
            an order
          </Typography>

          <Button
            onClick={handleNavigate}
            variant='contained'
            sx={{
              color: "#e9f3fb",
              bgcolor: "#1e6091",
              mt: "1.2rem",
              // width:"6rem",
              fontSize: { xs: "0.7rem", md: "1rem" },
              "&:hover": {
                color: "#e9f3fb",
                bgcolor: "#1e6091",
              },
            }}
          >
            go back to Showcase
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            mt: { xs: "4rem", md: "7rem" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component='img'
            src='/images/confirmation-tick.png'
            alt='tick'
            sx={{ width: { xs: "4rem", md: "5rem" } }}
          />
          <Typography
            sx={{
              fontSize: { xs: "1.1rem", md: "2.5rem" },
              fontWeight: 500,
              color: "#595959",
              mt: "2.5rem",
              textAlign: "center",
            }}
          >
            Your order has been placed successfully!
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "0.9rem",
                md: "1.4rem",
                color: "#595959",
                textAlign: "center",
              },
              mx: { xs: "1rem", md: "0rem" },
            }}
          >
            Thank you for shopping with us your, your Quotation ID is{" "}
            <Typography
              component='span'
              sx={{
                fontWeight: 600,
                fontSize: { xs: "0.9rem", md: "1.4rem", color: "#595959" },
              }}
            >
              {localStorage.getItem("invoice_id")}
            </Typography>
          </Typography>

          <Button
            onClick={handleNavigate}
            variant='contained'
            sx={{
              color: "#e9f3fb",
              bgcolor: "#1e6091",
              mt: "1.6rem",
              // width:"6rem",
              fontSize: { xs: "0.7rem", md: "1rem" },
              "&:hover": {
                color: "#e9f3fb",
                bgcolor: "#1e6091",
              },
            }}
          >
            browse Showcase
          </Button>
        </Box>
      )}
    </>
  )
}

export default Thankyou
