import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "./components/Layout/NewDesign/Layout/Layout";
import { GlobalProvider } from "./context/GlobalState";
import '../src/styles/fonts.css'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     {/* <HelmetWrapper></HelmetWrapper> */}
    <GlobalProvider>
      <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <App />
        </Layout>
      </BrowserRouter>
      </Provider>
    </GlobalProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
