import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { productList } from "../../services/products-service"
import Filters from "./Filters"
import ProductList from "./ProductList"
import { getSectionDetail } from "../../services/getSectionDetail"
import { Container } from "@mui/system"
import { Helmet } from "react-helmet"

const sortFunc = ({ arr, arg, acs }) => {
  if (acs) {
    return arr.sort(
      (a, b) =>
        parseFloat(a[arg].toString().replace(/,/g, "")) -
        parseFloat(b[arg].toString().replace(/,/g, ""))
    )
  } else {
    return arr.sort(
      (a, b) =>
        parseFloat(b[arg].toString().replace(/,/g, "")) -
        parseFloat(a[arg].toString().replace(/,/g, ""))
    )
  }
}
// shop gemstone online by company name
// image will be logo
const MainContent = () => {
  const [productData, setProductData] = useState()
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState("")
  const [filterMenu, setFilterMenu] = useState(false)
  const [category, setCategory] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { search, pathname } = useLocation()
  const { parcel_type_ids } = useParams()
  const navigate = useNavigate()

  const token = localStorage.getItem("token")
  const categorySectionDetail = async () => {
    try {
      setIsLoading(true)
      const response = await getSectionDetail(window.config.vendor_id, "category")
      setCategory(response.data.response?.user_website_details?.category_section)
      setIsLoading(false)
    } catch (error) {
      // console.log(error )
    }

  }
  useEffect(() => {
    categorySectionDetail()
  }, [])
  useEffect(() => {
    if (parcel_type_ids) {
      if (search) {
        let objectFromUrl = convertUrlSearch(search)
        getProductList(objectFromUrl, 1)
      } else {
        getProductList({}, 1)
      }
    } else {
      navigate("/products/1,4")
    }
  }, [search, parcel_type_ids])

  const getProductList = async (propsArr, pageNumber) => {
    setLoading(true)

    let price_range = propsArr.price_range
      ? {
          price_range_from: propsArr?.price_range[0]
            ? propsArr?.price_range[0]
            : 0,
          price_range_to: propsArr?.price_range[1]
            ? propsArr?.price_range[1]
            : null,
        }
      : {}
    let weight_range = propsArr.wight_range
      ? {
          weight_range_from: propsArr?.weight_range[0]
            ? propsArr?.weight_range[0]
            : 0,
          weight_range_to: propsArr?.weight_range[1]
            ? propsArr?.weight_range[1]
            : null,
        }
      : {}
    const response = await productList(
      {
        user_id:
          token !== null
            ? localStorage.getItem("user_id")
            : window.config.vendor_id,
        user_type:
          localStorage.getItem("user_type") === null
            ? "vendor"
            : localStorage.getItem("user_type"),
        parcel_type_ids: parcel_type_ids?.split(","),
        ...price_range,
        ...weight_range,
        ...propsArr,
      },
      pageNumber
    )
    setProductData(response?.data?.stones)
    setLoading(false)
    setSort("")
  }

  // const transformedData = category?.history_paragraph?.map((item) => {
  //   console.log(item)
  //   const key = Object.keys(item)[0]
  //   return {
  //     name: key,
  //     paragraph: item[key].Paragraph,
  //   }
  // })

  const transformedData = category?.history_paragraph?.map((item) => {
    const name = Object.keys(item)[0] // Get the name property
    const paragraph = item[name].paragraph // Get the paragraph property
    return { name, paragraph } // Create the desired object structure
  })

  const handleSorting = ({ target: { value } }) => {
    let sortedDataArray = productData.data
    switch (value) {
      case 1: {
        sortedDataArray = sortFunc({
          arr: productData.data,
          arg: "total_selling_price",
          acs: true,
        })
        break
      }
      case 2: {
        sortedDataArray = sortFunc({
          arr: productData.data,
          arg: "total_selling_price",
          acs: false,
        })
        break
      }
      case 3: {
        sortedDataArray = sortFunc({
          arr: productData.data,
          arg: "weight",
          acs: true,
        })
        break
      }
      case 4: {
        sortedDataArray = sortFunc({
          arr: productData.data,
          arg: "weight",
          acs: false,
        })
        break
      }
      default:
        break
    }
    setProductData({ ...productData, data_array: sortedDataArray })
    setSort(value)
  }
  const fetchProducts = async (pageNumber) => {
    setLoading(true)
    let modifiedFilters = {
      ...convertUrlSearch(search),
    }
    getProductList(modifiedFilters, pageNumber)
  }
  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        mobile2: 420,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
        desktopLarget: 1440,
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          "&.MuiContainer-root ": {
            p: 0,
            maxWidth: "1220px",
          },
        }}
      >
        {pathname === "/products/1,4" &&
          transformedData?.length > 0 &&
          transformedData[0] && (
            <StoneDescription
              name={transformedData[0].name}
              description={transformedData[0].paragraph}
            />
          )}
        {pathname === "/products/2,3,5,6" &&
          transformedData?.length > 1 &&
          transformedData[1] && (
            <StoneDescription
              name={transformedData[1].name}
              description={transformedData[1].paragraph}
            />
          )}
        {pathname === "/products/7,8" &&
          transformedData?.length > 2 &&
          transformedData[2] && (
            <StoneDescription
              name={transformedData[2].name}
              description={transformedData[2].paragraph}
            />
          )}
        {pathname === "/products/9,10" &&
          transformedData?.length > 3 &&
          transformedData[3] && (
            <StoneDescription
              name={transformedData[3].name}
              description={transformedData[3].paragraph}
            />
          )}
        <Box sx={{ display: "flex", mt: "24px", width: "100%" }}>
          {/* sort and card in the same box */}
          <Box
            sx={{
              width: "17.875rem",
              display: { mobile: "none", desktop: "block" },
              padding: "0px 16px",
            }}
          >
            <Filters productData={productData} />
          </Box>
          <Box sx={{ width: "100%", px: { mobile: "12px", desktop: "0px" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // pl: { laptop: "22px" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    p: { mobile: "5px", tablet: "8px" },
                    cursor: "pointer",
                  }}
                >
                  <Box
                    onClick={() => setFilterMenu(true)}
                    component='img'
                    src='/images/filter.png'
                    sx={{ display: { desktop: "none" } }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#333333",
                    pl: { mobile: "8px", laptop: "8px", desktop: "0px" },
                    fontSize: "16px",
                    fontFamily: "mulish-regular",
                  }}
                >
                  Show {productData?.data.length} items
                </Typography>
              </Box>
              <Drawer
                anchor='left'
                open={filterMenu}
                onClose={() => setFilterMenu(false)}
                sx={{
                  display: { desktop: "none" },
                }}
              >
                <Box sx={{ p: "18px" }}>
                  <Filters
                    productData={productData}
                    onClose={() => setFilterMenu(false)}
                  />
                </Box>
              </Drawer>
              <FormControl sx={{ width: { mobile: "9rem", tablet: "13rem" } }}>
                <InputLabel
                  sx={{
                    top: { mobile: -6, laptop: -6 },
                    fontSize: "16px",
                    fontFamily: "mulish-regular",
                  }}
                  id='demo-simple-select-label'
                >
                  Sort by
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={sort}
                  label='Sort by'
                  disabled={loading}
                  onChange={handleSorting}
                  sx={{
                    height: "42px",
                  }}
                >
                  {token && <MenuItem value={1}>Price: Low to High</MenuItem>}
                  {token && <MenuItem value={2}>Price: High to Low</MenuItem>}

                  <MenuItem value={3}>Weight: Low to High</MenuItem>
                  <MenuItem value={4}>Weight: High to Low</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <ProductList
              productData={productData}
              loading={loading}
              categorySection={category.different_variety}
            />
            <Box sx={{ display: " flex", justifyContent: "center" }}>
              {
                <Pagination
                  count={productData && productData?.last_page}
                  variant='outlined'
                  shape='rounded'
                  sx={{
                     mt: "40px",
                      // mb: "48px",
                       textAlign: "center",
                       '& .MuiPaginationItem-page.Mui-selected': {
                        backgroundColor: 'black',
                        color: 'white',
                      }, }}
                  color='primary'
                  onChange={(e, page) => {
                    fetchProducts(page)
                  }}
                />
              }
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
export const convertUrlSearch = (search) => {
  if (search) {
    const searchString = search.slice(1)
    let objectFromSearch = JSON.parse(
      `{"` + searchString.replace(/=/g, `":"`).replace(/&/g, `","`) + `"}`
    )
    for (const keys in objectFromSearch) {
      if (objectFromSearch[keys]) {
        if (objectFromSearch[keys].includes(`,`)) {
          const arrayFromObject = objectFromSearch[keys].split(",")
          objectFromSearch[keys] = arrayFromObject.map(Number)
        } else {
          objectFromSearch[keys] = objectFromSearch[keys].split()?.map(Number)
        }
      }
    }
    return objectFromSearch
  } else {
    return {}
  }
}
export const convertObjectToUrlSearch = (obj, navigate) => {
  let urlToGo = ""
  for (const key in obj) {
    if (obj[key].length !== 0) {
      urlToGo =
        urlToGo + ((urlToGo.length === 0 ? "?" : "&") + key + "=" + obj[key])
    }
  }
  navigate(urlToGo)
}
export default MainContent

export const StoneDescription = ({ name, description }) => {
  return (
    <Box
      sx={{
        bgcolor: "#F8F8F8",
        mt: { mobile: "24px", laptop: "48px" },
        mx: { mobile: "12px", laptop: "0px" },
        // px: { tablet: "136px", laptop: "0px" },
      }}
    >
      <Typography
        sx={{
          fontSize: { mobile: "32px", laptop: "36px" },
          fontWeight: 700,
          color: "#333333",
          textAlign: "center",
          lineHeight:" 133.333%",
          pt: "24px",
          fontFamily: "KaiseiDecol-Regular",
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: "#333333",
          lineHeight: "24px",
          textAlign: "center",
          width: { mobile: "100%", tablet: "596px" },
          mt: "16px",
          mx: { tablet: "auto" },
          pb: "40px",
          px: { mobile: "16px", tablet: "0px" },
          fontFamily: "mulish-regular",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
    </Box>
  )
}
// fontFamily: "mulish-regular"
// fontFamily: "KaiseiDecol-Regular",
