import {
  Box,
  Button,
  Card,
  Container,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material"
import React, { useEffect, useState, useContext} from "react"
import { useNavigate } from "react-router-dom"
import { fetchUserWebisteSetting } from "../../services/fetch-user-website-settings-service"
import { getSectionDetail } from "../../services/getSectionDetail"
import { Loader } from "../common/Loader/Loader"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "./aboutSwipper.css"
import { GlobalContext } from "../../context/GlobalState"
export const Aboutus = () => {
  const { setContentState } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [aboutWebsite, setAboutWebsite] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
      },
    },
  })

  const sectiionDetail = async () => {
    try {
      setIsLoading(true)
      const response = await getSectionDetail(window.config.vendor_id, "about")
      setAboutWebsite(response.data.response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  
  }
  useEffect(() => {
    sectiionDetail()
  }, [])

  const stripStyles = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    const textContent = doc.body.textContent || ""
    return textContent
  }

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          m: "atuo",
          //  flexDirection:"column"
        }}
      >
        <Loader />
      </Box>
    )
  const image =
    aboutWebsite.user_website_details?.about_section.gemstore_international
      ?.image ||
    aboutWebsite.user_website_details?.about_section.gemstore_international
      ?.photo_link

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          "&.MuiContainer-root ": {
            p: 0,
            maxWidth: "1215px",
          },
        }}
      >
      {aboutWebsite?.user_website_details?.about_section?.gemstore_international && (

       <Box sx={{ mt: { mobile: "14px", laptop: "48px" } }}>
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: 700,
              textAlign: { tablet: "center" },
              color: "#333333",
              fontFamily: "KaiseiDecol-Regular",
              mx: { mobile: "12px", laptop: "0px" },
            }}
          >
            {aboutWebsite &&
              aboutWebsite.user_website_details?.about_section
                .gemstore_international?.store_name}
          </Typography>
          {image && (
                 <Box
                 sx={{
                   mt: { mobile: "16px", laptop: "32px" },
                   mx: { mobile: "12px", laptop: "auto" },
                 }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url("${image}")`,
                      height: { mobile: "218px", tablet: "480px" },
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                                    backgroundPosition: "center",
                      flexGrow: 1,
                    }}
                  />
                </Box>
          )}
        
          {aboutWebsite.user_website_details?.about_section.our_history
            ?.paragraph && (
            <Box
              sx={{
                mt: { mobile: "24px", laptop: "48px" },
                mx: { mobile: "12px", laptop: "0px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: {mobile:"28px", tablet:"36px"} ,
                  fontWeight: 700,
                  textAlign: { tablet: "center" },
                  color: "#333333",
                  lineHeight:"133.333%",
                  fontFamily: "KaiseiDecol-Regular",
                }}
              >
                Our history
              </Typography>

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  mx: { tablet: "74px", laptop: "auto" },
                  color: "#333333",
                  width: { laptop: "596px" },
                  mt: "16px",
                  fontFamily: "mulish-regular",
                  // textAlign: { tablet: "center" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      aboutWebsite.user_website_details?.about_section
                        .our_history?.paragraph,
                  }}
                />
              </Typography>
            </Box>
          )}
          {aboutWebsite?.user_website_details?.about_section?.team_members
            ?.is_enabled && (
            <>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "28px ",
                  textAlign: "center",
                  lineHeight:"128.571%",
                  color: "#333333",
                  mt: { mobile: "24px", laptop: "48px" },
                  fontFamily: "KaiseiDecol-Regular",
                }}
              >
                The team
              </Typography>
              {aboutWebsite?.user_website_details?.about_section?.team_members?.members && (
              <Box
                sx={{
                  display: { mobile: "none", tablet: "flex" },
                  justifyContent: "center",
                  px: { mobile: "12px", desktop: "0px" },
                  gap: "24px",
                }}
              >
                {aboutWebsite?.user_website_details?.about_section?.team_members
                  ?.members &&
                  Object.values(
                    aboutWebsite.user_website_details.about_section.team_members
                      .members
                  ).map((itm, index) => {
                    return (
                      itm.image_hidden !== "true" && (
                        <React.Fragment key={index}>
                          <TeamCard
                            name={itm.name}
                            image={itm.image}
                            profession={itm.role}
                          />
                        </React.Fragment>
                      )
                    )
                  })}
              </Box>
              )}
            </>
          )}
          {aboutWebsite?.user_website_details?.about_section?.team_members
            ?.is_enabled && (
            <Box
              sx={{
                display: { mobile: "flex", tablet: "none" },
                justifyContent: "center",
              }}
            >
              <Swiper
                slidesPerView={1.2}
                spaceBetween={12}
                className='mySwiper'
                // onSlideChange={(slideChange) =>
                //   setActiveStep(slideChange.activeIndex)
                // }
                // style={{width:"100%"}}
              >
                {aboutWebsite?.user_website_details?.about_section?.team_members
                  ?.members &&
                  Object.values(
                    aboutWebsite.user_website_details.about_section.team_members
                      .members
                  ).map((item, index) => {
                    // const parcelid = item.parcel_type.join(",")

                    return (
                      <SwiperSlide key={item.id}>
                        <Card
                          sx={{
                            mt: { mobile: "1rem", laptop: "24px" },
                            // ml: { mobile: index !== 0 ? "2rem" : 0 },
                            mx: "12px",
                            cursor: "pointer",
                            boxShadow:
                              "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75)",
                            transition: "box-shadow 0.3s",
                            "&:hover": {
                              boxShadow:
                                "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 15px -9px rgba(51, 51, 51, 0.9)",
                            },
                            // width:"60%"
                          }}
                          // onClick={() => navigate(`products/${parcelid}`)}
                        >
                          {isLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                bgcolor: "#F0F0F0",
                              }}
                            >
                              <Loader />
                            </Box>
                          ) : (
                            <>
                              <Box
                                component='img'
                                src={item.image}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: {
                                    mobile: "320px",
                                  },
                                }}
                              />
                            </>
                          )}

                          <Typography
                            sx={{
                              textAlign: "center",
                              mt: { mobile: "12px", laptop: "24px" },
                              fontWeight: 400,
                              fontSize: "18px",
                              fontFamily: "mulish-regular",
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "center",
                              mb: { mobile: "12px", laptop: "24px" },
                              fontWeight: 400,
                              fontSize: { mobile: "16px", laptop: "18px" },
                              fontFamily: "mulish-regular",
                            }}
                          >
                            {item.role}
                          </Typography>
                        </Card>
                      </SwiperSlide>
                    )
                  })}
              </Swiper>
            </Box>
          )}

          {aboutWebsite.user_website_details?.about_section.findOut_more
            ?.is_enabled && (
            <Box
              sx={{
                textAlign: "center",
                    mt: {mobile:"32px", tablet:"32px", laptop:"48px"} ,
                    py: {mobile:"24px", laptop:"32px"} ,
                bgcolor:
                  aboutWebsite.user_website_details?.about_section.findOut_more
                    ?.theme_mode === "dark"
                    ? "black"
                    : aboutWebsite.user_website_details?.about_section
                        .findOut_more?.theme_mode === "season"
                    ? "#FFF5E6 "
                    : "white",
              }}
            >
              <Typography
                sx={{
                  fontSize: {mobile:"28px", tablet:"44px"} ,
                  fontWeight: 700,
                  lineHeight: "52px",
              
                  fontFamily: "KaiseiDecol-Regular",
                  color:
                    aboutWebsite.user_website_details?.about_section
                      .findOut_more?.theme_mode === "dark"
                      ? "white "
                      : aboutWebsite.user_website_details?.about_section
                          .findOut_more?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "#333333",
                }}
              >
                {
                  aboutWebsite.user_website_details?.about_section.findOut_more
                    .title
                }
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  mt: "16px",
                  fontFamily: "mulish-regular",

                  color:
                    aboutWebsite.user_website_details?.about_section
                      .findOut_more?.theme_mode === "dark"
                      ? "white "
                      : aboutWebsite.user_website_details?.about_section
                          .findOut_more?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "#333333",
                  px: { mobile: "22%", laptop: "34%" },
                }}
              >
                {
                  aboutWebsite.user_website_details?.about_section.findOut_more
                    ?.description
                }
              </Typography>
              <Button
                size='tabletall'
                sx={{
                  color:
                    aboutWebsite.user_website_details?.about_section
                      .findOut_more?.theme_mode === "dark"
                      ? "#333333 "
                      : aboutWebsite.user_website_details?.about_section
                          .findOut_more?.theme_mode === "season"
                      ? "#E1D5C3 "
                      : "white",

                  bgcolor:
                    aboutWebsite.user_website_details?.about_section
                      .findOut_more?.theme_mode === "dark"
                      ? "white"
                      : aboutWebsite.user_website_details?.about_section
                          .findOut_more?.theme_mode === "season"
                      ? "#7B4A00 "
                      : "black ",

                  fontSize: "16px",
                  textTransform: "capitalize",
                  width: "112px",

                  // height: "20px",
                  mt: "16px",
                  fontFamily: "mulish-regular",
                  "&.MuiButton-root:hover": {
                    color: " white",
                    bgcolor: "#333333",
                    borderColor: " #333333",
                  },
                }}
                onClick={() => navigate(`/contact-us`)}
              >
                contact us
              </Button>
            </Box>
          )}

          {/* <Box
            id='contact-us'
            sx={{ display: { mobile: "none", laptop: "flex" }, mt: "3rem" }}
          >
            <Box>
              <Box
                component='img'
                alt=''
                src={fetchUserWebsite?.homepage_top_section?.image}
                sx={{ width: "100%", height: "480px" }}
              />
            </Box>
            <Box sx={{ bgcolor: " #F8F8F8", width: "493px" }}>
              <Box sx={{ mx: "45px", my: "112px" }}>
                <Typography
                  sx={{
                    fontSize: "44px",
                    fontWeight: 700,
                    lineHeight: "52px",
                    letterSpacing: "1.5px",
                    color: " #333333",
                    fontFamily: "KaiseiDecol-Regular",
                  }}
                >
                  Shop different variety
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "26px",
                    letterSpacing: "1px",
                    color: "#333333",
                    mt: "16px",
                    fontFamily: "mulish-regular",
                  }}
                >
                  Short description Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </Typography>
                <Box>
                  <Button
                    size='tabletall'
                    sx={{
                      bgcolor: "black",
                      color: "white",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      width: "112px",

                      // height: "20px",
                      mt: "16px",
                      fontFamily: "mulish-regular",
                      "&.MuiButton-root:hover": {
                        color: " white",
                        bgcolor: "#333333",
                        borderColor: " #333333",
                      },
                    }}
                    onClick={() => navigate(`/contact-us`)}
                  >
                    contact us
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box> */}
        </Box>
      )} 
      </Container>
    </ThemeProvider>
  )
}

export const TeamCard = ({ image, name, profession }) => {
  return (
    <>
      <Card
        sx={{
          mt: { mobile: "1rem", laptop: "24px" },
          // ml: { mobile: index !== 0 ? "2rem"  : 0 },
          cursor: "pointer",
          boxShadow:
            "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75)",
          transition: "box-shadow 0.3s",
          "&:hover": {
            boxShadow:
              "0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 5px -9px rgba(51, 51, 51, 0.75), 0px 6px 15px -9px rgba(51, 51, 51, 0.9)",
          },
        }}
      >
        {false ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              bgcolor: "#F0F0F0",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: {
                mobile: "290px",
                tablet: "175px",
                laptop: "225px",
                desktop: "286px",
              },
              height: {
                mobile: "320px",
                tablet: "216px",
                laptop: "246px",
                desktop: "375px",
              },
              backgroundImage: `url("${image}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        )}

        <Typography
          sx={{
            textAlign: "center",
            mt: { mobile: "12px", laptop: "24px" },
            fontWeight: 400,
            fontSize: { mobile: "16px", laptop: "18px" },
            fontFamily: "mulish-regular",
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            mb: { mobile: "12px", laptop: "24px" },
            fontWeight: 400,
            fontSize: { mobile: "16px", laptop: "18px" },
            fontFamily: "mulish-regular",
          }}
        >
          {profession}
        </Typography>
      </Card>
    </>
  )
}
