
import api from "./API";

export const AuthService = {
  login: async () => {
    return await api.post(`/login-api-vendor`)
  },
  signup: async (params) => {
    return await api.post(`/add-customer`, { ...params })
  },
  userLogin: async (email, password) => {
    return await api.post(`/login-api-customer?email=${email}&password=${password}&user_type=customer&vendor_id=${window.config.vendor_id}`)
  },
  header: async (formdata) => {
    return await api.post(`/get-template-details`, formdata)
  },
}