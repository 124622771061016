import React from "react"
import { Box, Typography } from "@mui/material"
import { Helmet } from "react-helmet"

export const ProductDetailCard = ({ item }) => {
  const productDetail = [
    {
      SKU: item?.internal_reference_number,
      variety: item?.stone_types || item?.type,
      category: item?.parcel_types,
      color: item?.stone_type_colors,
      shape: item?.shapes,
      origin: item?.countries,
      treatment: item?.stone_type_treatments,
      "number of pieces": item?.number_of_pieces,
      dimensions: `${item.measurements
        ?.split("x")
        .map((dim) => {
          const parsedDim = parseFloat(dim.trim());
          return isNaN(parsedDim) ? "0" : `${parsedDim.toFixed(1)}`;
        })
        .join(" x ")} mm`,

      weight: item?.weight + item?.weight_type,
      "cutting type": item?.stone_cuts,
      "color grade": item?.saturations,
      "clarity grade": item?.clarities,
      "cut grade": item?.cut_grades,
      "color grade range": item?.color_grade_range,
      "clarity grade range": item?.clarity_range,
      "cut grade range": item?.cut_grade_range,
      "jewelry type": item?.jewelry_type,
      "jewelry style": item?.style,
      "metal type": item?.metal,
      "metal color": item?.metal_color,
      "gender type": item?.gender_type,
      ocassion: item?.ocassion,
      engraving: item?.engraving,
    },
  ]
  const includedKeysForDescription = [
    "variety",
    "category",
    "color",
    "shape",
    "origin",
    "treatment",
    "weight",
    "cutting type",
    "color grade",
    "clarity grade",
    "cut grade",
    "color grade range",
    "clarity grade range",
    "cut grade range",
    "jewelry type",
    "jewelry style",
    "metal type",
    "metal color",
    "gender type",
    "ocassion",
    "engraving",
  ]
  const includedKeysForTitle = [
    "variety",
    "color",
    "shape",
    "origin",
    "weight",
    "cutting type",
    "color grade",
    "clarity grade",
    "cut grade",
    "jewelry type",
    "jewelry style",
    "metal type",
    "metal color",
    "gender type",
    "ocassion",
  ]

  const filteredProductDetailDescription = productDetail.filter((obj) =>
    includedKeysForDescription.some((key) => obj.hasOwnProperty(key))
  )
  const formatedDescription = filteredProductDetailDescription
    .map((detail) => Object.values(detail).join("-"))
    .join(", ")
  const filteredProductDetailTitle = productDetail.filter((obj) =>
    includedKeysForTitle.some((key) => obj.hasOwnProperty(key))
  )
  const formatedTitle = filteredProductDetailTitle
    .map((detail) => Object.values(detail).join("-"))
    .join(", ")

  return (
    <>
      <Helmet>
        <title>{formatedTitle}</title>
        <meta property='og:title' content={formatedTitle} />
        <meta name='description' content={formatedDescription} />
        <meta property='og:description' content={formatedDescription} />
        <meta property='og:image' content={item?.image} />
      </Helmet>
      {productDetail.map((obj, index) => (
        <Box key={index} sx={{ width: "100%" }}>
          {Object.entries(obj).map(([key, value]) => (
            <ProductDetailItem key={key} label={key} value={value} />
          ))}
        </Box>
      ))}
    </>
  )
}
const ProductDetailItem = ({ label, value }) => {
  if (!value || label === "dimensions" && value.startsWith("undefined")) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "0.6rem",
        }}
      >
        <Typography
          sx={{
            textTransform: "capitalize",
            color: "#333333",
            fontWeight: 400,
            fontSize: { xs: "0.8rem", md: "1rem" },
            fontFamily: "mulish-regular",
          }}
        >
          {label}:
        </Typography>
        <Box
          sx={{
            borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
            flexGrow: 1,
          }}
        />
        <Typography
          sx={{
            fontWeight: 600,
            color: "#333333",
            fontSize: { xs: "0.8rem", md: "1rem" },
            fontFamily: "mulish-regular",
            ml: "1rem",
          }}
        >
          {value}
        </Typography>
      </Box>
    </>
  )
}
