import { ADD_TO_CART, CLEAR_CART, REMOVE_FROM_CART } from "../constant"

const initState = {
  cart: []
}

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload]
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(cartdata => cartdata.id !== action.payload)
      }
    case CLEAR_CART:
      return {
        cart: []
      }

    default:
      return state;
  }
}
export default cartReducer