import { Button, IconButton, Typography } from "@mui/material"
import { Box, minWidth } from "@mui/system"
import React, { useContext, useMemo } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import { GlobalContext } from "../../../context/GlobalState"
import { Link, useLocation, useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
const SidebarCartView = ({
  close,
  name,
  items,
  buttonName,
  buttonHandler,
  removeItem,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem("token")
  const sumPrice = useMemo(() => {
    return items?.reduce((total, item) => {
      if (item.total_selling_price === 0 || item.total_selling_price === "0") {
        return total + parseFloat(item.total_selling_price)
      } else {
        return total + parseFloat(item.total_selling_price?.replace(/,/g, ""))
      }
    }, 0)
  }, [items])

  return (
    <Box
      sx={{
        mt: "24px",
        mx: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            onClick={close}
            sx={{
              color: "white",
              textTransform: "capitalize",
              borderRadius: "14px",
              "&.MuiButton-root": {
                padding: 0,
                minWidth: 0,
              },
            }}
          >
            <Box
              component='img'
              src='/images/chevron-right.png'
              sx={{ bgcolor: "black", p: "5px", borderRadius: "4px" }}
            />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: 700,
              color: " #333333",
              fontFamily: "KaiseiDecol-Regular",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              color: " #333333",
              fontFamily: "KaiseiDecol-Regular",
            }}
          >
            {items?.length === 1 || items?.length === 0
              ? items?.length + " item"
              : items?.length + " items"}
          </Typography>
        </Box>
        <Box sx={{ borderBottom: "1px solid #E0E0E0", mt:"24px" }} />
        {items?.map((item, i) => {
          const stoneDetails = [
            item?.weight + item?.weight_type,
            item?.countries,
            item?.stone_type_treatments,
          ]
          return (
            <Box
              key={item.id}
              sx={{
                borderBottom: "1px solid #E0E0E0",
                borderRadius: "4px",
                // mt: "16px",
                py:"24px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  // pb: "20px",
                  // mt: "0.7rem",
                  cursor: "pointer",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box
                    onClick={() => {
                      navigate("/detail/" + item.id)

                      close()
                    }}
                    component='img'
                    src={item.image}
                    alt={item.internal_reference_number}
                    sx={{ width: "125px", height: "125px" }}
                  />
                </Box>
                <Box sx={{ ml: "16px", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: " #858585",
                        fontFamily: "mulish-regular",
                      }}
                    >
                      Ref#:&nbsp;
                      {item?.system_reference_number}
                    </Typography>
                    <Button
                      onClick={() => {
                        removeItem(item.id)
                      }}
                      color='primary'
                      size='small'
                      sx={{
                        "&.MuiButton-root": {
                          padding: 0,
                          minWidth: 0,
                        },
                        ml: "10px",
                      }}
                    >
                      <ClearIcon sx={{ color: "#858585", width: "18px" }} />
                    </Button>
                  </Box>
                  <Box
                    onClick={() => {
                      navigate("/detail/" + item.id)

                      close()
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#333333",
                        lineHeight: "24px",
                        fontFamily: "KaiseiDecol-Regular",
                      }}
                    >
                      {item?.stone_types}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#333333",
                        mt: "4px",
                        fontWeight: 400,
                        fontFamily: "mulish-regular",
                      }}
                    >
                      {stoneDetails
                        .filter((item) => item)
                        .map((item, index) => {
                          return (
                            <span key={index}>
                              {" "}
                              {item}
                              {index !==
                              stoneDetails.filter((i) => i).length - 1
                                ? ","
                                : ""}
                            </span>
                          )
                        })}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 700,
                          color: " #333333",
                          mt: "27px",
                          fontFamily: "KaiseiDecol-Regular",
                        }}
                      >
                        {token && item.currency_logo + item.total_selling_price}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
      <Box sx={{ borderTop: "1px solid #E0E0E0" }}>
        {token && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "16px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                color: "#333333",
                fontFamily: "KaiseiDecol-Regular",
                lineHeight:"132%"
              }}
            >
              SUBTOTAL
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                color: "#333333",
                fontFamily: "KaiseiDecol-Regular",
                lineHeight:"132%"
              }}
            >
              {sumPrice?.toFixed(2)}
            </Typography>
          </Box>
        )}

        <Typography
          sx={{
            fontWeight: 400,
            color: "#333333",
            mt: "8px",
            fontFamily: "mulish-regular",
          }}
        >
          Shipping and taxes calculated at checkout
        </Typography>
        <Button
          fullWidth
          sx={{
            bgcolor: "black",
            color: "white",
            fontSize: "18px",
            textTransform: "lowercase",
            fontFamily: "mulish-regular",
            // height: "20px",
            mt: "16px",
            mb: "24px",
            "&.MuiButton-root:hover": {
              color: " white",
              bgcolor: "#333333",
              borderColor: " #333333",
            },
            "& .capitalize-first": {
              "&::first-letter": {
                textTransform: "uppercase",
              },
            },
          }}
          onClick={buttonHandler}
        >
          <span className='capitalize-first'> {buttonName}</span>
        </Button>
      </Box>
    </Box>
  )
}

export default SidebarCartView
