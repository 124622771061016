import axios from "axios"
import toast from "react-hot-toast"

const url = window.location.pathname
var id = url.substring(url.lastIndexOf("/") + 1)

const api = axios.create({
  baseURL: window.config.api_url,
  headers: {
    Authorization: `Bearer ${
      localStorage["token"] ? localStorage.getItem("token") : ""
    }`,
  },
})

api.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    throw err;
  }
)

export default api
