import { updateLocalStorage } from "../components/Products/Filters"
import {
  ADD_CUSTOMER,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  EMPTY_CART,
  REMOVE_FROM_CART,
  ADD_ALL_TO_CART,
  CONTENT_STATE,
  REMOVE_FROM_WISHLIST,
} from "./actionTypes"

const AapReducer = (state, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      if (action.payload) {
        updateLocalStorage("customer", action.payload)
        return { ...state, customer: action.payload }
      } else {
        return state
      }
    case ADD_TO_CART:
      const inCart = state.cartItems.some(
        (item) => item.id === action.payload.id
      )

      if (!inCart) {
        let myArr = [...state.cartItems, action.payload]
        updateLocalStorage("cartItems", myArr)
        return {
          ...state,
          cartItems: myArr,
        }
      } else {
        return state
      }
    case ADD_TO_WISHLIST:
      const inWishList = state.wishList.some(
        (item) => item.id === action.payload.id
      )
      if (!inWishList) {
        let mywishArr = [...state.wishList, action.payload]
        updateLocalStorage("wishlistItems", mywishArr)
        return {
          ...state,
          wishList: mywishArr,
        }
      } else {
        return state
      }
    case REMOVE_FROM_CART: {
      if (action.payload > -1) {
        let myArr = state.cartItems.filter((item) => item.id !== action.payload)
        updateLocalStorage("cartItems", myArr)
        return {
          ...state,
          cartItems: myArr,
        }
      } else {
        return state
      }
    }
    case REMOVE_FROM_WISHLIST: {
      if (action.payload > -1) {
        let mywishArr = state.wishList.filter(
          (item) => item.id !== action.payload
        )
        updateLocalStorage("wishlistItems", mywishArr)
        return {
          ...state,
          wishList: mywishArr,
        }
      } else {
        return state
      }
    }
    case EMPTY_CART: {
      updateLocalStorage("cartItems", [])
      return {
        ...state,
        cartItems: [],
      }
    }
    case CONTENT_STATE:
      return {
        ...state,
        content: action.payload,
      };
    case ADD_ALL_TO_CART: {
      // Add all wishlist items to the cart
      const newItems = []
      state.wishList.forEach((product) => {
        // Check if product is already in cart
        const inCart = state.cartItems.some((item) => item.id === product.id)
        if (!inCart) {
          newItems.push(product)
        }
      })

      if (newItems.length > 0) {
        const updatedCart = [...state.cartItems, ...newItems]
        updateLocalStorage("cartItems", updatedCart)
        updateLocalStorage("wishlistItems", [])
        return {
          ...state,
          cartItems: updatedCart,
          wishList: [],
        }
      } else {
        return state
      }
    }
    default:
      return state
  }
}

export default AapReducer
