import React from "react"
import ImageMagnifier from "./ImageMagnifier"

const ProductMedia = (props) => {
  const { item } = props

  const frameRefresh = (e, index, vid) => {
    let el = document.getElementById(`lab-${index}`)
    el.setAttribute("src", vid)
  }

  const frameRefreshVendor = (e, index, vid) => {
    let el = document.getElementById(`360-video-vendor-${index}`)
    el.setAttribute("src", vid)
  }

  const frameRefreshVideo = (e, index, vid) => {
    let el = document.getElementById(`video-link-${index}`)
    el.setAttribute("src", vid)
  }

  const toggleMediaVisibility = (id) => {
    let elems = document.querySelectorAll(".show.active")
    let el = document.getElementById(id)

    elems.forEach((elem) => elem.classList.remove("show", "active"))
    el.classList.add("show", "active")
  }

  return (
    <>
      {/* =========================================================== Tab Displays =========================================================== */}
      <div className='detail-img order-1 w-100'>
        <div className='tab-content' id='myTabContent'>
          {/* Simple images */}
          {item?.images?.length ? (
            item.images.map((img, i) => (
              <div
                className={`tab-pane fade ${i === 0 ? " show active" : ""}`}
                id={`image-${i}`}
                role='tabpanel'
                key={`img-${i}`}
              >
                <div className='video-section mx-auto  text-center  '>
                  {/* <img src={img} alt='' className='gem-images' width={300} /> */}
                  <ImageMagnifier src={img} width={300} />
                </div>
                {/* <small className='font-weight-light d-block text-center mt-4'>
                  Actual gemstone image
                </small> */}
              </div>
            ))
          ) : (
            <div
              className={`tab-pane fade show active`}
              id={`image-`}
              role='tabpanel'
              key={`img-`}
            >
              <div className='video-section mx-auto h-100 text-center'>
                <img
                  src='/images/LoaderIcon.gif'
                  alt=''
                  className='gem-images'
                />
              </div>
              {/* <small className='font-weight-light d-block text-center mt-4'>
                Actual gemstone image
              </small> */}
            </div>
          )}

          {/* Map Vendor 360 Images */}
          {item?.vendor_360?.length
            ? item.vendor_360.map((vid, i) => (
                <div
                  className='tab-pane fade'
                  id={`360-vendor-${i}`}
                  key={`${i}-${vid}`}
                  role='tabpanel'
                  style={{marginTop:"2rem"}}
                >
                  <div className='video-section mx-auto text-center'>
                    <iframe
                      src={vid}
                      title='360'
                      id={`360-video-vendor-${i}`}
                      scrolling='no'
                    ></iframe>
                  </div>
                </div>
              ))
            : ""}

          {/*  Map Lab 360 Images Thumbnail */}
          {item?.lab_360?.length
            ? item?.lab_360?.map((vid, i) => (
                <div
                  className='tab-pane fade'
                  id={`lab_360-${i}`}
                  key={`lab-${i}`}
                  role='tabpanel'
                >
                  <div className='video-section mx-auto h-100 text-center'>
                    <iframe
                      src={vid}
                      title='360'
                      id={`lab-${i}`}
                      className='lab_frame'
                      scrolling='no'
                    />
                  </div>
                </div>
              ))
            : ""}

          {/*  Map Videos  */}

          {item?.video_link?.length
            ? item?.video_link?.map((vid, i) => (
                <div
                  className='tab-pane fade'
                  id={`video-${i}`}
                  key={`vid-${i}`}
                  role='tabpanel'
                >
                  <div className='video-section mx-auto h-100 text-center'>
                    <iframe
                      id={`video-link-${i}`}
                      src={vid.link}
                      width='640'
                      height='480'
                      allowFullScreen={true}
                    />
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>

      {/* =========================================================== Tabs =========================================================== */}
      <ul
        className='detail-thumbnail-list border-0 flex-wrap justify-content-center 
                justify-content-lg-start mr-lg-5 mr-md-4 mt-3 mt-md-0 nav nav-tabs text-center align-self-start'
        id='myTab'
        role='tablist'
      >
        {/* Map Vendor 360 Images Thumbnail */}
        {item?.vendor_360?.length
          ? item?.vendor_360?.map((vid, i) => (
              <li className='nav-item stone-img-box mb-3' key={`${i}-${vid}`}>
                <a
                  className='nav-link '
                  data-bs-toggle='tab'
                  //   href={`#360-vendor-${i}`}
                  //   style={{ backgroundImage: `url(/images/360.png)` }}
                  onClick={(e) => {
                    frameRefreshVendor(e, i, vid)
                    toggleMediaVisibility(`360-vendor-${i}`)
                  }}
                >
                  <img
                    className='h-100 p-0 rounded-0 w-100'
                    src={`/images/360.png`}
                  />
                </a>
              </li>
            ))
          : ""}

        {/*  Map Lab 360 Images Thumbnail */}
        {item?.lab_360?.length
          ? item?.lab_360?.map((vid, i) => (
              <li className='nav-item stone-img-box mb-3' key={`${i}-${vid}`}>
                <a
                  className='nav-link'
                  data-toggle='tab'
                  // href={`#lab_360-${i}`}
                  style={{ backgroundImage: `url(/images/360.png)` }}
                  onClick={(e) => {
                    frameRefresh(e, i, vid)
                    toggleMediaVisibility(`lab_360-${i}`)
                  }}
                >
                  <img
                    className='h-100 p-0 rounded-0 w-100'
                    src={`/images/360.png`}
                  />
                </a>
              </li>
            ))
          : ""}

        {/*  Map Simple Images Thumbnail */}
        {
          item?.images?.length ? (
            item?.images?.map((img, i) => (
              <li className='nav-item stone-img-box mb-3' key={`${i}-${img}`}>
                <a
                  className='nav-link '
                  data-toggle='tab'
                  // href={`#image-${i}`}
                  //   style={{ backgroundImage: `url(${img})`,width:80 }}
                  onClick={(e) => {
                    toggleMediaVisibility(`image-${i}`)
                  }}
                >
                  <img className='h-100 p-0 rounded-0 w-100' src={img} />
                </a>
              </li>
            ))
          ) : (
            <li className='nav-item stone-img-box mb-3'>
              <a
                className='nav-link h-100 p-0 rounded-0 w-100'
                data-toggle='tab'
                // href={`#image-`}
                // style={{ backgroundImage: `url(/images/LoaderIcon.gif)` }}
                onClick={(e) => {
                  toggleMediaVisibility(`image-`)
                }}
              >
                <img
                  className='h-100 p-0 rounded-0 w-100'
                  src={"/images/LoaderIcon.gif"}
                />
              </a>
            </li>
          )
          // ""
        }

        {/*  Map Videos Thumbnail */}
        {item?.video_link?.length
          ? item?.video_link?.map((vid, i) => (
              <li className='nav-item stone-img-box mb-3' key={`${i}-${vid}`}>
                <a
                  className='nav-link h-100 p-0 rounded-0 w-100'
                  data-toggle='tab'
                  // href={`#video-${i}`}
                  style={{ backgroundImage: `url(/images/360.png)` }}
                  onClick={(e) => {
                    frameRefreshVideo(e, i, vid.link)
                    toggleMediaVisibility(`video-${i}`)
                  }}
                />
              </li>
            ))
          : ""}
      </ul>
    </>
  )
}

export default ProductMedia
