import React, { useState, useEffect, useContext } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { useNavigate } from "react-router-dom"
import { InvoiceService } from "../services/invoice-service"
import { GlobalContext } from "../context/GlobalState"
import { Box, Divider, Typography } from "@mui/material"
import { userPaymentKey } from "../services/get-user-payment-publish-key"
export const Paypal = ({ paypalObj, sumPrice }) => {
  // console.log(paypalObj)
  const billing = JSON.parse(localStorage.getItem("billingAddress"))
  const shipping = JSON.parse(localStorage.getItem("shippingAddress"))
  const cart = JSON.parse(localStorage.getItem("cartItems")) || []
  // const [cart, setCart] = useState(
  //   JSON.parse(localStorage.getItem("cartItems"))
  // )
  // const [billingAddress, setBillingAddress] = useState(
  //   JSON.parse(localStorage.getItem("billingAddress"))
  // )
  // const [shippingAddress, setShippingAddress] = useState(
  //   JSON.parse(localStorage.getItem("shippingAddress"))
  // )
  const currencyFormat = cart.map((cartItem) => cartItem?.currency_format)[0]
  const [userId, setUserId] = useState(localStorage.getItem("user_id"))
  const { emptyCart } = useContext(GlobalContext)
  const navigate = useNavigate()
  const itmPrice = cart?.reduce((total, item) => {
    if (item.total_selling_price === 0 || item.total_selling_price === "0") {
      return total + parseFloat(item.total_selling_price)
    } else {
      return total + parseFloat(item.total_selling_price.replace(/,/g, ""))
    }
  }, 0)
  // useEffect(() => {
  //   setCart(JSON.parse(localStorage.getItem("cartItems")))
  //   setBillingAddress(JSON.parse(localStorage.getItem("billingAddress")))
  //   setShippingAddress(JSON.parse(localStorage.getItem("shippingAddress")))
  //   setUserId(localStorage.getItem("user_id"))
  // }, [])

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: itmPrice.toString(),
            // currency:"GBP"
          },
        },
      ],
    })
  }

  const onApprove = async (data, actions) => {
    const res = await actions.order.capture()
    if (res.status === "COMPLETED") {
      const response = await InvoiceService.createInvoice({
        payment_response: res,
        from_id: window.config.vendor_id,
        stone_ids: cart.map((cartItem) => {
          return cartItem.id
        }),
        to_id: userId,
        customer_billing_address_id: JSON.parse(
          localStorage.getItem("billingAddress")
        ).id,
        customer_shipping_address_id: JSON.parse(
          localStorage.getItem("shippingAddress")
        ).id,
        user_id: window.config.vendor_id,
        payment_received: true,
        prices: cart.map((cartItem) => {
          return cartItem.total_selling_price.toString().replace(",", "")
        }),
        payment_format: cart
          .map((cartItem) => {
            return cartItem.currency_format
          })[0]
          .toString(),
        user_type:
          localStorage.getItem("user_type") === null
            ? "vendor"
            : localStorage.getItem("user_type"),
        from_vendor: true,
      })
      if (response.data.success) {
        localStorage.setItem("invoice_id", response.data.invoice.ref_id)
        localStorage.setItem("cartItems", JSON.stringify([]))
        emptyCart()
        navigate("/thankyou")
      }
    }
  }

  return (
    paypalObj &&
    sumPrice !== 0 && (
      <PayPalScriptProvider
        options={{
          "client-id": `${paypalObj?.published_key}&currency=${currencyFormat}`,
        }}
        // forceReRender={[ currency]}
      >
        <Box sx={{ width: "154px" }}>
          <PayPalButtons
            style={{
              layout: "horizontal",
              color: "gold",
              height: 40,
              minWidth: "120px",
            }}
            createOrder={(data, actions) =>
              createOrder(data, actions, itmPrice)
            }
            onApprove={(data, actions) => onApprove(data, actions)}
          />
        </Box>
      </PayPalScriptProvider>
    )
  )
}
